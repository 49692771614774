<template>
  <AnimationList class="notificationsWrapper">
    <div v-for="notification in notifications" :key="notification.id" class="notificationWrapper animationList-item">
      <div :class="['notification', notification.type]" @click="dismissNotification(notification.id)">
        {{ notification.message }}
      </div>
    </div>
  </AnimationList>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AnimationList from "../animations/AnimationList";

export default {
  components: {
    AnimationList,
  },

  data() {
    return {
      ids: {},
    };
  },

  computed: {
    ...mapGetters({
      notifications: "notifications/first5",
    }),
  },

  watch: {
    notifications() {
      this.notifications.forEach(
        ({ id, duration }) => (this.ids[id] = this.ids[id] || setTimeout(() => this.dismissNotification(id), duration))
      );
    },
  },

  methods: {
    ...mapActions({
      dismissNotification: "notifications/dismiss",
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "../App/css/variables";

.notificationsWrapper {
  position: fixed !important;
  top: 0;
  left: 50%;
  z-index: 1000;
  width: 600px;
  max-width: 100%;
  transform: translateX(-50%);
}

.notificationWrapper {
  display: flex;
  justify-content: center;
  margin: 10px;
}

.notification {
  padding: 10px;
  border-radius: 8px;
  color: $white;
  font-weight: bold;
  font-size: 14px;
  box-shadow: 0 0 15px 4px rgb(0 0 0 / 17%);
  cursor: pointer;

  &.success {
    background-color: $success;
  }

  &.danger {
    background-color: $danger;
  }
}
</style>
