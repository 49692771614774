<template>
  <div class="invoices">
    <h2>{{ $t("header") }}</h2>
    <a class="link" href="https://manage.wix.com/account/billing-history" target="_blank">
      {{ $t("link") }}
    </a>
  </div>
</template>

<script>
export default {};
</script>

<i18n src="./locale.json"></i18n>

<style lang="scss" scoped>
@import "../../../App/css/variables";

.invoices {
  h2 {
    margin: 60px 0 20px;
  }

  .link {
    color: $tertiary;
    font-size: 14px;
  }
}
</style>
