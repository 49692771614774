<template>
  <slot v-if="allowed"></slot>
</template>

<script>
import { mapGetters } from "vuex";
import waitForSessionToBeFetched from "./waitForSessionToBeFetched";

export default {
  props: {
    unAuthRedirectTo: {
      type: String,
      default: "/",
    },
  },

  data() {
    return {
      allowed: false,
    };
  },

  computed: {
    ...mapGetters({
      isLoggedIn: "session/isLoggedIn",
    }),
  },

  watch: {
    $route: {
      handler() {
        this.checkAuth();
      },
      immediate: true,
    },

    isLoggedIn() {
      this.checkAuth();
    },
  },

  methods: {
    async checkAuth() {
      await waitForSessionToBeFetched(this.$store);

      const routeRequiresAuth = this.$route.matched.some((record) => record.meta.requiresAuth);
      if (routeRequiresAuth && !this.isLoggedIn) {
        this.allowed = false;
        this.$router.push({ path: this.unAuthRedirectTo });
      } else {
        this.allowed = true;
      }
    },
  },
};
</script>
