{
  "jp": {
    "header": {
      "addShippingPreset": "送り状新規作成",
      "editShippingPreset": "出荷元情報を変更"
    },
    "fields": {
      "nameLabel": "出荷元名称",
      "namePlaceholder": "出荷元を入力して下さい",
      "businessNameLabel": "会社名・店舗名*",
      "businessNamePlaceholder": "会社名・店舗名を入力して下さい",
      "addressLabel": "住所*",
      "addressPlaceholder": "住所を入力して下さい",
      "zipCodeLabel": "郵便番号* （数字のみ）",
      "zipCodePlaceholder": "郵便番号を入力して下さい",
      "phoneLabel": "電話番号",
      "phonePlaceholder": "電話番号を入力して下さい",
      "defaultLabel": "既定の出荷元にする"
    },
    "buttons": {
      "errored": "エラー",
      "saving": "保存中",
      "save": "保存",
      "back": "戻る"
    },
    "notifications": {
      "createdSuccess": "出荷元情報が作成されました。",
      "createdError": "出荷元情報を作成中にエラーが発生しました。",
      "updatedSuccess": "出荷元情報が更新されました。",
      "updatedError": "出荷元情報を更新中にエラーが発生しました。"
    }
  },
  "en": {
    "header": {
      "addShippingPreset": "Add new Warehouse",
      "editShippingPreset": "Edit Warehouse"
    },
    "fields": {
      "nameLabel": "PRESET NAME*",
      "namePlaceholder": "Enter preset name",
      "businessNameLabel": "BUSINESS NAME*",
      "businessNamePlaceholder": "Enter business name",
      "addressLabel": "ADDRESS*",
      "addressPlaceholder": "Enter address",
      "zipCodeLabel": "POSTAL CODE* (only digits)",
      "zipCodePlaceholder": "Enter postal code",
      "phoneLabel": "PHONE NUMBER",
      "phonePlaceholder": "Enter phone number",
      "defaultLabel": "Set as default preset"
    },
    "buttons": {
      "errored": "Errored",
      "saving": "Saving...",
      "save": "Save",
      "back": "Back"
    },
    "notifications": {
      "createdSuccess": "The preset has been successfully created!",
      "createdError": "An error occurred while creating a preset..",
      "updatedSuccess": "The preset has been successfully updated!",
      "updatedError": "An error occurred while updating a preset.."
    }
  }
}
