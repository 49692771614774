{
  "jp": {
    "buttons": {
      "generateLabel": "ラベルを作成",
      "submitting": "読込中",
      "errored": "次へ",
      "downloadUrl": "ラベルを表示"
    },
    "notifications": {
      "success": "配送伝票が作成されました。",
      "error": "ラベルを作成中にエラーが発生しました。住所を再度ご確認ください。"
    },
    "errorBox": {
      "title": "送り状を作成できない理由:",
      "editLabel": "編集",
      "subscriptions": "定期購入のページ",
      "invalidLabelData": "ラベルのデータが無効または不完全です。",
      "noSubscription": "購読をまだ選択していない。",
      "emptyBalance": "現在契約しているプランの残高がゼロである。"
    },
    "generateLabelConfirm": "送り状が一度発行されると、送り状の内容を変更することはできません。このまま進みますか？"
  },
  "en": {
    "buttons": {
      "generateLabel": "Generate Post Label",
      "submitting": "Loading...",
      "downloadUrl": "Show Post Label"
    },
    "notifications": {
      "success": "The label has been successfully generated!",
      "error": "An error occurred while generating the label.. Please confirm that all addresses are correct and valid."
    },
    "errorBox": {
      "title": "Reasons why the label can't be generated:",
      "editLabel": "Edit Label",
      "subscriptions": "Subscriptions Page",
      "invalidLabelData": "Label's data is still invalid or incomplete.",
      "noSubscription": "You haven't chosen any subscription yet.",
      "emptyBalance": "Your current subscription plan's balance is zero."
    },
    "generateLabelConfirm": "After a label has been generated, you won't be able to edit the label. Do you want to continue?"
  }
}
