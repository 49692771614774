export default (store) =>
  new Promise((resolve) => {
    const unwatch = store.watch(
      () => ({ fetched: store.getters["session/fetched"] }),
      ({ fetched }) => {
        if (!fetched) return;
        unwatch?.();
        resolve();
      },
      { immediate: true }
    );
  });
