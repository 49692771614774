{
  "jp": {
    "fields": {
      "consignorIdLabel": "認証コード*",
      "consignorIdPlaceholder": "認証コードを入力してください"
    },
    "buttons": {
      "errored": "エラー",
      "saving": "保存中",
      "save": "保存"
    },
    "notifications": {
      "success": "配送会社として日本郵便が設定されました。",
      "error": "エラーが発生しました。"
    },
    "title": "日本郵便",
    "status": "ステータス:",
    "active": "有効",
    "inactive": "まだご利用出来ません"
  },
  "en": {
    "fields": {
      "consignorIdLabel": "CONSIGNOR ID*",
      "consignorIdPlaceholder": "Provide Consignor Id"
    },
    "buttons": {
      "errored": "Errored",
      "saving": "Saving...",
      "save": "Save"
    },
    "notifications": {
      "success": "Japan Post carrier settings have been successfully updated!",
      "error": "An error occurred while updating Japan Post carrier settings!"
    },
    "title": "Japan Post",
    "status": "STATUS:",
    "active": "Active",
    "inactive": "Inactive"
  }
}
