<template>
  <LayoutJustSidePanel>
    <div class="error">
      <strong class="logo" />
      <div class="content">
        <template v-if="multiple422Errors">
          <p class="errorContent">
            <span v-for="errorText in error.errors" :key="errorText">{{ errorText }}<br /></span>
          </p>
          <h1>{{ $t("text422") }}</h1>
          <div class="controls">
            <Button class="btn" size="big" @click="$router.push({ path: '/' })">{{ $t("buttons.goToTopPage") }}</Button>
          </div>
        </template>

        <template v-else-if="err001">
          <h1>{{ $t("text422") }}</h1>
          <strong class="errorContent">{{ $t("textERR001") }}</strong>
          <div class="controls">
            <Button class="btn" size="big" type="inverted" @click="$router.push({ path: '/' })">
              {{ $t("buttons.goToTopPage") }}
            </Button>
            <Button class="btn" size="big" @click="gotoWixStores">{{ $t("buttons.goToWixStore") }}</Button>
          </div>
        </template>

        <template v-else>
          <img :src="errorScreenSvgUrl" />
          <h1>{{ $t("text500") }}</h1>
          <div class="controls">
            <Button class="btn" size="big" @click="$router.push({ path: '/' })">{{ $t("buttons.goToTopPage") }}</Button>
          </div>
        </template>
      </div>
    </div>
  </LayoutJustSidePanel>
</template>

<script>
import errorScreenSvgUrl from "../../../assets/images/error-screen.svg";
import LayoutJustSidePanel from "../../layouts/JustSidePanel";
import Button from "../../inputs/Button";

export default {
  components: {
    LayoutJustSidePanel,
    Button,
  },

  data() {
    return {
      errorScreenSvgUrl,
      error: window.vueData?.error || {},
    };
  },

  computed: {
    multiple422Errors() {
      return this.error.type == 422 && this.error.errors.length > 1;
    },

    err001() {
      return this.error.type == 422 && this.error.errors[0].indexOf("ERR001") === 0;
    },
  },

  methods: {
    gotoWixStores() {
      window.location.href = "https://www.wix.com/app-market/wix-stores";
    },
  },
};
</script>

<i18n src="./locale.json"></i18n>

<style lang="scss" scoped>
@import "../../App/css/variables";

.error {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.logo {
  position: absolute;
  top: 30px;
  left: 50%;
  width: 175px;
  height: 37px;
  background: $white url("../../../assets/images/logo-text.svg") no-repeat 0 0;
  background-size: contain;
  transform: translateX(-50%);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $primary;
  text-align: center;

  h1 {
    margin: 30px 0;
    font-weight: normal;
    font-size: 30px;
  }
}

.errorContent {
  width: 100%;
  max-width: 60%;
  padding: 30px;
  border: 1px solid $lines;
  font-family: "Courier New", Courier, monospace;
  background-color: $blue-1-aqua-haze;
}

.controls {
  display: flex;
  margin-top: 60px;

  .btn {
    margin: 0 30px;
  }
}
</style>
