import Cookies from "js-cookie";
import { loginStatuses } from "../consts";
import loginApi from "../api/login";

const state = {
  loginStatus: loginStatuses.bootingUp,
};

const getters = {
  sessionToken: () => Cookies.get("session_token") || "",
  loginStatus: ({ loginStatus }) => loginStatus,
  isLoggedIn: ({ loginStatus }) => loginStatus === loginStatuses.loggedIn,
  fetched: ({ loginStatus }) => loginStatus === loginStatuses.loggedIn || loginStatus === loginStatuses.notLoggedIn,
};

const actions = {
  async login({ commit, dispatch, getters, rootGetters }) {
    const sessionToken = getters["sessionToken"];
    const csrfToken = rootGetters["config/csrfToken"];

    commit("SET_LOGIN_STATUS", loginStatuses.loggingIn);
    const { error, sessionToken: returnedSessionToken } = await loginApi(csrfToken, sessionToken);

    if (error) return commit("SET_LOGIN_STATUS", loginStatuses.errored);
    if (returnedSessionToken) {
      commit("SET_LOGIN_STATUS", loginStatuses.loggedIn);
      commit("SET_SESSION_TOKEN", returnedSessionToken);
      dispatch("fetchData", null, { root: true });
    } else {
      commit("SET_LOGIN_STATUS", loginStatuses.notLoggedIn);
    }
  },

  async logout({ commit }) {
    commit("SET_SESSION_TOKEN", "");
    commit("SET_LOGIN_STATUS", loginStatuses.notLoggedIn);
  },
};

const mutations = {
  SET_LOGIN_STATUS(state, status) {
    state.loginStatus = status;
  },
  SET_SESSION_TOKEN(_state, sessionToken) {
    Cookies.set("session_token", sessionToken);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
