<template>
  <LocaleGuard>
    <AuthGuard unAuthRedirectTo="/signin">
      <router-view></router-view>
      <AnimationFade>
        <ConfirmBox v-if="showConfirmBox" />
      </AnimationFade>
    </AuthGuard>
  </LocaleGuard>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LocaleGuard from "../LocaleGuard";
import AuthGuard from "../AuthGuard";
import AnimationFade from "../animations/AnimationFade";
import ConfirmBox from "../ConfirmBox";

export default {
  components: {
    LocaleGuard,
    AuthGuard,
    AnimationFade,
    ConfirmBox,
  },

  computed: {
    ...mapGetters({
      showConfirmBox: "confirmBox/opened",
    }),
  },

  mounted() {
    this.setCsrfToken(this.csrfToken);
    this.fetchData();
  },

  methods: {
    ...mapActions({
      setCsrfToken: "config/setCsrfToken",
      fetchData: "fetchData",
    }),
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;700&display=swap");
@import "./css/variables.scss";
@import "./css/normalize";

html,
body,
#app {
  height: 100%;
  color: $black;
  font-family: Manrope, sans-serif;
}

html,
body,
ul,
ol,
p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

* {
  box-sizing: border-box;
  outline: none;
}
</style>
