export default (componentThis, { title }) =>
  new Promise((resolve) => {
    const store = componentThis.$store;

    store.dispatch("confirmBox/open", { title });
    const unwatch = store.watch((_state, getters) => {
      const result = getters["confirmBox/result"];
      if (result === null) return;

      store.dispatch("confirmBox/close", result);
      unwatch();
      resolve(result);
    });
  });
