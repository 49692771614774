const state = {
  notifications: [],
};

const getters = {
  first5: ({ notifications }) => notifications.slice(0, 5),
};

const actions = {
  dismiss({ commit }, id) {
    commit("REMOVE_NOTIFICATION", id);
  },
  queue({ commit }, { type = "success", message, duration = 3000 }) {
    const notification = { id: Math.random(), type, message, duration };
    commit("ADD_NOTIFICATION", notification);
  },
};

const mutations = {
  REMOVE_NOTIFICATION(state, id) {
    state.notifications = state.notifications.filter((notification) => notification.id !== id);
  },

  ADD_NOTIFICATION(state, notification) {
    state.notifications.push(notification);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
