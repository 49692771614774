{
  "jp": {
    "step1": {
      "title": "１.",
      "description": "「ゆうパック送り状作成システム」申込"
    },
    "step2": {
      "title": "２.",
      "description": "受付完了メール"
    },
    "step3": {
      "title": "３.",
      "description": "審査結果メール"
    },
    "step4": {
      "title": "４.",
      "description": "認証コードをUnipostalに入力"
    }
  },
  "en": {
    "step1": {
      "title": "Step 1",
      "description": "Apply for Japan Post"
    },
    "step2": {
      "title": "Step 2",
      "description": "Email Approval"
    },
    "step3": {
      "title": "Step 3",
      "description": "Receiving Cosignor ID"
    },
    "step4": {
      "title": "Step 4",
      "description": "Add to Unipostal"
    }
  }
}
