<template>
  <Notifications />
  <div class="withOrderList">
    <IncompleteSettingsInfoBar />
    <div class="main">
      <SidePanel class="sidePanel" />
      <OrdersPanel class="ordersPanel" />
      <div class="layoutContent">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import SidePanel from "../../SidePanel";
import OrdersPanel from "../../OrdersPanel";
import Notifications from "../../Notifications";
import IncompleteSettingsInfoBar from "../../IncompleteSettingsInfoBar";

export default {
  components: {
    SidePanel,
    OrdersPanel,
    Notifications,
    IncompleteSettingsInfoBar,
  },
};
</script>

<style lang="scss" scoped>
@import "../../App/css/variables";

.withOrderList {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.main {
  display: flex;
  flex-grow: 1;
  width: 100%;
  overflow: hidden;
}

.sidePanel {
  flex: 80px 0 0;
}

.ordersPanel {
  flex: 332px 0 0;
}

.layoutContent {
  flex-grow: 1;
  height: 100%;
  overflow: auto;
}
</style>
