import { camelizeKeys, decamelizeKeys } from "humps";
import axiosOpenApiWrapper from "../../lib/axios-open-api-wrapper";

export default async (csrfToken, sessionToken, name, billingCycle, successUrl) => {
  const { status, data } = await axiosOpenApiWrapper({
    method: "post",
    url: "/api/subscriptions/",
    headers: {
      "X-CSRF-TOKEN": csrfToken,
      Authorization: `Bearer ${sessionToken}`,
    },
    data: decamelizeKeys({
      successUrl,
      subscription: {
        name,
        billingCycle,
      },
    }),
    validateStatus: () => true,
  });

  const payload = camelizeKeys(data.payload);
  const error = `${status}` !== "200";
  const checkoutUrl = payload?.checkoutUrl;
  return { error, checkoutUrl };
};
