<template>
  <div class="loader">
    <div v-if="show" class="content">
      <!-- intentionally left as an inline svg, not to have to load a laoder -->
      <svg width="272" height="272" viewBox="0 0 272 272" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="136" cy="136" r="136" fill="white" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M75.0842 81.4702H191.181C196.703 81.4702 201.181 85.9473 201.181 91.4702V145.278L203.181 146.563V91.4702C203.181 84.8428 197.808 79.4702 191.181 79.4702H75.0842C68.4568 79.4702 63.0842 84.8428 63.0842 91.4702V171.518C63.0842 178.146 68.4568 183.518 75.0843 183.518H191.181C197.808 183.518 203.181 178.146 203.181 171.518V162.354L201.181 161.021V171.518C201.181 177.041 196.703 181.518 191.181 181.518H75.0843C69.5614 181.518 65.0842 177.041 65.0842 171.518V91.4702C65.0842 85.9473 69.5614 81.4702 75.0842 81.4702Z"
          fill="#8E99B7"
        />
        <path
          d="M63.9035 105.687H57.8794C51.252 105.687 45.8794 111.06 45.8794 117.687V170.699C45.8794 177.327 51.252 182.699 57.8794 182.699H63.9035"
          stroke="#8E99B7"
          stroke-width="2"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M209.205 104.687C216.384 104.687 222.205 110.507 222.205 117.687V157.622L220.205 156.409V117.687C220.205 111.611 215.28 106.687 209.205 106.687H203.181V104.687H209.205ZM220.121 172.064C219.449 177.495 214.818 181.699 209.205 181.699H203.181V183.699H209.205C215.562 183.699 220.853 179.135 221.982 173.106L220.121 172.064Z"
          fill="#8E99B7"
        />
        <path
          d="M201.102 84.0797L206.714 82.576C213.115 80.8607 219.695 84.6597 221.411 91.0613L235.136 142.286C236.85 148.68 233.062 155.254 226.67 156.978L222.434 158.121"
          stroke="#8E99B7"
          stroke-width="2"
        />
        <path
          d="M34.4095 181.699C33.8573 181.699 33.4095 182.147 33.4095 182.699C33.4095 183.251 33.8573 183.699 34.4095 183.699L34.4095 181.699ZM239.229 183.699C239.781 183.699 240.229 183.251 240.229 182.699C240.229 182.147 239.781 181.699 239.229 181.699L239.229 183.699ZM34.4095 183.699L239.229 183.699L239.229 181.699L34.4095 181.699L34.4095 183.699Z"
          fill="#8E99B7"
        />
        <path
          d="M230.103 169.111V174.093C234.67 172.017 233.425 167.865 233.01 165.374C232.678 163.381 228.719 161.499 226.782 160.807L194.811 141.707L192.735 147.105L230.103 169.111Z"
          fill="#E9ECFF"
        />
        <mask id="path-8-inside-1" fill="white">
          <path
            d="M198.133 129.251C198.133 150.577 180.845 167.865 159.519 167.865C138.194 167.865 120.906 150.577 120.906 129.251C120.906 107.926 138.194 90.6376 159.519 90.6376C180.845 90.6376 198.133 107.926 198.133 129.251ZM132.49 129.251C132.49 144.179 144.591 156.281 159.519 156.281C174.447 156.281 186.549 144.179 186.549 129.251C186.549 114.323 174.447 102.222 159.519 102.222C144.591 102.222 132.49 114.323 132.49 129.251Z"
          />
        </mask>
        <path
          d="M198.133 129.251C198.133 150.577 180.845 167.865 159.519 167.865C138.194 167.865 120.906 150.577 120.906 129.251C120.906 107.926 138.194 90.6376 159.519 90.6376C180.845 90.6376 198.133 107.926 198.133 129.251ZM132.49 129.251C132.49 144.179 144.591 156.281 159.519 156.281C174.447 156.281 186.549 144.179 186.549 129.251C186.549 114.323 174.447 102.222 159.519 102.222C144.591 102.222 132.49 114.323 132.49 129.251Z"
          stroke="#8E99B7"
          stroke-width="4"
          mask="url(#path-8-inside-1)"
        />
        <path
          d="M193.15 145.444C198.797 111.232 174.189 95.4819 161.18 91.8835C167.408 91.8835 175.712 94.3747 184.846 101.433C193.981 108.491 195.642 118.041 197.302 126.76C198.631 133.736 195.088 142.123 193.15 145.444Z"
          fill="#E9ECFF"
        />
        <mask id="path-10-inside-2" fill="white">
          <path
            d="M198.133 129.251C198.133 150.577 180.845 167.865 159.519 167.865C138.194 167.865 120.906 150.577 120.906 129.251C120.906 107.926 138.194 90.6376 159.519 90.6376C180.845 90.6376 198.133 107.926 198.133 129.251ZM132.49 129.251C132.49 144.179 144.591 156.281 159.519 156.281C174.447 156.281 186.549 144.179 186.549 129.251C186.549 114.323 174.447 102.222 159.519 102.222C144.591 102.222 132.49 114.323 132.49 129.251Z"
          />
        </mask>
        <path
          d="M198.133 129.251C198.133 150.577 180.845 167.865 159.519 167.865C138.194 167.865 120.906 150.577 120.906 129.251C120.906 107.926 138.194 90.6376 159.519 90.6376C180.845 90.6376 198.133 107.926 198.133 129.251ZM132.49 129.251C132.49 144.179 144.591 156.281 159.519 156.281C174.447 156.281 186.549 144.179 186.549 129.251C186.549 114.323 174.447 102.222 159.519 102.222C144.591 102.222 132.49 114.323 132.49 129.251Z"
          stroke="#8E99B7"
          stroke-width="4"
          mask="url(#path-10-inside-2)"
        />
        <circle cx="159.519" cy="129.251" r="27.6488" stroke="#8E99B7" stroke-width="2" />
        <path
          d="M188.168 153.629L223.555 174.06C226.733 175.894 230.796 174.806 232.63 171.628V171.628C234.465 168.451 233.376 164.388 230.199 162.553L194.811 142.123"
          stroke="#8E99B7"
          stroke-width="2"
        />
        <path
          d="M77.8313 95.0363H121.253"
          stroke="#8E99B7"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M77.8313 163.856H109.783"
          stroke="#8E99B7"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M77.8313 115.518H95.8554"
          stroke="#8E99B7"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M77.8313 170.41H136.819"
          stroke="#8E99B7"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <p>{{ $t("text") }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    delay: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      show: false,
    };
  },

  mounted() {
    setTimeout(() => (this.show = true), this.delay);
  },
};
</script>

<i18n src="./locale.json"></i18n>

<style lang="scss" scoped>
@import "../../App/css/variables";

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: -20px;

  p {
    margin-top: -65px;
    color: $secondary;
    font-size: 12px;
    text-align: center;
  }
}
</style>
