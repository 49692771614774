import { camelizeKeys } from "humps";
import axiosOpenApiWrapper from "../../lib/axios-open-api-wrapper";

export default async (csrfToken, sessionToken) => {
  const { status, data } = await axiosOpenApiWrapper({
    method: "get",
    url: "/api/shipping_carriers/",
    headers: {
      "X-CSRF-TOKEN": csrfToken,
      Authorization: `Bearer ${sessionToken}`,
    },
    validateStatus: () => true,
  });

  const payload = camelizeKeys(data.payload);
  const error = `${status}` !== "200";
  const shippingCarriers = payload?.shippingCarriers;
  return { error, shippingCarriers };
};
