<template>
  <div class="shippingCarrier">
    <div class="info">
      <h3>{{ $t("title") }}</h3>
      <div class="additional">
        {{ $t("status") }}<br />
        <span class="status" :class="{ active: v$.value.active.$model, inactive: !v$.value.active.$model }">
          {{ v$.value.active.$model ? $t("active") : $t("inactive") }}
        </span>
      </div>
    </div>
    <div class="inputs">
      <InputText
        v-model="v$.value.jppostConsignorId.$model"
        :invalid="v$.value.jppostConsignorId.$error"
        class="input"
        :label="$t('fields.consignorIdLabel')"
        :placeholder="$t('fields.consignorIdPlaceholder')"
      />
      <Button size="small" class="btn" :disabled="btnDisabled" @click="save">{{ btnText }}</Button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import { submitStatuses } from "../../../../stores/consts";
import notificationOnSubmit from "../../../../lib/notification-on-submit";
import InputText from "../../../inputs/InputText";
import Button from "../../../inputs/Button";

export default {
  components: {
    InputText,
    Button,
  },

  data() {
    return {
      v$: useVuelidate(),
      value: {
        active: false,
        jppostConsignorId: "",
      },
    };
  },

  validations() {
    return {
      value: {
        jppostConsignorId: {},
        active: {},
      },
    };
  },

  computed: {
    ...mapGetters({
      submitStatus: "shippingCarriers/submitStatus",
      jppostCarrier: "shippingCarriers/jppost",
    }),

    btnText() {
      if (this.submitStatus === submitStatuses.errored) return this.$t("buttons.errored");
      if (this.submitStatus === submitStatuses.submitting) return this.$t("buttons.saving");
      return this.$t("buttons.save");
    },

    btnDisabled() {
      return this.submitStatus === submitStatuses.submitting;
    },
  },

  watch: {
    jppostCarrier: {
      immediate: true,
      handler(jppostCarrier) {
        this.value = {
          ...this.value,
          ...jppostCarrier,
        };
      },
    },
  },

  methods: {
    ...mapActions({
      saveCarrier: "shippingCarriers/save",
    }),

    async save() {
      this.v$.$touch();

      if (this.submitStatus === submitStatuses.submitting) return;

      notificationOnSubmit(this, {
        statusWatcher: "shippingCarriers/submitStatus",
        onSuccessMessage: this.$t("notifications.success"),
        onErrorMessage: this.$t("notifications.error"),
      });

      this.saveCarrier({ type: "jppost", shippingCarrier: this.value });
    },
  },
};
</script>

<i18n src="./locale.json"></i18n>

<style lang="scss" scoped>
@import "../../../App/css/variables";

.shippingCarrier {
  display: flex;
  margin-bottom: 15px;
  padding: 20px;
  border: 2px solid $lines;
  border-radius: 8px;
  background-color: $white;
}

.info {
  display: flex;
  flex-basis: 30%;
  flex-direction: column;
  justify-content: space-between;

  h3 {
    font-weight: normal;
    font-size: 24px;
  }

  .additional {
    color: $tertiary;
    font-size: 12px;
  }

  .status {
    display: block;
    margin-top: 8px;
    color: $black;

    &.active {
      color: $success;
    }

    &.inactive {
      color: $danger;
    }
  }
}

.inputs {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-end;

  .input {
    width: 100%;
    margin-bottom: 15px;
  }

  .btn {
    width: 100px;
  }
}
</style>
