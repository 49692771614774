import { orderSearchFilters, orderSearchSorting } from "../consts";

const state = {
  query: "",
  filter: orderSearchFilters.allActive,
  sorting: orderSearchSorting.newestToOldest,
};

const getters = {
  query: ({ query }) => query,
  filter: ({ filter }) => filter,
  sorting: ({ sorting }) => sorting,
  result: ({ filter, sorting, query }, _getters, _rootState, rootGetters) =>
    rootGetters["orders/all"]
      .filter((order) => {
        if (filter === orderSearchFilters.allActive && !order.archived) return true;
        if (filter === orderSearchFilters.archived && order.archived) return true;
        if (filter === orderSearchFilters.fulfilled && order.fulfillmentStatus === "FULFILLED") return true;
        if (filter === orderSearchFilters.unfulfilled && order.fulfillmentStatus === "NOT_FULFILLED") return true;
        return false;
      })
      .filter((order) => {
        if (!query) return true;
        const searchBlob = [
          order.buyerFirstName,
          order.buyerLastName,
          order.totalPrice,
          order.wixNumericId,
          ...rootGetters["labels/forOrder"](order.id).flatMap((l) => l.address),
        ]
          .join()
          .toLowerCase();
        return searchBlob.indexOf(query) !== -1;
      })
      .sort((a, b) => {
        if (sorting === orderSearchSorting.newestToOldest) return a.wixNumericId <= b.wixNumericId ? 1 : -1;
        if (sorting === orderSearchSorting.oldestToNewest) return b.wixNumericId <= a.wixNumericId ? 1 : -1;

        if (sorting === orderSearchSorting.highestValueToLowest)
          return parseFloat(a.totalPrice) <= parseFloat(b.totalPrice) ? 1 : -1;
        if (sorting === orderSearchSorting.lowestValueToHighest)
          return parseFloat(b.totalPrice) <= parseFloat(a.totalPrice) ? 1 : -1;
        return 1;
      }),
};

const actions = {
  changeQuery({ commit }, value) {
    commit("SET_QUERY", value.toLowerCase());
  },
  changeFilter({ commit }, value) {
    commit("SET_FILTER", value);
  },
  changeSorting({ commit }, value) {
    commit("SET_SORTING", value);
  },
};

const mutations = {
  SET_QUERY(state, value) {
    state.query = value;
  },
  SET_FILTER(state, value) {
    state.filter = value;
  },
  SET_SORTING(state, value) {
    state.sorting = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
