{
  "jp": {
    "header": {
      "title": "ご利用プラン"
    },
    "title": "お客様のお支払いがWIXに記録されました。",
    "desc": "WIXからすべての詳細について通知されるまで、数分かかる場合があります。契約ページでプランをご確認ください。"
  },
  "en": {
    "header": {
      "title": "Subscription"
    },
    "title": "Your payment has been successfully recorded on WIX!",
    "desc": "It may take up to a few minutes for WIX to notify us about all details. Please check the subscription page, your plan should be marked as selected just in a moment."
  }
}
