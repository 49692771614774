<template>
  <component :is="type === 'normal' ? 'InputCheckboxNormal' : 'InputCheckboxCompact'" :value="value" :label="label" />
</template>

<script>
import InputCheckboxNormal from "./InputCheckboxNormal";
import InputCheckboxCompact from "./InputCheckboxCompact";

export default {
  components: {
    InputCheckboxNormal,
    InputCheckboxCompact,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "normal",
    },
  },
};
</script>
