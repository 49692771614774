<template>
  <transition-group name="animationList" class="animationList-wrapper" tag="div">
    <slot></slot>
  </transition-group>
</template>

<script>
export default {};
</script>

<style lang="scss">
/* stylelint-disable selector-class-pattern */
@import "../../App/css/variables";

.animationList-wrapper {
  position: relative;
}

.animationList-leave-active {
  position: absolute;
  right: 0;
  left: 0;
}

.animationList-leave-to {
  transform: translateY(30px);
  opacity: 0;
}

.animationList-item {
  transition: transform 0.8s ease, opacity 0.8s ease;
}
</style>
