<template>
  <BaseModal @close="$emit('close')">
    <div class="content">
      <h1>{{ $t("title") }}</h1>
      <p>{{ $t("subtitle") }}</p>
      <hr />
      <Button class="btn" size="big" warning :disabled="btnDisabled" @click="cancelSubscription">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-html="$t('buttons.cancelSubscription')" />
      </Button>
      <Button class="btn" size="big" type="noBorders" @click="$emit('close')">{{ $t("buttons.back") }}</Button>
    </div>
  </BaseModal>
</template>

<script>
import { mapActions } from "vuex";
import { submitStatuses } from "../../../stores/consts";
import BaseModal from "../Base";
import Button from "../../inputs/Button";

export default {
  components: {
    BaseModal,
    Button,
  },

  data() {
    return {
      redirectingToWix: false,
    };
  },

  computed: {
    btnDisabled() {
      return this.redirectingToWix;
    },
  },

  watch: {
    submitStatus(newValue) {
      if (newValue === submitStatuses.submitted) this.$emit("close");
    },
  },

  methods: {
    ...mapActions({
      cancelSubscriptionVuex: "subscriptions/cancel",
      queueNotification: "notifications/queue",
    }),

    cancelSubscription() {
      const subscriptionWixUrl = "https://manage.wix.com/account/subscriptions";
      this.redirectingToWix = true;
      this.queueNotification({ message: this.$t("notifications.redirectingToWix") });
      setTimeout(() => (window.location.href = subscriptionWixUrl), 2000);
    },
  },
};
</script>

<i18n src="./locale.json"></i18n>

<style lang="scss" scoped>
@import "../../App/css/variables";

.content {
  margin-top: -50px;
  padding-right: 50%;
  color: $primary;
  background: url("../../../assets/images/boxes2.svg") 100% calc(100% - 50px) no-repeat;
  background-size: 48%;

  h1 {
    font-size: 38px;
  }

  p {
    padding: 25px 0 40px;
  }

  hr {
    width: 75px;
    height: 2px;
    margin: 0 0 43px;
    padding: 0;
    border: 0;
    background-color: $lines;
  }
}

.btn {
  width: 70%;
  margin-bottom: 10px;
}
</style>
