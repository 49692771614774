import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import { createI18n } from "vue-i18n";
import App from "./components/App";
import routes from "./components/App/routes";
import store from "./stores";

if (window.vueData?.navigateTo) {
  window.history.pushState({}, "", window.vueData.navigateTo);
}

const router = createRouter({
  routes,
  history: createWebHistory(),
});

const i18n = createI18n({
  locale: "jp", // needed to be here but the used value comes from vuex
  fallbackLocale: "en",
});

const app = createApp(App);
app.config.globalProperties.csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute("content");
app.use(router).use(store).use(i18n).mount("#app");
