<template>
  <BaseModal
    :title="value.id ? $t('header.editShippingPreset') : $t('header.addShippingPreset')"
    @close="$emit('close')"
  >
    <div class="content">
      <div class="row">
        <InputText
          v-model="v$.value.name.$model"
          :invalid="v$.value.name.$error"
          class="input"
          :label="$t('fields.nameLabel')"
          :placeholder="$t('fields.namePlaceholder')"
        />

        <InputText
          v-model="v$.value.businessName.$model"
          :invalid="v$.value.businessName.$error"
          class="input"
          :label="$t('fields.businessNameLabel')"
          :placeholder="$t('fields.businessNamePlaceholder')"
        />
      </div>
      <div class="row">
        <InputText
          v-model="v$.value.address.$model"
          :invalid="v$.value.address.$error"
          class="input"
          :label="$t('fields.addressLabel')"
          :placeholder="$t('fields.addressPlaceholder')"
        />
        <InputText
          v-model="v$.value.addressZipCode.$model"
          :invalid="v$.value.addressZipCode.$error"
          class="input"
          :label="$t('fields.zipCodeLabel')"
          :placeholder="$t('fields.zipCodePlaceholder')"
        />
      </div>

      <div class="row">
        <InputText
          v-model="v$.value.phoneNr.$model"
          :invalid="v$.value.phoneNr.$error"
          class="input"
          :label="$t('fields.phoneLabel')"
          :placeholder="$t('fields.phonePlaceholder')"
        />
        <InputCheckbox
          :value="v$.value.default.$model"
          :invalid="v$.value.default.$error"
          :label="$t('fields.defaultLabel')"
          class="input"
          @change="changeDefault"
        />
      </div>
    </div>
    <div class="actions">
      <Button class="btn" type="inverted" size="small" @click="$emit('close')">{{ $t("buttons.back") }}</Button>
      <Button class="btn" size="small" :disabled="btnDisabled" @click="complete">{{ btnText }}</Button>
    </div>
  </BaseModal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, numeric, minLength, maxLength } from "@vuelidate/validators";
import { submitStatuses } from "../../../stores/consts";
import notificationOnSubmit from "../../../lib/notification-on-submit";
import Button from "../../inputs/Button";
import InputText from "../../inputs/InputText";
import InputCheckbox from "../../inputs/InputCheckbox";
import BaseModal from "../Base";

export default {
  components: {
    BaseModal,
    Button,
    InputText,
    InputCheckbox,
  },
  props: {
    shippingPreset: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      v$: useVuelidate(),
      value: {
        name: "",
        businessName: "",
        address: "",
        addressZipCode: "",
        phoneNr: "",
        default: false,
      },
    };
  },

  validations() {
    return {
      value: {
        name: { required },
        businessName: { required },
        address: { required },
        addressZipCode: { required, numeric, minLength: minLength(7), maxLength: maxLength(7) },
        phoneNr: {},
        default: {},
      },
    };
  },

  computed: {
    ...mapGetters({
      submitStatus: "shippingPresets/submitStatus",
    }),

    btnText() {
      if (this.submitStatus === submitStatuses.errored) return this.$t("buttons.errored");
      if (this.submitStatus === submitStatuses.submitting) return this.$t("buttons.saving");
      return this.$t("buttons.save");
    },

    btnDisabled() {
      return this.v$.value.$error;
    },
  },

  watch: {
    submitStatus(newValue) {
      if (newValue === submitStatuses.submitted) this.$emit("close");
    },
  },

  mounted() {
    this.value = {
      ...this.value,
      ...this.shippingPreset,
    };
  },

  methods: {
    ...mapActions({
      update: "shippingPresets/update",
      create: "shippingPresets/create",
    }),

    async complete() {
      if (!(await this.v$.$validate())) return;
      if (this.submitStatus === submitStatuses.submitting) return;

      if (this.value.id) {
        notificationOnSubmit(this, {
          statusWatcher: "shippingPresets/submitStatus",
          onSuccessMessage: this.$t("notifications.updatedSuccess"),
          onErrorMessage: this.$t("notifications.updatedError"),
        });
        this.update(this.value);
      } else {
        notificationOnSubmit(this, {
          statusWatcher: "shippingPresets/submitStatus",
          onSuccessMessage: this.$t("notifications.createdSuccess"),
          onErrorMessage: this.$t("notifications.createdError"),
        });
        this.create(this.value);
      }
    },

    changeDefault(value) {
      if (!this.shippingPreset.default) this.v$.value.default.$model = value;
    },
  },
};
</script>

<i18n src="./locale.json"></i18n>

<style lang="scss" scoped>
@import "../../App/css/variables";

.content {
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
}

.actions {
  display: flex;
  justify-content: center;
  text-align: center;

  .btn {
    width: 100%;
    max-width: 135px;
    margin: 0 25px;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;
}

.input {
  width: 45%;
}
</style>
