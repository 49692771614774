import { createStore } from "vuex";
import config from "./modules/config";
import session from "./modules/session";
import user from "./modules/user";
import shippingPresets from "./modules/shippingPresets";
import shippingCarriers from "./modules/shippingCarriers";
import orders from "./modules/orders";
import orderSearch from "./modules/orderSearch";
import labels from "./modules/labels";
import notifications from "./modules/notifications";
import subscriptions from "./modules/subscriptions";
import confirmBox from "./modules/confirmBox";

const actions = {
  fetchData({ rootGetters, dispatch }) {
    if (rootGetters["session/isLoggedIn"]) {
      dispatch("user/fetch");
      dispatch("orders/fetch");
      dispatch("labels/fetch");
      dispatch("shippingPresets/fetch");
      dispatch("shippingCarriers/fetch");
      dispatch("subscriptions/fetch");
    } else {
      dispatch("session/login");
    }
  },
};

export default new createStore({
  strict: true,
  actions,
  modules: {
    config,
    shippingPresets,
    shippingCarriers,
    session,
    user,
    orders,
    orderSearch,
    labels,
    notifications,
    subscriptions,
    confirmBox,
  },
});
