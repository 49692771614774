<template>
  <div class="inputSelectWrapper" :class="{ invalid }">
    <span v-if="label" class="label" :class="{ noBorders, invalid }">{{ label }}</span>
    <span class="value" :class="{ placeholder: !computedValue, invalid }">{{ displayedValue }}</span>

    <select :id="id" @change="(e) => change(e.target.value)">
      <option v-if="!!placeholder" value="">{{ placeholder }}</option>
      <option
        v-for="(option, index) in values"
        :key="option.value"
        :value="index"
        :selected="option.value === computedValue"
      >
        {{ option.name }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String],
      default: "",
    },
    modelValue: {
      type: [Number, String],
      default: null,
    },
    values: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    noBorders: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      id: `id-${Math.random()}`,
    };
  },
  computed: {
    computedValue() {
      return this.modelValue || this.value;
    },

    displayedValue() {
      if (!this.computedValue) return this.placeholder;
      const nameForValue = this.values.find((p) => p.value === this.computedValue)?.name;
      return nameForValue;
    },
  },

  methods: {
    change(indexString) {
      const value = this.values[parseInt(indexString)]?.value;
      this.customEmit(value);
    },

    customEmit(value) {
      this.$emit("change", value);
      this.$emit("update:modelValue", value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../App/css/variables";

.inputSelectWrapper {
  position: relative;
  height: 46px;
  border: 2px solid $lines;
  border-radius: 8px;

  // arrow
  &::before {
    position: absolute;
    top: calc(50% - 1px);
    right: 13px;
    border-color: $lines transparent transparent transparent;
    border-style: solid;
    border-width: 6px 6px 0;
    content: "";
  }

  // inner border
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 40px;
    border-left: 2px solid $lines;
    content: "";
  }

  &.invalid {
    border-color: $danger;

    &::before {
      border-color: $danger transparent transparent transparent;
    }

    &::after {
      border-color: $danger;
    }
  }

  &.noBorders {
    border-color: transparent;
  }
}

.label {
  position: absolute;
  top: -7px;
  left: 17px;
  z-index: 1;
  color: $secondary;
  font-size: 10px;

  &::after {
    position: absolute;
    top: 4px;
    z-index: -1;
    display: block;
    width: calc(100% + 10px);
    height: 5px;
    margin-left: -5px;
    background-color: $white;
    content: "";
  }

  &.invalid {
    color: $danger;
  }

  &.noBorders {
    top: -6px;

    &::after {
      top: 7px;
    }
  }
}

.value {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 16px;
  color: $primary;
  font-size: 14px;

  &.placeholder {
    color: $secondary;
  }

  &.invalid {
    color: $danger;
  }
}

select {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  padding-top: 45px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  appearance: none;
}
</style>
