<template>
  <BaseModal @close="$emit('close')">
    <template #title>{{ page === 0 ? $t("header.selectYourAddress") : $t("header.shippingInformation") }}</template>
    <template v-if="page === 0" #additional>
      <Button type="noBorders" @click="navigateToShippingPresetScreen">{{ $t("header.createNewPreset") }}</Button>
    </template>

    <div ref="content" class="content" :style="{ transform: `translateX(-${100 * page}%)` }">
      <span v-if="showDeletedShippingPresetWarning" class="warning">
        {{ $t("shippingPresetRemovedInvalid") }}
        <router-link to="/settings/shipping-presets">{{ $t("shippingPresetRemovedInvalidLink") }}</router-link>
      </span>
      <div class="page selectShippingInfo">
        <div class="scrollWrapper">
          <div class="scrollableContent">
            <div
              v-for="shippingPreset in shippingPresets"
              :key="shippingPreset.id"
              :class="['shippingPreset', { active: shippingPreset.id === value.shippingPresetId }]"
            >
              <span :ref="`shippingPresetScrollTarget-${shippingPreset.id}`" class="scrollTarget" />
              <table>
                <tr>
                  <td class="fieldName">{{ $t("fields.name") }}</td>
                  <td>
                    <i>{{ shippingPreset.name }}</i>
                  </td>
                </tr>
                <tr>
                  <td class="fieldName">{{ $t("fields.businessName") }}</td>
                  <td>{{ shippingPreset.businessName }}</td>
                </tr>
                <tr>
                  <td class="fieldName">{{ $t("fields.address") }}</td>
                  <td>{{ shippingPreset.address }}</td>
                </tr>
                <tr>
                  <td class="fieldName">{{ $t("fields.phoneNumber") }}</td>
                  <td>{{ shippingPreset.phoneNr }}</td>
                </tr>
              </table>
              <Button
                class="btn"
                size="big"
                :type="shippingPreset.id === value.shippingPresetId ? 'filled' : 'inverted'"
                @click="
                  v$.value.shippingPresetId.$model = shippingPreset.id;
                  scrollToActiveShippingPreset();
                "
              >
                {{ $t("buttons.select") }}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div class="page">
        <div class="row">
          <template v-if="locale === 'en'">
            <InputText
              v-model="v$.value.shippingFirstName.$model"
              :invalid="v$.value.shippingFirstName.$error"
              class="input"
              :label="$t('fields.firstNameLabel')"
              :placeholder="$t('fields.firstNamePlaceholder')"
            />
            <InputText
              v-model="v$.value.shippingLastName.$model"
              :invalid="v$.value.shippingLastName.$error"
              class="input"
              :label="$t('fields.lastNameLabel')"
              :placeholder="$t('fields.lastNamePlaceholder')"
            />
          </template>
          <template v-else>
            <InputText
              v-model="v$.value.shippingLastName.$model"
              :invalid="v$.value.shippingLastName.$error"
              class="input"
              :label="$t('fields.lastNameLabel')"
              :placeholder="$t('fields.lastNamePlaceholder')"
            />
            <InputText
              v-model="v$.value.shippingFirstName.$model"
              :invalid="v$.value.shippingFirstName.$error"
              class="input"
              :label="$t('fields.firstNameLabel')"
              :placeholder="$t('fields.firstNamePlaceholder')"
            />
          </template>
        </div>
        <div class="row">
          <InputText
            v-model="v$.value.email.$model"
            class="input"
            :label="$t('fields.emailLabel')"
            :placeholder="$t('fields.emailPlaceholder')"
          />
          <InputText
            v-model="v$.value.shippingPhoneNr.$model"
            :invalid="v$.value.shippingPhoneNr.$error"
            class="input"
            :label="$t('fields.phoneLabel')"
            :placeholder="$t('fields.phonePlaceholder')"
          />
        </div>
        <div class="row">
          <InputText
            v-model="v$.value.shippingAddressCompanyName.$model"
            :invalid="v$.value.shippingAddressCompanyName.$error"
            class="input"
            :label="$t('fields.companyLabel')"
            :placeholder="$t('fields.companyPlaceholder')"
          />
          <InputDate
            v-model="v$.value.deliveryDate.$model"
            :invalid="v$.value.deliveryDate.$error"
            class="input"
            :label="$t('fields.deliveryDateLabel')"
          />
        </div>
        <div class="row">
          <InputText
            v-model="v$.value.shippingAddressLine.$model"
            :invalid="v$.value.shippingAddressLine.$error"
            class="input"
            :label="$t('fields.addressLabel')"
            :placeholder="$t('fields.addressPlaceholder')"
          />
          <InputText
            v-model="v$.value.shippingAddressZipCode.$model"
            :invalid="v$.value.shippingAddressZipCode.$error"
            class="input"
            :label="$t('fields.zipCodeLabel')"
            :placeholder="$t('fields.zipCodePlaceholder')"
          />
        </div>
      </div>
      <div class="page">
        <span v-if="showDeactivatedShippingCarrierWarning" class="warning">
          {{ $t("shippingCarrierDeactivated") }}
        </span>
        <div class="row">
          <InputSelect
            v-model="v$.value.shippingCarrierId.$model"
            :invalid="v$.value.shippingCarrierId.$error"
            :values="availableShippingCarriers"
            class="input"
            :label="$t('fields.shippingCarrierLabel')"
            :placeholder="$t('fields.shippingCarrierPlaceholder')"
          />
          <InputText
            v-model.number="v$.value.numberOfParcels.$model"
            :invalid="v$.value.numberOfParcels.$error"
            class="input"
            numbers
            :label="$t('fields.numberOfParcelsLabel')"
            :placeholder="$t('fields.numberOfParcelsPlaceholder')"
          />
        </div>
        <div class="row">
          <InputText
            v-model="v$.value.weight.$model"
            :invalid="v$.value.weight.$error"
            class="input"
            :label="$t('fields.weightLabel')"
            :placeholder="$t('fields.weightPlaceholder')"
          />
          <InputText
            v-model="v$.value.itemName.$model"
            :invalid="v$.value.itemName.$error"
            class="input"
            :label="$t('fields.itemNameLabel')"
            :placeholder="$t('fields.itemNamePlaceholder')"
          />
        </div>
      </div>
    </div>
    <div class="actions">
      <Button class="btn" type="inverted" size="small" @click="page === 0 ? $emit('close') : page--">
        {{ $t("buttons.back") }}
      </Button>
      <Button
        class="btn"
        size="small"
        :disabled="invalidCurrentPageData"
        @click="page === pageCount - 1 ? complete() : nextPage()"
      >
        {{ btnText }}
      </Button>
    </div>
    <div class="footer">
      <span v-if="invalidCurrentPageData">{{ $t("invalid") }}</span>
      <Dots v-else :count="pageCount" :value="page" @change="page = $event" />
    </div>
  </BaseModal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, email, numeric, minValue, minLength, maxLength } from "@vuelidate/validators";
import { submitStatuses } from "../../../stores/consts";
import notificationOnSubmit from "../../../lib/notification-on-submit";
import confirmBox from "../../../lib/confirm-box";
import Button from "../../inputs/Button";
import InputText from "../../inputs/InputText";
import InputSelect from "../../inputs/InputSelect";
import InputDate from "../../inputs/InputDate";
import Dots from "../../inputs/Dots";
import BaseModal from "../Base";

export default {
  components: {
    BaseModal,
    Button,
    InputText,
    InputSelect,
    InputDate,
    Dots,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    label: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      v$: useVuelidate(),
      pageCount: 0,
      page: 0,
      value: {
        shippingFirstName: "",
        shippingLastName: "",
        email: "",
        shippingAddressCompanyName: "",
        shippingPhoneNr: "",
        shippingAddressZipCode: "",
        shippingAddressLine: "",
        shippingCarrierId: 0,
        numberOfParcels: 1,
        weight: "",
        itemName: "",
        deliveryDate: "",
        shippingPresetId: 0,
      },
    };
  },

  validations() {
    return {
      value: {
        shippingFirstName: { required },
        shippingLastName: { required },
        email: { email },
        shippingAddressCompanyName: {},
        shippingPhoneNr: { required },
        shippingAddressZipCode: { required, numeric, minLength: minLength(7), maxLength: maxLength(7) },
        shippingAddressLine: { required },
        shippingCarrierId: {
          required,
          numeric,
          exists(shippingCarrierId) {
            return !!this.availableShippingCarriers.find(({ value }) => value === shippingCarrierId);
          },
        },
        numberOfParcels: { required, numeric, minValue: minValue(1) },
        itemName: { required, maxLength: maxLength(100) },
        deliveryDate: {
          required,
          isInFuture(deliveryDateString) {
            return Date.parse(deliveryDateString) > new Date();
          },
        },
        weight: {},
        shippingPresetId: {
          required,
          usesExistingShippingPreset(shippingPresetId) {
            return !!this.shippingPresets.find(({ id }) => id === shippingPresetId);
          },
        },
      },
    };
  },

  computed: {
    ...mapGetters({
      submitStatus: "labels/submitStatus",
      jppostCarrier: "shippingCarriers/jppost",
      shippingPresets: "shippingPresets/allValid",
      defaultShippingPreset: "shippingPresets/default",
      locale: "user/locale",
    }),

    btnText() {
      if (this.submitStatus === submitStatuses.submitting) return this.$t("buttons.saving");
      if (this.page === this.pageCount - 1) return this.$t("buttons.complete");
      return this.$t("buttons.next");
    },

    invalidCurrentPageData() {
      return (
        (this.page === 0 && this.v$.value.shippingPresetId.$invalid) ||
        (this.page === this.pageCount - 1 && this.v$.value.$error) ||
        (this.page === this.pageCount - 2 &&
          (this.v$.value.shippingFirstName.$invalid ||
            this.v$.value.shippingLastName.$invalid ||
            this.v$.value.email.$invalid ||
            this.v$.value.shippingAddressCompanyName.$invalid ||
            this.v$.value.shippingPhoneNr.$invalid ||
            this.v$.value.shippingAddressZipCode.$invalid ||
            this.v$.value.shippingAddressLine.$invalid ||
            this.v$.value.deliveryDate.$invalid))
      );
    },

    showDeletedShippingPresetWarning() {
      const shippingPreset = this.shippingPresets.find(({ id }) => id === this.value.shippingPresetId);
      return !shippingPreset || !shippingPreset.validData;
    },

    showDeactivatedShippingCarrierWarning() {
      return (
        this.label.shippingCarrierId &&
        this.availableShippingCarriers.filter(({ value }) => value === this.label.shippingCarrierId).length === 0
      );
    },

    availableShippingCarriers() {
      const carriers = [];
      if (this.jppostCarrier?.active) carriers.push({ name: "JAPAN POST", value: this.jppostCarrier.id });
      return carriers;
    },
  },

  watch: {
    submitStatus(newValue) {
      if (newValue === submitStatuses.submitted) this.$emit("close");
    },

    page(newValue) {
      if (newValue === 0) this.scrollToActiveShippingPreset();
    },
  },

  mounted() {
    this.pageCount = this.$refs.content.getElementsByClassName("page").length;
    this.value = {
      ...this.value,
      shippingPresetId: this.defaultShippingPreset?.id,
      ...this.label,
    };
    setTimeout(() => {
      this.scrollToActiveShippingPreset();
      this.v$.$validate();
    }, 0);
  },

  methods: {
    ...mapActions({
      updateLabel: "labels/update",
      createLabel: "labels/create",
    }),

    nextPage() {
      this.page++;
      this.v$.$validate();
    },

    async complete() {
      if (!(await this.v$.$validate())) return;
      if (this.submitStatus === submitStatuses.submitting) return;

      if (this.value.id) {
        notificationOnSubmit(this, {
          statusWatcher: "labels/submitStatus",
          onSuccessMessage: this.$t("notifications.createdSuccess"),
          onErrorMessage: this.$t("notifications.createdError"),
        });
        this.updateLabel(this.value);
      } else {
        notificationOnSubmit(this, {
          statusWatcher: "labels/submitStatus",
          onSuccessMessage: this.$t("notifications.updatedSuccess"),
          onErrorMessage: this.$t("notifications.updatedError"),
        });
        this.createLabel({ order: this.order, label: this.value });
      }
    },

    scrollToActiveShippingPreset() {
      const id = this.value.shippingPresetId;
      this.$refs[`shippingPresetScrollTarget-${id}`]?.scrollIntoView?.({ behavior: "smooth" });
    },

    async navigateToShippingPresetScreen() {
      if (!(await confirmBox(this, { title: this.$t("changesWillBeLostConfirm") }))) return;
      this.$router.push({ path: "/settings/shipping-presets" });
    },
  },
};
</script>

<i18n src="./locale.json"></i18n>

<style lang="scss" scoped>
@import "../../App/css/variables";

.content {
  display: flex;
  margin: 0 -80px;
  padding-bottom: 40px;
  transition: all 300ms ease;

  .page {
    position: relative;
    flex-shrink: 0;
    width: 100%;
    padding: 0 80px;
  }
}

.actions {
  display: flex;
  justify-content: center;
  text-align: center;

  .btn {
    width: 100%;
    max-width: 135px;
    margin: 0 25px;
  }
}

.footer {
  min-height: 30px;
  margin: 30px auto 0;

  span {
    display: block;
    color: $secondary;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;

  .input {
    width: 45%;
  }
}

.selectShippingInfo {
  margin-bottom: -20px;
  padding-right: 0 !important;
  padding-bottom: 20px;
  padding-left: 0 !important;
  overflow: hidden;

  .scrollWrapper {
    height: calc(100% + 20px);
    padding: 0 80px;
    overflow: auto;
  }

  .scrollableContent {
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    height: calc(100% - 20px);
    padding: 0;

    &::after {
      display: block;
      flex: 0 0 40px;
      content: "";
    }
  }

  .shippingPreset {
    position: relative;
    display: flex;
    flex: 0 0 47.5%;
    flex-direction: column;
    max-width: 343px;
    margin-right: 36px;
    padding: 15px;
    border: 2px solid $lines;
    border-radius: 8px;

    &.active {
      border-color: $inputs;
    }

    table {
      flex-grow: 1;
      font-size: 14px;

      td {
        vertical-align: top;
      }

      .fieldName {
        font-weight: bold;
      }
    }

    .scrollTarget {
      display: none;
    }

    &:first-child .scrollTarget {
      position: absolute;
      top: -100px;
      left: -75px;
      display: block;
    }

    &:last-child .scrollTarget {
      position: absolute;
      top: -100px;
      right: -75px;
      display: block;
    }
  }

  .btn {
    margin-top: 10px;
  }
}

.warning {
  position: absolute;
  top: -25px;
  left: 80px;
  color: $danger;
  font-size: 12px;

  &:deep(a) {
    color: $danger;
  }
}
</style>
