<template>
  <div class="sidePanel">
    <router-link to="/" class="logo">Unipostal</router-link>

    <div v-if="isLoggedIn" class="menuGroup">
      <router-link to="/orders" class="menuItem order" :title="$t('orders')" />
      <span class="menuSeparator" />
      <router-link to="/settings" class="menuItem gear" :title="$t('settings')" />
    </div>
    <div class="menuGroup">
      <LanguageSelect />
      <span v-if="isLoggedIn" class="menuSeparator" />
      <a v-if="isLoggedIn" class="menuItem logout" :title="$t('logout')" @click="logout" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LanguageSelect from "../inputs/LanguageSelect";

export default {
  components: {
    LanguageSelect,
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "session/isLoggedIn",
    }),
  },

  methods: {
    ...mapActions({
      logoutVuex: "session/logout",
      queueNotification: "notifications/queue",
    }),

    logout() {
      this.logoutVuex();
      this.queueNotification({ message: this.$t("notifications.logoutSuccess") });
    },
  },
};
</script>

<i18n src="./locale.json"></i18n>

<style lang="scss" scoped>
@import "../App/css/variables";

.sidePanel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 22px 0;
  border-right: 1px solid $lines;
}

.logo {
  display: block;
  width: 50px;
  height: 0;
  padding-top: 48px;
  overflow: hidden;
  background: url("../../assets/images/logo.svg") no-repeat 50% 0;
  background-size: contain;
}

.menuGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menuSeparator {
  display: flex;
  align-items: center;
  width: 45px;
  height: 1px;
  margin-top: 30px;
  background-color: $lines;
}

.menuItem {
  display: flex;
  align-items: center;
  width: 30px;
  height: 30px;
  margin-top: 30px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  cursor: pointer;

  /* stylelint-disable-next-line selector-class-pattern */
  &.router-link-active,
  &:hover {
    filter: invert(41%) sepia(61%) saturate(1956%) hue-rotate(189deg) brightness(88%) contrast(96%);
  }

  &.logout {
    margin-left: -7px;
    background-image: url("../../assets/images/icons/logout.svg");
  }

  &.gear {
    background-image: url("../../assets/images/icons/gear.svg");
  }

  &.order {
    background-image: url("../../assets/images/icons/order.svg");
  }
}
</style>
