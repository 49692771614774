<template>
  <div class="withStepsPanel">
    <SidePanel class="sidePanel" />
    <StepsPanel class="stepsPanel" />
    <div id="layoutContentWrapper" class="layoutContentWrapper">
      <div class="layoutContent">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import SidePanel from "../../SidePanel";
import StepsPanel from "../../StepsPanel";

export default {
  components: {
    SidePanel,
    StepsPanel,
  },
};
</script>

<style lang="scss" scoped>
@import "../../App/css/variables";

.withStepsPanel {
  display: flex;
  width: 100%;
  height: 100%;
}

.sidePanel {
  flex: 80px 0 0;
}

.stepsPanel {
  flex: 308px 0 0;
}

.layoutContent {
  flex-grow: 1;
  height: 100%;
  overflow: scroll;
}
</style>
