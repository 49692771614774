import { deepClone } from "../../lib/utils";
import { fetchStatuses, submitStatuses } from "../consts";
import fetchLabelsApi from "../api/fetchLabels";
import createLabelApi from "../api/createLabel";
import updateLabelApi from "../api/updateLabel";
import removeLabelApi from "../api/removeLabel";
import generatePostLabelApi from "../api/generatePostLabel";

const state = {
  fetchStatus: fetchStatuses.idle,
  submitStatus: submitStatuses.idle,
  labels: [],
};

const getters = {
  fetched: ({ fetchStatus }) => fetchStatus === fetchStatuses.fetched,
  submitting: ({ submitStatus }) => submitStatus === submitStatuses.submitting,
  submitStatus: ({ submitStatus }) => submitStatus,
  forOrder: ({ labels }) => (orderId) => labels.filter((l) => l.orderId === orderId),
  url: ({ labelsUrl }) => (label) => labelsUrl[label.id] || "",
};

const actions = {
  async fetch({ commit, rootGetters }) {
    const sessionToken = rootGetters["session/sessionToken"];
    const csrfToken = rootGetters["config/csrfToken"];

    commit("SET_FETCH_STATUS", fetchStatuses.fetching);

    const { error, labels } = await fetchLabelsApi(csrfToken, sessionToken);

    if (error) return commit("SET_FETCH_STATUS", fetchStatuses.errored);

    commit("SET_LABELS", labels);
    commit("SET_FETCH_STATUS", fetchStatuses.fetched);
  },

  async generatePostLabel({ dispatch, commit, rootGetters }, label) {
    const sessionToken = rootGetters["session/sessionToken"];
    const csrfToken = rootGetters["config/csrfToken"];

    commit("SET_SUBMIT_STATUS", submitStatuses.submitting);
    const { error, label: updatedLabel, order: updatedOrder } = await generatePostLabelApi(
      csrfToken,
      sessionToken,
      label.id
    );
    if (error) return commit("SET_SUBMIT_STATUS", submitStatuses.errored);

    commit("UPDATE_LABEL", updatedLabel);
    commit("SET_SUBMIT_STATUS", submitStatuses.submitted);
    dispatch("orders/update", updatedOrder, { root: true });
    setTimeout(() => commit("SET_SUBMIT_STATUS", submitStatuses.idle), 0);
  },

  async create({ dispatch, commit, rootGetters }, { order, label }) {
    const sessionToken = rootGetters["session/sessionToken"];
    const csrfToken = rootGetters["config/csrfToken"];

    commit("SET_SUBMIT_STATUS", submitStatuses.submitting);
    const { error, label: createdLabel, order: updatedOrder } = await createLabelApi(
      csrfToken,
      sessionToken,
      order.id,
      label
    );
    if (error) return commit("SET_SUBMIT_STATUS", submitStatuses.errored);

    dispatch("orders/update", updatedOrder, { root: true });
    commit("ADD_LABEL", createdLabel);
    commit("SET_SUBMIT_STATUS", submitStatuses.submitted);
    setTimeout(() => commit("SET_SUBMIT_STATUS", submitStatuses.idle), 0);
  },

  async update({ dispatch, commit, rootGetters }, label) {
    const sessionToken = rootGetters["session/sessionToken"];
    const csrfToken = rootGetters["config/csrfToken"];

    commit("SET_SUBMIT_STATUS", submitStatuses.submitting);
    const { error, order: updatedOrder, label: updatedLabel } = await updateLabelApi(
      csrfToken,
      sessionToken,
      label.id,
      label
    );
    if (error) return commit("SET_SUBMIT_STATUS", submitStatuses.errored);

    dispatch("orders/update", updatedOrder, { root: true });
    commit("UPDATE_LABEL", updatedLabel);
    commit("SET_SUBMIT_STATUS", submitStatuses.submitted);
    setTimeout(() => commit("SET_SUBMIT_STATUS", submitStatuses.idle), 0);
  },

  async remove({ dispatch, commit, rootGetters }, label) {
    const sessionToken = rootGetters["session/sessionToken"];
    const csrfToken = rootGetters["config/csrfToken"];

    commit("SET_SUBMIT_STATUS", submitStatuses.submitting);
    const { error, order: updatedOrder } = await removeLabelApi(csrfToken, sessionToken, label.id);
    if (error) return commit("SET_SUBMIT_STATUS", submitStatuses.errored);

    dispatch("orders/update", updatedOrder, { root: true });
    commit("REMOVE_LABEL", label);
    commit("SET_SUBMIT_STATUS", submitStatuses.submitted);
    setTimeout(() => commit("SET_SUBMIT_STATUS", submitStatuses.idle), 0);
  },
};

const mutations = {
  SET_LABELS(state, labels) {
    state.labels = labels;
  },

  SET_FETCH_STATUS(state, fetchStatus) {
    state.fetchStatus = fetchStatus;
  },

  SET_SUBMIT_STATUS(state, submitStatus) {
    state.submitStatus = submitStatus;
  },

  ADD_LABEL(state, label) {
    state.labels.push(label);
  },

  UPDATE_LABEL(state, label) {
    const labels = deepClone(state.labels);
    labels[labels.findIndex(({ id }) => id === label.id)] = label;

    state.labels = labels;
  },

  REMOVE_LABEL(state, label) {
    state.labels = state.labels.filter(({ id }) => label.id !== id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
