<template>
  <Notifications />
  <div class="justSidePanel">
    <SidePanel class="sidePanel" />
    <div class="layoutContent">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import SidePanel from "../../SidePanel";
import Notifications from "../../Notifications";

export default {
  components: {
    SidePanel,
    Notifications,
  },
};
</script>

<style lang="scss" scoped>
@import "../../App/css/variables";

.justSidePanel {
  display: flex;
  width: 100%;
  height: 100%;
}

.sidePanel {
  flex: 80px 0 0;
}

.layoutContent {
  flex-grow: 1;
  height: 100%;
  overflow: auto;
}
</style>
