<template>
  <Notifications />
  <slot></slot>
</template>

<script>
import Notifications from "../../Notifications";

export default {
  components: {
    Notifications,
  },
};
</script>
