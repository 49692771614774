{
  "jp": {
    "header": "請求内容",
    "link": "Wixお支払い履歴ページ"
  },
  "en": {
    "header": "Invoices",
    "link": "Go to WIX billing page"
  }
}
