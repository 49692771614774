{
  "jp": {
    "header": {
      "title": "ご利用プラン"
    },
    "buttons": {
      "selectPlanMonthly": "月間プラン",
      "selectPlanYearly": "年間プラン"
    },
    "mostPopular": "人気プラン",
    "notifications": {
      "redirecting": "WIXのチェックアウトページにリダイレクトされます...",
      "error": "ご利用プランの更新中にエラーが発生しました。"
    },
    "currentPlan": "現在のプラン",
    "plans": {
      "trial": {
        "title": "お試しプラン",
        "price": "¥0",
        "offer": "お試しプラン期間\n送り状10枚まで",
        "desc": "このプランでUnipostalの機能をぜひお試しください。"
      },
      "starter": {
        "title": "ミニプラン",
        "price": "¥1,000／月",
        "offer": "送り状35枚／月",
        "desc": "1日の発送が1件ぐらいの方に適しています。"
      },
      "professional": {
        "title": "スタンダードプラン",
        "price": "¥2,500／月",
        "offer": "送り状125枚／月",
        "desc": "1日の発送が3～4件の方に適しています。"
      },
      "premier": {
        "title": "ビジネスプラン",
        "price": "¥5,000／月",
        "offer": "送り状350枚／月",
        "desc": "1日の発送が10件以上のビジネスオーナーに適しています。"
      },
      "enterprise": {
        "title": "プレミアムプラン",
        "price": "¥20,000／月",
        "offer": "送り状2,000枚／月",
        "desc": "1日の発送が50件以上の企業や店舗に適しています。"
      }
    }
  },
  "en": {
    "header": {
      "title": "Select Plan"
    },
    "buttons": {
      "selectPlanMonthly": "MONTHLY PLAN",
      "selectPlanYearly": "YEARLY PLAN"
    },
    "mostPopular": "MOST POPULAR",
    "notifications": {
      "redirecting": "Redirecting to WIX checkout page...",
      "error": "An error occurred while updating subscription plan.."
    },
    "currentPlan": "Current plan",
    "plans": {
      "trial": {
        "title": "Trial",
        "price": "FREE",
        "offer": "10 Labels during Trial Period",
        "desc": "Try it out to see how Unipostal streamlines your business."
      },
      "starter": {
        "title": "Starter",
        "price": "¥1000 PER MONTH",
        "offer": "35 Labels Per Month",
        "desc": "Suitable for smaller companies with around 1 order per day."
      },
      "professional": {
        "title": "Professional",
        "price": "¥2500 PER MONTH",
        "offer": "125 Labels Per Month",
        "desc": "Suitable for smaller companies with 3-4 orders per day."
      },
      "premier": {
        "title": "Premier",
        "price": "¥5000 PER MONTH",
        "offer": "350 Labels Per Month",
        "desc": "Suitable for companies shipping 10 or more orders per day."
      },
      "enterprise": {
        "title": "Enterprise",
        "price": "¥20,000 PER MONTH",
        "offer": "2000 Labels Per Month",
        "desc": "Suitable for enterprises shipping 50 or more orders per day."
      }
    }
  }
}
