<template>
  <LayoutWithSettingsMenu>
    <PageHeader :title="$t('header.title')" />
    <div v-if="subscriptionFetched" class="plans">
      <div v-if="subscription && subscription.name === 'TRIAL'" class="plan">
        <h4>{{ $t("plans.trial.title") }}</h4>
        <span class="pill">{{ $t("plans.trial.price") }}</span>
        <span class="offer">{{ $t("plans.trial.offer") }}</span>
        <div class="bottom">
          <p>{{ $t("plans.trial.desc") }}</p>
          <div class="controls">
            <span class="currentPlan">{{ $t("currentPlan") }}</span>
          </div>
        </div>
      </div>
      <div class="plan">
        <h4>{{ $t("plans.starter.title") }}</h4>
        <span class="pill">{{ $t("plans.starter.price") }}</span>
        <span class="offer">{{ $t("plans.starter.offer") }}</span>
        <div class="bottom">
          <p>{{ $t("plans.starter.desc") }}</p>
          <div class="controls">
            <Button
              v-if="!subscription || subscription.name !== 'STARTER'"
              size="big"
              :disabled="btnDisabled"
              @click="selectMonthlyPlan('STARTER')"
            >
              {{ $t("buttons.selectPlanMonthly") }}
            </Button>
            <Button
              v-if="!subscription || subscription.name !== 'STARTER'"
              size="big"
              :disabled="btnDisabled"
              @click="selectYearlyPlan('STARTER')"
            >
              {{ $t("buttons.selectPlanYearly") }}
            </Button>
            <span v-else class="currentPlan">{{ $t("currentPlan") }}</span>
          </div>
        </div>
      </div>
      <div class="plan">
        <h4>{{ $t("plans.professional.title") }}</h4>
        <span class="pill">{{ $t("plans.professional.price") }}</span>
        <span class="offer">{{ $t("plans.professional.offer") }}</span>
        <div class="bottom">
          <p>{{ $t("plans.professional.desc") }}</p>
          <div class="controls">
            <Button
              v-if="!subscription || subscription.name !== 'PROFESSIONAL'"
              size="big"
              :disabled="btnDisabled"
              @click="selectMonthlyPlan('PROFESSIONAL')"
            >
              {{ $t("buttons.selectPlanMonthly") }}
            </Button>
            <Button
              v-if="!subscription || subscription.name !== 'PROFESSIONAL'"
              size="big"
              :disabled="btnDisabled"
              @click="selectYearlyPlan('PROFESSIONAL')"
            >
              {{ $t("buttons.selectPlanYearly") }}
            </Button>
            <span v-else class="currentPlan">{{ $t("currentPlan") }}</span>
          </div>
        </div>
        <span class="mostPopular">{{ $t("mostPopular") }}</span>
      </div>

      <div class="plan">
        <h4>{{ $t("plans.premier.title") }}</h4>
        <span class="pill">{{ $t("plans.premier.price") }}</span>
        <span class="offer">{{ $t("plans.premier.offer") }}</span>
        <div class="bottom">
          <p>{{ $t("plans.premier.desc") }}</p>
          <div class="controls">
            <Button
              v-if="!subscription || subscription.name !== 'PREMIER'"
              size="big"
              :disabled="btnDisabled"
              @click="selectMonthlyPlan('PREMIER')"
            >
              {{ $t("buttons.selectPlanMonthly") }}
            </Button>
            <Button
              v-if="!subscription || subscription.name !== 'PREMIER'"
              size="big"
              :disabled="btnDisabled"
              @click="selectYearlyPlan('PREMIER')"
            >
              {{ $t("buttons.selectPlanYearly") }}
            </Button>
            <span v-else class="currentPlan">{{ $t("currentPlan") }}</span>
          </div>
        </div>
      </div>
      <div class="plan">
        <h4>{{ $t("plans.enterprise.title") }}</h4>
        <span class="pill">{{ $t("plans.enterprise.price") }}</span>
        <span class="offer">{{ $t("plans.enterprise.offer") }}</span>
        <div class="bottom">
          <p>{{ $t("plans.enterprise.desc") }}</p>
          <div class="controls">
            <Button
              v-if="!subscription || subscription.name !== 'ENTERPRISE'"
              size="big"
              :disabled="btnDisabled"
              @click="selectMonthlyPlan('ENTERPRISE')"
            >
              {{ $t("buttons.selectPlanMonthly") }}
            </Button>
            <Button
              v-if="!subscription || subscription.name !== 'ENTERPRISE'"
              size="big"
              :disabled="btnDisabled"
              @click="selectYearlyPlan('ENTERPRISE')"
            >
              {{ $t("buttons.selectPlanYearly") }}
            </Button>
            <span v-else class="currentPlan">{{ $t("currentPlan") }}</span>
          </div>
        </div>
      </div>
    </div>
  </LayoutWithSettingsMenu>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { submitStatuses } from "../../../stores/consts";
import notificationOnSubmit from "../../../lib/notification-on-submit";
import LayoutWithSettingsMenu from "../../layouts/WithSettingsMenu";
import PageHeader from "../../PageHeader";
import Button from "../../inputs/Button";

export default {
  components: {
    LayoutWithSettingsMenu,
    PageHeader,
    Button,
  },

  data() {
    return {
      redirectingToWix: false,
    };
  },

  computed: {
    ...mapGetters({
      subscriptionFetched: "subscriptions/fetched",
      subscription: "subscriptions/active",
      submitStatus: "subscriptions/submitStatus",
      checkoutUrl: "subscriptions/checkoutUrl",
    }),

    btnDisabled() {
      return this.submitStatus === submitStatuses.submitting || this.redirectingToWix;
    },
  },

  watch: {
    submitStatus(newValue) {
      if (newValue === submitStatuses.submitted) {
        this.redirectingToWix = true;
        setTimeout(() => (window.location.href = this.checkoutUrl), 2000);
      }
    },
  },

  methods: {
    ...mapActions({
      updateSubscription: "subscriptions/update",
    }),

    selectMonthlyPlan(planName) {
      this.selectPlan(planName, "MONTHLY");
    },

    selectYearlyPlan(planName) {
      this.selectPlan(planName, "YEARLY");
    },

    selectPlan(planName, billingCycle) {
      notificationOnSubmit(this, {
        statusWatcher: "subscriptions/submitStatus",
        onSuccessMessage: this.$t("notifications.redirecting"),
        onErrorMessage: this.$t("notifications.error"),
      });
      this.updateSubscription({ planName, billingCycle });
    },
  },
};
</script>

<i18n src="./locale.json"></i18n>

<style lang="scss" scoped>
@import "../../App/css/variables";

.plans {
  display: flex;
  flex-wrap: wrap;
  height: 430px;
  margin-right: -30px;
}

.plan {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(33% - 20px);
  margin-right: 20px;
  margin-bottom: 50px;
  padding: 20px 30px;
  border: 2px solid $lines;
  border-radius: 8px;
  white-space: pre-line;
  background-color: $white;

  &:first-child {
    margin-left: 0;
  }

  h4 {
    margin-bottom: 50px;
    font-size: 24px;
  }

  .pill {
    display: flex;
    align-content: center;
    align-items: center;
    height: 30px;
    padding: 0 15px;
    border-radius: 6px;
    color: $blue-4-curious-blue;
    font-weight: bold;
    font-size: 14px;
    background-color: $blue-2-linkwater;
  }

  .offer {
    min-height: 60px;
    margin-top: 15px;
    font-size: 18px;
  }

  .bottom {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
  }

  p {
    margin-top: auto;
    margin-bottom: 20px;
  }

  .currentPlan {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    color: $green;
  }
}

.mostPopular {
  position: absolute;
  right: 0;
  bottom: -25px;
  left: 0;
  display: flex;
  align-items: center;
  color: $blue-4-curious-blue;
  font-weight: bold;
  font-size: 12px;

  &::before {
    flex-grow: 1;
    height: 1px;
    margin-right: 20px;
    background-color: $blue-4-curious-blue;
    content: "";
  }

  &::after {
    flex-grow: 1;
    height: 1px;
    margin-left: 20px;
    background-color: $blue-4-curious-blue;
    content: "";
  }
}

.controls {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90px;
}
</style>
