<template>
  <div class="inputDateWrapper">
    <span v-if="label" class="label" :class="{ invalid }">{{ label }}</span>
    <input type="date" :class="{ invalid }" :value="computedValue" @input="(e) => customEmit(e.target.value)" />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: "",
    },
    invalid: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    computedValue() {
      return this.modelValue || this.value;
    },
  },
  methods: {
    customEmit(value) {
      this.$emit("change", value);
      this.$emit("update:modelValue", value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../App/css/variables";

.inputDateWrapper {
  position: relative;
  height: 46px;
}

.label {
  position: absolute;
  top: -5px;
  left: 17px;
  z-index: 1;
  color: $secondary;
  font-size: 10px;

  &::after {
    position: absolute;
    top: 4px;
    z-index: -1;
    display: block;
    width: calc(100% + 10px);
    height: 5px;
    margin-left: -5px;
    background-color: $white;
    content: "";
  }

  &.invalid {
    color: $danger;
  }
}

input {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 16px;
  border: 2px solid $lines;
  border-radius: 8px;
  color: $primary;
  font-size: 14px;
  background: $white no-repeat;
  background-position: right 25px bottom 50%;
  background-size: 20px;

  &::placeholder {
    color: $secondary;
  }

  &::before {
    position: absolute;
    content: attr(data-label);
  }

  &.invalid {
    border-color: $danger;
    color: $danger;

    &::placeholder {
      color: $danger;
    }
  }
}
</style>
