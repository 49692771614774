<template>
  <LayoutWithSettingsMenu>
    <PageHeader :title="$t('header.title')">
      <template #rightSection>
        <span class="headerInfo">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <p v-html="$t('header.info')" />
          <br />
          <router-link to="/japanpost-guide">{{ $t("header.guide") }}</router-link>
        </span>
      </template>
    </PageHeader>

    <JPPostShippingCarrier v-if="shippingCarriersFetched" />
  </LayoutWithSettingsMenu>
</template>

<script>
import { mapGetters } from "vuex";
import LayoutWithSettingsMenu from "../../layouts/WithSettingsMenu";
import PageHeader from "../../PageHeader";
import JPPostShippingCarrier from "./JPPostShippingCarrier";

export default {
  components: {
    LayoutWithSettingsMenu,
    PageHeader,
    JPPostShippingCarrier,
  },

  computed: {
    ...mapGetters({
      shippingCarriersFetched: "shippingCarriers/fetched",
    }),
  },
};
</script>

<i18n src="./locale.json"></i18n>

<style lang="scss" scoped>
@import "../../App/css/variables";

a {
  color: $inputs;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.headerInfo {
  max-width: 416px;
  font-size: 14px;
  text-align: right;
}
</style>
