const state = {
  opened: false,
  result: null,
  title: "",
  text: "",
  yesText: "",
  noText: "",
};

const getters = {
  opened: ({ opened }) => opened,
  result: ({ result }) => result,
  content: ({ title, text, yesText, noText }) => ({ title, text, yesText, noText }),
};

const actions = {
  open({ commit }, { title, text = "", yesText = "", noText = "" }) {
    commit("OPEN", { title, text, yesText, noText });
  },

  close({ commit }, result) {
    commit("CLOSE", result);
  },
};

const mutations = {
  OPEN(state, { title, text = "", yesText = "", noText = "" }) {
    state.opened = true;
    state.title = title;
    state.text = text;
    state.yesText = yesText;
    state.noText = noText;
    state.result = null;
  },
  CLOSE(state, result) {
    state.opened = false;
    state.result = result;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
