<template>
  <div class="wrapper">
    <div v-for="i in count" :key="i" :class="{ active: i - 1 === value }" @click="$emit('change', i - 1)" />
  </div>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../App/css/variables";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    width: 12px;
    height: 12px;
    margin-left: 10px;
    border-radius: 100px;
    background-color: $lines;
    cursor: pointer;

    &:first-child {
      margin-left: 0;
    }

    &.active {
      background-color: $secondary;
    }
  }
}
</style>
