{
  "jp": {
    "header": {
      "title": "注文",
      "addLabel": "送り状新規作成",
      "archiveOrder": "注文をアーカイブ",
      "unarchiveOrder": "アーカイブを解除"
    },
    "notifications": {
      "archivedSuccess": "注文がアーカイブされました。",
      "archivedError": "エラーが発生しました。",
      "unarchivedSuccess": "アーカイブが解除されました。",
      "unarchivedError": "エラーが発生しました。"
    },
    "manuallyEdited": "手動で編集された注文",
    "manuallyEditedInfo": "この注文はWixと同期されません。",
    "archiveConfirmation": "この注文をアーカイブしますか？",
    "unarchiveConfirmation": "アーカイブした注文を解除しますか？"
  },
  "en": {
    "header": {
      "title": "Order",
      "addLabel": "Create new label",
      "archiveOrder": "Archive Order",
      "unarchiveOrder": "Unarchive Order"
    },
    "notifications": {
      "archivedSuccess": "The order has been successfully archived!",
      "archivedError": "An error occurred while archiving the order..",
      "unarchivedSuccess": "The order has been successfully unarchived!",
      "unarchivedError": "An error occurred while unarchiving the order.."
    },
    "manuallyEdited": "MANUALLY EDITED",
    "manuallyEditedInfo": "THIS ORDER <b>WON'T</b> BE SYNCED WITH WIX.",
    "archiveConfirmation": "Are you sure you want to archive this order?",
    "unarchiveConfirmation": "Are you sure you want to unarchive this order?"
  }
}
