{
  "jp": {
    "text422": "お客様のリクエストの処理中にエラーが発生しました。",
    "text500": "エラーが発生しました。",
    "textERR001": "あなたのWIXサイトにはWIXストアがインストールされていないため、Unipostalのアカウントを作成することができません。以下のボタンをクリックして、WIX Storesのページに移動してください。",
    "buttons": {
      "goToTopPage": "トップページに戻る",
      "goToWixStore": "WIX Storesページへ"
    }
  },
  "en": {
    "text422": "There was an error while processing your request.",
    "text500": "Oops, looks like something went wrong.",
    "textERR001": "We can't create Unipostal account because your WIX site doesn't have any WIX Stores installed.. Please navigate to WIX Stores page by clicking the button below.",
    "buttons": {
      "goToTopPage": "Back to Top Page",
      "goToWixStore": "Go to WIX Stores Page"
    }
  }
}
