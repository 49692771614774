import { camelizeKeys } from "humps";
import axiosOpenApiWrapper from "../../lib/axios-open-api-wrapper";

export default async (csrfToken, sessionToken, labelId) => {
  const { status, data } = await axiosOpenApiWrapper({
    method: "post",
    url: `/api/labels/${labelId}/generate_label_file`,
    openApiUrl: "/api/labels/{labelId}/generate_label_file",
    headers: {
      "X-CSRF-TOKEN": csrfToken,
      Authorization: `Bearer ${sessionToken}`,
    },
    validateStatus: () => true,
  });

  const payload = camelizeKeys(data.payload);
  const error = `${status}` !== "200";
  const label = payload?.label;
  const order = payload?.order;
  return { error, label, order };
};
