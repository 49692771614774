import { submitStatuses } from "../stores/consts";

export default (componentThis, { statusWatcher, onSuccessMessage, onErrorMessage, duration = 3000 } = {}) => {
  const store = componentThis.$store;

  const unwatch = store.watch(
    (_state, getters) => getters[statusWatcher],
    (submitStatus) => {
      if (submitStatus === submitStatuses.submitted) {
        store.dispatch("notifications/queue", { message: onSuccessMessage, duration });
        unwatch();
      }

      if (submitStatus === submitStatuses.errored) {
        store.dispatch("notifications/queue", { message: onErrorMessage, type: "danger", duration });
        unwatch();
      }
    }
  );
};
