{
  "jp": {
    "buttons": {
      "remove": "削除",
      "edit": "編集",
      "noEditAfterGenerationExplanation": "送り状が一度発行されると、送り状の内容を変更することはできません。",
      "print": "印刷"
    },
    "fields": {
      "person": "お客様氏名",
      "orderNr": "注文番号",
      "parcelInformation": "個数",
      "itemName": "商品名",
      "deliveryDate": "配送日",
      "fromAddress": "ご依頼主の住所",
      "storeInformation": "ご依頼主情報",
      "address": "住所",
      "toAddress": "お届け先住所",
      "recipientInformation": "お届け先情報",
      "shippingCarrier": "配送会社",
      "phone": "電話 {value}",
      "zipcode": "〒{value}",
      "shippingStatus": "出荷状況",
      "trackingNumber": "追跡番号",
      "date": "日付",
      "japanPost": "日本郵便"
    },
    "notifications": {
      "removedSuccess": "送り状が削除されました。",
      "removedError": "送り状の削除中にエラーが発生しました。"
    },
    "shippingStatuses": {
      "Pending": "保留中",
      "InfoReceived": "集荷",
      "InTransit": "輸送中",
      "OutForDelivery": "配達中",
      "AttemptFail": "不在",
      "Delivered": "配達完了",
      "AvailableForPickup": "窓口受取可能",
      "Exception": "例外",
      "Expired": "期限切れ"
    },
    "shippingStatusMessages": {
      "Delivered": "配達完了",
      "Picked up by customer": "お客様がお荷物を受け取りました。",
      "Signed by customer": "お客様はお荷物の受け取りにサインしました。",
      "Delivered and cash collected": "代金引き換えでの受け取りが完了しました。",
      "Available for pickup": "お近くの受け取り場所に届きました。お客様はそこで受け取ることも可能です。",
      "Exception": "例外",
      "Customer relocated": "受取人の住所が変わったため、配達出来ませんでした。",
      "Customer refused delivery": "受取人が受け取りを拒否したため、配達できませんでした。",
      "Delayed (Customs clearance)": "通関手続き等により遅延。",
      "Delayed (External factors)": "何らかの理由により遅延。",
      "Held for payment": "お客様による支払いが確認されていないため、配送が保留されています。",
      "Incorrect address": "誤った受取人住所のため、配達できません。",
      "Pickup missed": "お客様がまだ受け取り場所から受け取っていません。",
      "Rejected by carrier": "ガイドラインに準拠していないという理由で運送会社が荷物を拒否している",
      "Returning to sender": "出荷元に返送中",
      "Returned to sender": "出荷元に返送済み",
      "Shipment damaged": "荷物破損",
      "Shipment lost": "荷物紛失により配達できませんでした。",
      "Failed attempt": "何らかの理由によりお届けできませんでした。通常、お客様に通知を残し再配送することになります。",
      "Recipient unavailable": "指定された送り先住所に受取人はいません。",
      "Business closed": "配達時に業務が終了しています。",
      "In transit": "配達中",
      "Acceptance scan": "運送会社が荷物を受け取りました。",
      "Arrival scan": "お荷物が中継場所か物流センターに届きました。",
      "Arrived at the destination country": "国際配送便がお届け先の国に届きました。",
      "Customs clearance completed": "税関通過",
      "Customs clearance started": "お荷物は通関のために税関に引き渡されました。",
      "Departure scan": "お荷物が出荷されました。",
      "Problem resolved": "問題は解決され、お荷物は配送中です。",
      "Forwarded to a new address": "別の住所にお荷物を転送中です。",
      "Info received": "運送会社は配送情報を受け取り、荷物を受け取ることが出来ます。",
      "Out for delivery": "お荷物はすでに出荷されました。",
      "Customer contacted": "配達前にお客様は連絡を受けています。",
      "Delivery appointment scheduled": "配達時間は指定済みです。",
      "Pending": "保留中",
      "Expired": "運送会社が30日間追跡情報を通知しな かったため、配達期限が過ぎました。"
    },
    "title": "送り状のプレビュー",
    "subTitle": "出荷概要",
    "removeLabelConfirmation": "この送り状を削除しますか？"
  },
  "en": {
    "buttons": {
      "remove": "Remove",
      "edit": "Edit label",
      "noEditAfterGenerationExplanation": "You can't edit a label after it has been generated",
      "print": "Print label"
    },
    "fields": {
      "person": "PERSON",
      "orderNr": "ORDER NUMBER",
      "parcelInformation": "PARCEL INFORMATION",
      "itemName": "ITEM NAME",
      "deliveryDate": "DELIVERY DATE",
      "fromAddress": "FROM ADDRESS",
      "storeInformation": "STORE INFORMATION",
      "address": "ADDRESS",
      "toAddress": "TO ADDRESS",
      "recipientInformation": "RECIPIENT INFORMATION",
      "shippingCarrier": "SHIPPING CARRIER",
      "phone": "tel. {value}",
      "zipcode": "{value}",
      "shippingStatus": "SHIPPING STATUS",
      "shippingStatusMessage": "DETAILS",
      "trackingNumber": "TRACKING NUMBER",
      "date": "DATE",
      "japanPost": "Japan Post"
    },
    "notifications": {
      "removedSuccess": "The label has been successfully removed!",
      "removedError": "An error occurred while removing the label.."
    },
    "shippingStatuses": {
      "Pending": "Pending",
      "InfoReceived": "Information Received",
      "InTransit": "In Transit",
      "OutForDelivery": "Out For Delivery",
      "AttemptFail": "Failed Attempt",
      "Delivered": "Delivered",
      "AvailableForPickup": "Available For Pickup",
      "Exception": "Exception",
      "Expired": "Expired"
    },
    "shippingStatusMessages": {
      "Delivered": "Delivered",
      "Picked up by customer": "Picked up by customer",
      "Signed by customer": "Signed by customer",
      "Delivered and cash collected": "Delivered and cash collected",
      "Available for pickup": "Available for pickup",
      "Exception": "Exception",
      "Customer relocated": "Customer relocated",
      "Customer refused delivery": "Customer refused delivery",
      "Delayed (Customs clearance)": "Delayed (Customs clearance)",
      "Delayed (External factors)": "Delayed (External factors)",
      "Held for payment": "Held for payment",
      "Incorrect address": "Incorrect address",
      "Pickup missed": "Pickup missed",
      "Rejected by carrier": "Rejected by carrier",
      "Returning to sender": "Returning to sender",
      "Returned to sender": "Returned to sender",
      "Shipment damaged": "Shipment damaged",
      "Shipment lost": "Shipment lost",
      "Failed attempt": "Failed attempt",
      "Recipient unavailable": "Recipient unavailable",
      "Business closed": "Business closed",
      "In transit": "In transit",
      "Acceptance scan": "Acceptance scan",
      "Arrival scan": "Arrival scan",
      "Arrived at the destination country": "Arrived at the destination country",
      "Customs clearance completed": "Customs clearance completed",
      "Customs clearance started": "Customs clearance started",
      "Departure scan": "Departure scan",
      "Problem resolved": "Problem resolved",
      "Forwarded to a new address": "Forwarded to a new address",
      "Info received": "Info received",
      "Out for delivery": "Out for delivery",
      "Customer contacted": "Customer contacted",
      "Delivery appointment scheduled": "Delivery appointment scheduled",
      "Pending": "Pending",
      "Expired": "Expired"
    },
    "title": "LABEL PREVIEW",
    "subTitle": "Shipping Overview",
    "removeLabelConfirmation": "Are you sure you want to remove this label?"
  }
}
