<template>
  <LayoutWithSettingsMenu>
    <PageHeader :title="$t('header.title')" />

    <div class="twoColumns">
      <div>{{ $t("header.personalInformation") }}</div>
      <div>
        <InputText
          v-model="v$.value.fullName.$model"
          :invalid="v$.value.fullName.$error"
          class="input"
          :label="$t('fields.fullNameLabel')"
          :placeholder="$t('fields.fullNamePlaceholder')"
        />
        <InputText
          v-model="v$.value.email.$model"
          :invalid="v$.value.email.$error"
          class="input"
          :label="$t('fields.emailLabel')"
          :placeholder="$t('fields.emailPlaceholder')"
        />
        <div class="actions">
          <Button class="btn" :disabled="btnDisabled" size="small" @click="save">{{ btnText }}</Button>
        </div>
      </div>
    </div>
  </LayoutWithSettingsMenu>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { submitStatuses } from "../../../stores/consts";
import notificationOnSubmit from "../../../lib/notification-on-submit";
import LayoutWithSettingsMenu from "../../layouts/WithSettingsMenu";
import PageHeader from "../../PageHeader";
import InputText from "../../inputs/InputText";
import Button from "../../inputs/Button";

export default {
  components: {
    LayoutWithSettingsMenu,
    PageHeader,
    InputText,
    Button,
  },

  data() {
    return {
      v$: useVuelidate(),
      value: {
        fullName: "",
        email: "",
      },
    };
  },

  validations() {
    return {
      value: {
        fullName: { required },
        email: { required, email },
      },
    };
  },

  computed: {
    ...mapGetters({
      submitStatus: "user/submitStatus",
      userProfile: "user/profile",
    }),

    btnText() {
      if (this.submitStatus === submitStatuses.errored) return this.$t("buttons.errored");
      if (this.submitStatus === submitStatuses.submitting) return this.$t("buttons.saving");
      return this.$t("buttons.save");
    },

    btnDisabled() {
      return this.v$.value.$error;
    },
  },

  watch: {
    userProfile: {
      immediate: true,
      handler(userProfile) {
        this.value = {
          ...this.value,
          ...userProfile,
        };
      },
    },
  },

  methods: {
    ...mapActions({
      saveUser: "user/save",
    }),

    async save() {
      if (!(await this.v$.$validate())) return;
      if (this.submitStatus === submitStatuses.submitting) return;

      notificationOnSubmit(this, {
        statusWatcher: "user/submitStatus",
        onSuccessMessage: this.$t("notifications.success"),
        onErrorMessage: this.$t("notifications.error"),
      });
      this.saveUser(this.value);
    },
  },
};
</script>

<i18n src="./locale.json"></i18n>

<style lang="scss" scoped>
@import "../../App/css/variables";

.twoColumns {
  display: flex;

  div:first-child {
    min-width: 200px;
  }

  div:last-child {
    flex-grow: 1;
  }
}

.input {
  margin-bottom: 30px;
}

.actions {
  display: flex;
  justify-content: flex-end;
}
</style>
