export const loginStatuses = {
  bootingUp: "bootingUp",
  loggingIn: "loggingIn",
  loggedIn: "loggedIn",
  notLoggedIn: "notLoggedIn",
  errored: "errored",
};

export const fetchStatuses = {
  idle: "idle",
  fetching: "fetching",
  fetched: "fetched",
  errored: "errored",
};

export const submitStatuses = {
  idle: "idle",
  submitting: "submitting",
  submitted: "submitted",
  errored: "errored",
};

export const orderSearchFilters = {
  allActive: "allActive",
  archived: "archived",
  fulfilled: "fulfilled",
  unfulfilled: "unfulfilled",
};

export const orderSearchSorting = {
  newestToOldest: "newestToOldest",
  oldestToNewest: "oldestToNewest",
  highestValueToLowest: "highestValueToLowest",
  lowestValueToHighest: "lowestValueToHighest",
};
