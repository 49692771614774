const state = {
  csrfToken: null,
};

const getters = {
  csrfToken: ({ csrfToken }) => csrfToken,
};

const actions = {
  setCsrfToken({ commit }, csrfToken) {
    commit("SET_CSRF_TOKEN", csrfToken);
  },
};

const mutations = {
  SET_CSRF_TOKEN(state, csrfToken) {
    state.csrfToken = csrfToken;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
