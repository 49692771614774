<template>
  <LayoutWithSettingsMenu>
    <PageHeader
      :title="$t('header.title')"
      :btnTitle="$t('header.addShippingPreset')"
      @btnClick="showCreateShippingPresetModal = true"
    />

    <AnimationList>
      <ShippingPreset
        v-for="shippingPreset in shippingPresets"
        :key="shippingPreset.id"
        :shippingPreset="shippingPreset"
        class="animationList-item"
      />
    </AnimationList>

    <AnimationFade>
      <ShippingPresetModal
        v-if="showCreateShippingPresetModal"
        :shippingPreset="{}"
        @close="showCreateShippingPresetModal = false"
      />
    </AnimationFade>
  </LayoutWithSettingsMenu>
</template>

<script>
import { mapGetters } from "vuex";
import LayoutWithSettingsMenu from "../../layouts/WithSettingsMenu";
import AnimationFade from "../../animations/AnimationFade";
import AnimationList from "../../animations/AnimationList";
import ShippingPresetModal from "../../modals/ShippingPreset";
import PageHeader from "../../PageHeader";
import ShippingPreset from "./ShippingPreset";

export default {
  components: {
    LayoutWithSettingsMenu,
    AnimationFade,
    AnimationList,
    ShippingPresetModal,
    PageHeader,
    ShippingPreset,
  },

  data() {
    return {
      showCreateShippingPresetModal: false,
    };
  },

  computed: {
    ...mapGetters({
      shippingPresets: "shippingPresets/all",
    }),
  },
};
</script>

<i18n src="./locale.json"></i18n>

<style lang="scss" scoped>
@import "../../App/css/variables";
</style>
