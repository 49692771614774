{
  "jp": {
    "banner": "設定ページでお客様情報を入力してください。",
    "link": "こちらをクリック"
  },
  "en": {
    "banner": "Please fill in your information in the Settings page.",
    "link": "Click here"
  }
}
