{
  "jp": {
    "orders": "注文",
    "settings": "設定",
    "logout": "ログアウト",
    "notifications": {
      "logoutSuccess": "ログアウトしました。"
    }
  },
  "en": {
    "orders": "Orders",
    "settings": "Settings",
    "logout": "Logout",
    "notifications": {
      "logoutSuccess": "You have been successfully logged out!"
    }
  }
}
