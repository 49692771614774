<template>
  <button :disabled="disabled" :class="['btnWrapper', `type-${type}`, `size-${size}`, { disabled, warning }]">
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "filled",
    },
    size: {
      type: String,
      default: "auto",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    warning: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
/* stylelint-disable selector-class-pattern */
@import "../../App/css/variables";

.btnWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  border-radius: 8px;
  font-size: 12px;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }
}

.size-auto {
  height: auto;
}

.size-big {
  min-height: 40px;
}

.size-small {
  min-height: 33px;
}

.type-filled {
  border: solid 2px $inputs;
  color: $white;
  background-color: $inputs;

  &:hover {
    border-color: $tertiary;
    background-color: $tertiary;
  }

  &:active {
    border-color: $secondary;
    background-color: $secondary;
  }

  &.disabled {
    border-color: $secondary !important;
    background-color: $secondary !important;
  }

  &.warning {
    border-color: $warning;
    background-color: $warning;

    &:hover,
    &:active {
      opacity: 0.9;
    }
  }
}

.type-inverted {
  border: solid 2px $inputs;
  color: $inputs;
  background-color: $white;

  &:hover {
    border-color: $tertiary;
    color: $white;
    background-color: $tertiary;
  }

  &:active {
    border-color: $secondary;
    color: $white;
    background-color: $secondary;
  }

  &.disabled {
    border-color: $secondary !important;
    color: $secondary !important;
    background-color: $white !important;
  }

  &.warning {
    border-color: $warning;
    color: $warning;

    &:hover,
    &:active {
      color: $white;
      background-color: $warning;
    }
  }
}

.type-noBorders {
  border: solid 2px transparent;
  color: $inputs;
  background-color: $white;

  &:hover {
    color: $tertiary;
  }

  &:active,
  &.disabled {
    color: $secondary !important;
  }

  &.warning {
    color: $warning;

    &:hover,
    &:active {
      opacity: 0.8;
    }
  }
}
</style>
