<template>
  <div class="languageSelect">
    <div class="lang currentLang" :class="locale" :title="$t('selectLanguage')" />

    <div class="menuWrapper">
      <div class="menu">
        <div class="lang jp" :title="$t('jp')" @click="() => setLocale('jp')" />
        <div class="lang en" :title="$t('en')" @click="() => setLocale('en')" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      locale: "user/locale",
    }),
  },

  methods: {
    ...mapActions({
      setLocale: "user/setLocale",
    }),
  },
};
</script>

<i18n src="./locale.json"></i18n>

<style lang="scss" scoped>
@import "../../App/css/variables";

.languageSelect {
  position: relative;
  z-index: 100;

  &:hover {
    .currentLang {
      filter: saturate(100%);
    }

    .menuWrapper {
      width: 155px;
    }
  }
}

.currentLang {
  position: relative;
  z-index: 1;
  filter: saturate(0%);
  transition: filter 0.8s;
}

.menuWrapper {
  position: absolute;
  top: 0;
  left: 15px;
  display: flex;
  justify-content: flex-end;
  width: 0;
  overflow: hidden;
  transition: width 0.3s;

  .lang {
    margin-right: 15px;
  }
}

.menu {
  display: flex;
  justify-content: flex-end;
}

.lang {
  width: 32px;
  height: 32px;
  cursor: pointer;

  &.jp {
    background: url("../../../assets/images/icons/lang-jp.svg");
  }

  &.en {
    background: url("../../../assets/images/icons/lang-en.svg");
  }
}
</style>
