import RouteWrapperScreen from "../screens/RouteWrapperScreen";
import ErrorScreen from "../screens/Error";
import WelcomeScreen from "../screens/Welcome";
import SigninScreen from "../screens/Signin";
import SubscriptionScreen from "../screens/Subscription";
import SubscriptionSelectPlanScreen from "../screens/SubscriptionSelectPlan";
import SubscriptionSuccesfullyPurchasedScreen from "../screens/SubscriptionSuccesfullyPurchased";
import ShippingPresetsScreen from "../screens/ShippingPresets";
import ShippingCarriersScreen from "../screens/ShippingCarriers";
import ProfileScreen from "../screens/Profile";
import OrderDetailsScreen from "../screens/OrderDetails";
import JapanPostGuideScreen from "../screens/JapanPostGuide";

export default [
  { path: "/", redirect: "/orders" },
  { path: "/error", component: ErrorScreen },
  { path: "/signin", component: SigninScreen },
  { path: "/japanpost-guide", component: JapanPostGuideScreen },
  {
    path: "/settings",
    meta: { requiresAuth: true },
    component: RouteWrapperScreen,
    children: [
      { path: "", redirect: "/settings/profile" },
      { path: "shipping-presets", component: ShippingPresetsScreen },
      { path: "shipping-carriers", component: ShippingCarriersScreen },
      { path: "profile", component: ProfileScreen },
      {
        path: "subscription",
        component: RouteWrapperScreen,
        children: [
          { path: "", component: SubscriptionScreen },
          { path: "select-plan", component: SubscriptionSelectPlanScreen },
          { path: "succesfully-purchased", component: SubscriptionSuccesfullyPurchasedScreen },
        ],
      },
    ],
  },
  {
    path: "/orders",
    meta: { requiresAuth: true },
    component: RouteWrapperScreen,
    children: [
      { path: "", component: WelcomeScreen },
      { path: ":id", component: OrderDetailsScreen },
    ],
  },
];
