<template>
  <div v-if="show" class="incompleteSettingsInfoBar">
    {{ $t("banner") }}&nbsp;
    <router-link v-if="!noLink" to="/settings" class="link">{{ $t("link") }}</router-link>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    noLink: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      subscriptionFetched: "subscriptions/fetched",
      subscription: "subscriptions/active",
      jppostCarrier: "shippingCarriers/jppost",
      jppostCarrierFetched: "shippingCarriers/fetched",
      shippingPresets: "shippingPresets/allValid",
      shippingPresetsFetched: "shippingPresets/fetched",
    }),

    subscriptionWarning() {
      return !this.subscription;
    },

    shippingCarriersWarning() {
      return !this.jppostCarrier?.active;
    },

    shippingPresetsWarning() {
      return this.shippingPresets.every((preset) => !preset.validData);
    },

    show() {
      const loaded = this.subscriptionFetched && this.jppostCarrierFetched && this.shippingPresetsFetched;

      return loaded && (this.subscriptionWarning || this.shippingCarriersWarning || this.shippingPresetsWarning);
    },
  },
};
</script>

<i18n src="./locale.json"></i18n>

<style lang="scss" scoped>
@import "../App/css/variables";

.incompleteSettingsInfoBar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  min-height: 50px;
  color: $white;
  background-color: $blue-7-steel-blue;
}

.link {
  color: $white;
}
</style>
