<template>
  <div class="emptyLabelList">
    <div class="content">
      <p>{{ $t("info") }}</p>
      <img :src="emptyLabelListSvgUrl" />
    </div>
  </div>
</template>

<script>
import emptyLabelListSvgUrl from "../../../../assets/images/empty-label-list.svg";
export default {
  props: {
    delay: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      emptyLabelListSvgUrl,
    };
  },
};
</script>

<i18n src="./locale.json"></i18n>

<style lang="scss" scoped>
@import "../../../App/css/variables";

.emptyLabelList {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: -20px;

  p {
    color: $secondary;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
  }
}
</style>
