<template>
  <LayoutWithSettingsMenu v-if="hasSubscriptionPlan">
    <PageHeader :title="$t('header')">
      <template #rightSection>
        <Button size="big" @click="$router.push({ path: '/settings/subscription/select-plan' })">
          {{ $t("buttons.upgradePlan") }}
        </Button>
      </template>
    </PageHeader>
    <div class="box">
      <div>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <h2 v-html="$t('plan', { name: $t(`subscriptionName.${subscription.name}`) })" />
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-if="subscription.labelBalance === 0" class="warning" v-html="$t('labelBalanceDepleted')" />
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-else-if="unlimited" v-html="$t('labelUnlimited')" />

        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-else v-html="$t('labelBalance', { labelBalance: subscription.labelBalance })" />
      </div>

      <!-- eslint-disable vue/no-v-html -->
      <p
        v-if="trialPlan"
        class="planDesc"
        v-html="
          $t('planDesc', {
            amount: `${subscription.priceCurrency} ${subscription.priceAmount}`,
            date: formattedDate(subscription.nextRefillAt),
          })
        "
      />
      <!-- eslint-enable vue/no-v-html -->
      <div class="footer">
        <Button v-if="trialPlan" class="btn" type="noBorders" @click="showCancelSubscriptionModal = true">
          {{ $t("buttons.cancelSubscription") }}
        </Button>
        <Button
          v-else
          class="btn"
          type="noBorders"
          @click="$router.push({ path: '/settings/subscription/select-plan' })"
        >
          {{ $t("buttons.upgradePlan") }}
        </Button>
      </div>
    </div>
    <InvoiceList />

    <AnimationFade>
      <CancelSubscriptionModal v-if="showCancelSubscriptionModal" @close="showCancelSubscriptionModal = false" />
    </AnimationFade>
  </LayoutWithSettingsMenu>
  <NoPlanSelected v-else />
</template>

<script>
import { mapGetters } from "vuex";
import LayoutWithSettingsMenu from "../../layouts/WithSettingsMenu";
import PageHeader from "../../PageHeader";
import AnimationFade from "../../animations/AnimationFade";
import CancelSubscriptionModal from "../../modals/CancelSubscription";
import Button from "../../inputs/Button";
import NoPlanSelected from "./NoPlanSelected";
import InvoiceList from "./InvoiceList";

export default {
  components: {
    LayoutWithSettingsMenu,
    NoPlanSelected,
    InvoiceList,
    PageHeader,
    Button,
    AnimationFade,
    CancelSubscriptionModal,
  },

  data() {
    return {
      showCancelSubscriptionModal: false,
    };
  },

  computed: {
    ...mapGetters({
      unlimited: "subscriptions/unlimited",
      subscription: "subscriptions/active",
      locale: "user/locale",
    }),

    trialPlan() {
      return this.subscription.priceAmount && this.subscription.nextRefillAt;
    },

    hasSubscriptionPlan() {
      return !!this.subscription;
    },
  },

  methods: {
    formattedDate(dateString) {
      if (this.locale === "en") return dateString;

      const date = new Date(Date.parse(dateString));
      return `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`;
    },
  },
};
</script>

<i18n src="./locale.json"></i18n>

<style lang="scss" scoped>
@import "../../App/css/variables";

.box {
  display: flex;
  flex-direction: column;
  padding: 40px 40px 20px;
  border: 2px solid $lines;
  border-radius: 8px;
  background-color: $white;

  &:deep(b) {
    color: $secondary;
  }

  h2 {
    margin-bottom: 10px;
  }

  .planDesc {
    margin-top: 100px;
  }

  .warning {
    color: $warning;
  }

  .footer {
    display: flex;
    align-items: center;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid $lines;
    font-size: 12px;

    .btn {
      margin: 0 20px 0 -17px;
    }
  }
}
</style>
