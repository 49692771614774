<template>
  <LayoutWithStepsPanel>
    <div class="guideDetails">
      <PageHeader :title="$t('header.title')" />
      <div id="step1" class="steps">
        <h2>{{ $t("step1.title") }}</h2>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p class="description" v-html="$t('step1.description')" />
        <div class="furtherInfo">
          <ul class="bulletPoints">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <li v-html="$t('step1.furtherDescriptionLine1')" />
            <!-- eslint-disable-next-line vue/no-v-html -->
            <li v-html="$t('step1.furtherDescriptionLine2')" />
            <!-- eslint-disable-next-line vue/no-v-html -->
            <li v-html="$t('step1.furtherDescriptionLine3')" />
          </ul>
          <div class="imageContainer">
            <img class="furtherInfoImg" :src="japanpostStep1Url" />
          </div>
        </div>
      </div>
      <div id="step2" class="steps">
        <h2>{{ $t("step2.title") }}</h2>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p class="description" v-html="$t('step2.description')" />
        <div class="furtherInfo">
          <ul class="bulletPoints">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <li v-html="$t('step2.furtherDescriptionLine1')" />
          </ul>
          <div class="imageContainer">
            <img class="furtherInfoImg" :src="japanpostStep2Url" />
          </div>
        </div>
      </div>
      <div id="step3" class="steps">
        <h2>{{ $t("step3.title") }}</h2>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p class="description" v-html="$t('step3.description')" />
        <div class="furtherInfo">
          <ul class="bulletPoints">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <li v-html="$t('step3.furtherDescriptionLine1')" />
          </ul>
          <div class="imageContainer">
            <img class="furtherInfoImg" :src="japanpostStep3Url" />
          </div>
          <div class="warningBox">
            <img class="icon" :src="dangerUrl" />
            <!-- eslint-disable-next-line vue/no-v-html -->
            <p v-html="$t('step3.furtherDescriptionLine2')" />
          </div>
        </div>
        <!-- eslint-disable-next-line vue/no-v-html -->
      </div>
      <div id="step4" class="steps">
        <h2>{{ $t("step4.title") }}</h2>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p class="description" v-html="$t('step4.description')" />
        <div class="furtherInfo">
          <ul class="bulletPoints">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <li v-html="$t('step4.furtherDescriptionLine1')" />
          </ul>
          <div class="imageContainer">
            <img class="furtherInfoImg" :src="japanpostStep4Url" />
          </div>
        </div>
      </div>
    </div>
  </LayoutWithStepsPanel>
</template>

<script>
import LayoutWithStepsPanel from "../../layouts/WithStepsPanel";
import PageHeader from "../../PageHeader";
import japanpostStep1Url from "../../../assets/images/japanpost-step1.png";
import japanpostStep2Url from "../../../assets/images/japanpost-step2.png";
import japanpostStep3Url from "../../../assets/images/japanpost-step3.png";
import japanpostStep4Url from "../../../assets/images/japanpost-step4.png";
import dangerUrl from "../../../assets/images/icons/danger.svg";

export default {
  components: {
    LayoutWithStepsPanel,
    PageHeader,
  },

  data() {
    return {
      japanpostStep1Url,
      japanpostStep2Url,
      japanpostStep3Url,
      japanpostStep4Url,
      dangerUrl,
      scrollPosition: 0,
    };
  },

  mounted() {
    const rightScreen = document.querySelector(".layoutContent");
    rightScreen.addEventListener("scroll", this.selectMenuItem);
    this.selectMenuItem();
  },

  beforeDestroy() {
    const rightScreen = document.querySelector(".layoutContent");
    rightScreen.removeEventListener("scroll", this.selectMenuItem);
  },

  methods: {
    selectMenuItem() {
      const steps = document.querySelectorAll(".step");
      const sections = document.querySelectorAll(".steps");

      const activeStepIndex = [...sections]
        .map((stepEl, stepElIndex) => ({ index: stepElIndex, y: stepEl.getBoundingClientRect().y }))
        .filter((stepEl) => stepEl.y > -20)[0].index;

      steps.forEach((step) => step.classList.remove("activeStep"));
      steps[activeStepIndex]?.classList?.add("activeStep");
    },
  },
};
</script>

<i18n src="./locale.json"></i18n>

<style lang="scss" scoped>
@import "../../App/css/variables";

.pageHeader {
  padding-bottom: 40px;
  font-size: 28px;
}

.steps {
  margin-top: 24px;
}

.guideDetails {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 0 100px 50%;

  :deep(a) {
    color: $inputs;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.bulletPoints {
  padding-left: 24px;
}

.furtherInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: 24px 0;
  padding: 48px 48px 0;
  border-radius: 12px;
  background: #ebeef7;
}

.furtherInfoImg {
  width: 70%;
  padding: 24px 0;
}

.warningBox {
  display: flex;
  width: 90%;
  margin-bottom: 24px;
  padding: 24px;
  border: 1px solid #d85858;
  border-radius: 12px;
  background-color: #ffe4e4;
}

.description {
  margin-top: 24px;
  color: $primary;
}

.icon {
  margin-right: 24px;
}

.activeStep {
  color: white;
  background-color: $primary;
}

.imageContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 24px 0;
  border-radius: 12px;
  background-color: white;
}

h2 {
  color: $primary;
}

li {
  margin-bottom: 12px;
  color: $primary;
}

ul {
  align-self: flex-start;
}
</style>
