{
  "jp": {
    "header": {
      "selectYourAddress": "出荷元情報を選択",
      "shippingInformation": "お届け先情報",
      "createNewPreset": "出荷元新規登録"
    },
    "fields": {
      "name": "出荷元名:",
      "businessName": "会社名・店舗名:",
      "address": "住所:",
      "phoneNumber": "電話番号:",
      "firstNameLabel": "名*",
      "firstNamePlaceholder": "名入れて下さい",
      "lastNameLabel": "姓*",
      "lastNamePlaceholder": "姓入れて下さい",
      "fullNameLabel": "氏名*",
      "fullNamePlaceholder": "氏名入れて下さい",
      "emailLabel": "メールアドレス",
      "emailPlaceholder": "メールアドレスを入力してください",
      "phoneLabel": "電話番号*",
      "phonePlaceholder": "電話番号入れて下さい",
      "countryLabel": "国*",
      "countryPlaceholder": "国入れて下さい",
      "companyLabel": "会社名",
      "companyPlaceholder": "会社名を入力してください",
      "zipCodeLabel": "郵便番号* (数字のみ)",
      "zipCodePlaceholder": "郵便番号入れて下さい",
      "addressLabel": "住所*",
      "addressPlaceholder": "住所入れて下さい",
      "shippingCarrierLabel": "配送会社*",
      "shippingCarrierPlaceholder": "配送会社を選んで下さい",
      "numberOfParcelsLabel": "個数*",
      "numberOfParcelsPlaceholder": "個数入れて下さい",
      "weightLabel": "重量 (kg)",
      "weightPlaceholder": "重量を入力してください",
      "deliveryDateLabel": "配送日*",
      "itemNameLabel": "商品名* (最大100文字)",
      "itemNamePlaceholder": "商品名入れて下さい"
    },
    "buttons": {
      "select": "この情報を選択する",
      "errored": "エラー",
      "saving": "保存中",
      "complete": "完了",
      "next": "次へ",
      "back": "戻る"
    },
    "notifications": {
      "createdSuccess": "送り状が発行されました。",
      "createdError": "送り状の作成中にエラーが発生しました。",
      "updatedSuccess": "送り状が更新されました。",
      "updatedError": "送り状の更新中にエラーが発生しました。"
    },
    "shippingPresetRemovedInvalid": "この送り状には削除された、または無効な情報があります。",
    "shippingPresetRemovedInvalidLink": "設定画面の「出荷元情報」ページで確認し、変更・更新してください。",
    "shippingCarrierDeactivated": "この送り状の配送会社は有効ではありません。",
    "invalid": "出荷元情報に無効な情報があります。",
    "changesWillBeLostConfirm": "保存されていない変更は失われます。このまま進みますか？"
  },
  "en": {
    "header": {
      "selectYourAddress": "Select Your Address",
      "shippingInformation": "Shipping Information",
      "createNewPreset": "CREATE NEW WAREHOUSE"
    },
    "fields": {
      "name": "Name:",
      "businessName": "Business name:",
      "address": "Address:",
      "phoneNumber": "Phone number:",
      "firstNameLabel": "FIRST NAME*",
      "firstNamePlaceholder": "Enter first name",
      "lastNameLabel": "LAST NAME*",
      "lastNamePlaceholder": "Enter last name",
      "emailLabel": "EMAIL",
      "emailPlaceholder": "Enter email",
      "phoneLabel": "PHONE NUMBER*",
      "phonePlaceholder": "Enter phone number",
      "countryLabel": "COUNTRY*",
      "countryPlaceholder": "Enter country",
      "companyLabel": "COMPANY NAME",
      "companyPlaceholder": "Enter company name",
      "zipCodeLabel": "POSTAL CODE* (only digits)",
      "zipCodePlaceholder": "Enter postal code",
      "addressLabel": "ADDRESS*",
      "addressPlaceholder": "Enter address",
      "shippingCarrierLabel": "SHIPPING CARRIER*",
      "shippingCarrierPlaceholder": "Choose a Shipping Carrier",
      "numberOfParcelsLabel": "NUMBER OF PARCELS*",
      "numberOfParcelsPlaceholder": "Enter number of parcels",
      "weightLabel": "WEIGHT (kg)",
      "weightPlaceholder": "Enter weight",
      "deliveryDateLabel": "Delivery date*",
      "itemNameLabel": "Item Name* (max. 100 characters)",
      "itemNamePlaceholder": "Enter item name"
    },
    "buttons": {
      "select": "Select",
      "errored": "Errored",
      "saving": "Saving...",
      "complete": "Complete",
      "next": "Next",
      "back": "Back"
    },
    "notifications": {
      "createdSuccess": "The label has been successfully created!",
      "createdError": "An error occurred while creating the label..",
      "updatedSuccess": "The label has been successfully updated!",
      "updatedError": "An error occurred while updating the label.."
    },
    "shippingPresetRemovedInvalid": "This label uses either a removed or invalid Warehouse.",
    "shippingPresetRemovedInvalidLink": "You can review your Warehouses on Settings page.",
    "shippingCarrierDeactivated": "This label uses a Shipping Carrier that has been deactivated.",
    "invalid": "SOME FIELDS ARE INVALID",
    "changesWillBeLostConfirm": "Unsaved label changes will be lost. Do you want to proceed?"
  }
}
