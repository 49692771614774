import { decamelizeKeys, camelizeKeys } from "humps";
import axiosOpenApiWrapper from "../../lib/axios-open-api-wrapper";

export default async (csrfToken, sessionToken, shippingPreset) => {
  const { status, data } = await axiosOpenApiWrapper({
    method: "post",
    url: `/api/shipping_presets/`,
    openApiUrl: "/api/shipping_presets/",
    headers: {
      "X-CSRF-TOKEN": csrfToken,
      Authorization: `Bearer ${sessionToken}`,
    },
    data: decamelizeKeys({
      shipping_preset: shippingPreset,
    }),
    validateStatus: () => true,
  });

  const payload = camelizeKeys(data.payload);
  const error = `${status}` !== "200";
  const shippingPresets = payload?.shippingPresets;
  return { error, shippingPresets };
};
