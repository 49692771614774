import { camelizeKeys, decamelizeKeys } from "humps";
import axiosOpenApiWrapper from "../../lib/axios-open-api-wrapper";

export default async (csrfToken, sessionToken, orderId, archived) => {
  const { status, data } = await axiosOpenApiWrapper({
    method: "put",
    url: `/api/orders/${orderId}/archive`,
    openApiUrl: "/api/orders/{orderId}/archive",
    headers: {
      "X-CSRF-TOKEN": csrfToken,
      Authorization: `Bearer ${sessionToken}`,
    },
    data: decamelizeKeys({
      order: {
        archived,
      },
    }),
    validateStatus: () => true,
  });

  const payload = camelizeKeys(data.payload);
  const error = `${status}` !== "200";
  const order = payload?.order;
  return { error, order };
};
