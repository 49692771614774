<template>
  <div class="languageCyclicalSelect" @click="toggleLocale">
    <div class="lang" :class="displayedLocale" />
    {{ $t(displayedLocale) }}
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      locale: "user/locale",
    }),

    displayedLocale() {
      return this.locale === "jp" ? "en" : "jp";
    },
  },

  methods: {
    ...mapActions({
      setLocale: "user/setLocale",
    }),

    toggleLocale() {
      const nextLocale = this.locale === "jp" ? "en" : "jp";
      this.setLocale(nextLocale);
    },
  },
};
</script>

<i18n src="./locale.json"></i18n>

<style lang="scss" scoped>
@import "../../App/css/variables";

.languageCyclicalSelect {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 235px;
  height: 55px;
  border: 2px solid $lines;
  border-radius: 10px;
  background-color: $white;
  cursor: pointer;
  user-select: none;
}

.lang {
  position: absolute;
  top: 9px;
  left: 10px;
  width: 32px;
  height: 32px;

  &.jp {
    background: url("../../../assets/images/icons/lang-jp.svg");
  }

  &.en {
    background: url("../../../assets/images/icons/lang-en.svg");
  }
}
</style>
