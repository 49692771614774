{
  "jp": {
    "buttons": {
      "fetching": "データ取得中",
      "redirecting": "リダイレクト中",
      "login": "Wixアカウントでログイン"
    },
    "header": "UnipostalはWixでのみご利用いただけます。",
    "info": "Unipostalをご利用いただくため、下のボタンからWixアカウントでログインして下さい。"
  },
  "en": {
    "buttons": {
      "fetching": "Fetching data...",
      "redirecting": "Redirecting...",
      "login": "Login through WIX"
    },
    "header": "Unipostal only works<br />through WIX.",
    "info": "In order to use the application, click the button below<br />and login through your WIX account."
  }
}
