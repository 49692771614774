<template>
  <slot></slot>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      locale: "user/locale",
    }),
  },

  watch: {
    locale: {
      handler(value) {
        this.$root.$i18n.locale = value;
      },
      immediate: true,
    },
  },
};
</script>
