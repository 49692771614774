{
  "jp": {
    "settings": "設定",
    "profile": "アカウント",
    "subscription": "ご利用プラン",
    "shippingPresets": "出荷元登録",
    "shippingCarriers": "配送会社",
    "selectSubscriptionWarning": "まだご利用プランが選択されていません。",
    "noShippingCarriersWarning": "まだ運送会社を有効化していません。",
    "noShippingPresetsWarning": "有効な出荷元情報がセットされていません。"
  },
  "en": {
    "settings": "Settings",
    "profile": "Profile",
    "subscription": "Subscription",
    "shippingPresets": "Warehouses",
    "shippingCarriers": "Shipping Carriers",
    "selectSubscriptionWarning": "You haven't selected a subscription plan yet",
    "noShippingCarriersWarning": "You haven't activated any shipping carrier yet.",
    "noShippingPresetsWarning": "You don't have any valid Warehouses."
  }
}
