import { decamelizeKeys } from "humps";
import axiosOpenApiWrapper from "../../lib/axios-open-api-wrapper";

export default async (csrfToken, sessionToken, locale) => {
  const { status } = await axiosOpenApiWrapper({
    method: "put",
    url: "/api/users/me/update_locale",
    headers: {
      "X-CSRF-TOKEN": csrfToken,
      Authorization: `Bearer ${sessionToken}`,
    },
    data: decamelizeKeys({
      user: {
        locale,
      },
    }),
    validateStatus: () => true,
  });

  const error = `${status}` !== "200";
  return { error };
};
