{
  "jp": {
    "header": "ご利用プラン",
    "buttons": {
      "upgradePlan": "利用プランを変更",
      "cancelSubscription": "プランの利用を停止"
    },
    "plan": "現在のご利用プラン：<br /><b>{name}</b> プラン",
    "planDesc": "次回のお支払い(金額:<b>{amount}</b>)は<b>{date}</b>です。",
    "labelBalanceDepleted": "現在ご利用プランの今月の配送伝票を使い切りました。",
    "labelUnlimited": "このプランの配送伝票枚数 <b>無制限</b>です!",
    "labelBalance": "今月の作成可能なラベル枚数はあと<b>{labelBalance}</b>枚です。",
    "subscriptionName": {
      "TRIAL": "お試しプラン",
      "STARTER": "ミニプラン",
      "PROFESSIONAL": "スタンダードプラン",
      "PREMIER": "ビジネスプラン",
      "ENTERPRISE": "プレミアムプラン"
    }
  },
  "en": {
    "header": "Subscription",
    "buttons": {
      "upgradePlan": "UPGRADE PLAN",
      "cancelSubscription": "CANCEL SUBSCRIPTION"
    },
    "plan": "You’re currently on<br /><b>{name}</b> Subscription.",
    "planDesc": "Your next payment of <b>{amount}</b> will occur on the <b>{date}.</b>",
    "labelBalanceDepleted": "You have used all labels available on your current plan for this month!",
    "labelUnlimited": "This plan comes with <b>unlimited</b> labels!",
    "labelBalance": "You have <b>{labelBalance}</b> labels remaining on your current plan.",
    "subscriptionName": {
      "TRIAL": "TRIAL",
      "STARTER": "STARTER",
      "PROFESSIONAL": "PROFESSIONAL",
      "PREMIER": "PREMIER",
      "ENTERPRISE": "ENTERPRISE"
    }
  }
}
