<template>
  <div>
    <div class="overlay" />
    <div class="confirm">
      <h1>{{ content.title }}</h1>
      <p v-if="content.text">{{ content.text }}</p>
      <div class="actions">
        <Button class="btn" size="big" @click="close(true)">{{ content.yesText || $t("buttons.ok") }}</Button>
        <Button class="btn" size="big" type="inverted" @click="close(false)">
          {{ content.noText || $t("buttons.cancel") }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Button from "../inputs/Button";

export default {
  components: {
    Button,
  },

  computed: {
    ...mapGetters({
      content: "confirmBox/content",
    }),
  },

  methods: {
    ...mapActions({
      close: "confirmBox/close",
    }),
  },
};
</script>

<i18n src="./locale.json"></i18n>

<style lang="scss" scoped>
@import "../App/css/variables";

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: rgb(0 0 0 / 40%);
}

.confirm {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1001;
  width: 100%;
  max-width: 470px;
  padding: 30px;
  border-radius: 8px;
  overflow: hidden;
  background-color: $white;
  transform: translate(-50%, -50%);
}

h1 {
  font-weight: bold;
  font-size: 18px;
}

p {
  margin-top: 10px;
  font-size: 16px;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 30px;

  .btn {
    min-width: 80px;
    margin-left: 20px;
  }
}
</style>
