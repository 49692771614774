<template>
  <div>
    <div class="overlay" @click="$emit('close')" />
    <div class="modal">
      <div class="header" :class="{ withUnderline: hasTitle }">
        <h5>
          {{ title }}
          <slot name="title"></slot>
        </h5>
        <slot name="additional"></slot>
        <div class="closeBtn" @click="$emit('close')">×</div>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },

  computed: {
    hasTitle() {
      return this.title || this.$slots["title"];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../App/css/variables";

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: rgb(0 0 0 / 40%);
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 101;
  width: 90vw;
  max-width: 1000px;
  padding: 60px 80px 20px;
  border-radius: 8px;
  overflow: hidden;
  background-color: $white;
  transform: translate(-50%, -50%);
}

.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  padding-right: 40px;
  padding-bottom: 15px;

  &.withUnderline::after {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 50px;
    border-bottom: 2px solid $tertiary;
    content: "";
  }

  h5 {
    color: $primary;
    font-size: 24px;
  }
}

.closeBtn {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: 2px solid $lines;
  border-radius: 100px;
  color: $primary;
  font-size: 30px;
  cursor: pointer;
}
</style>
