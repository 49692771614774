<template>
  <LayoutWithOrderList>
    <div v-if="fetched" class="orderDetails">
      <PageHeader
        :title="$t('header.title')"
        :btnTitle="$t('header.addLabel')"
        :additionalBtnTitle="order.archived ? $t('header.unarchiveOrder') : $t('header.archiveOrder')"
        @additionalBtnClick="() => (order.archived ? unarchiveOrder() : archiveOrder())"
        @btnClick="showCreateLabelModal = true"
      />

      <EmptyLabelList v-if="labels.length === 0" class="emptyLabelList" />
      <AnimationList :key="order.id">
        <Label v-for="label in labels" :key="label.id" :label="label" :order="order" class="animationList-item" />
      </AnimationList>

      <div v-if="order.manuallyEdited" class="manuallyEditedInfo">
        <strong>{{ $t("manuallyEdited") }}</strong>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-html="$t('manuallyEditedInfo')" />
      </div>
      <AnimationFade>
        <LabelModal v-if="showCreateLabelModal" :order="order" :label="{}" @close="showCreateLabelModal = false" />
      </AnimationFade>
    </div>
  </LayoutWithOrderList>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { fetchStatuses } from "../../../stores/consts";
import notificationOnSubmit from "../../../lib/notification-on-submit";
import confirmBox from "../../../lib/confirm-box";
import LayoutWithOrderList from "../../layouts/WithOrderList";
import AnimationFade from "../../animations/AnimationFade";
import AnimationList from "../../animations/AnimationList";
import PageHeader from "../../PageHeader";
import LabelModal from "../../modals/Label";
import EmptyLabelList from "./EmptyLabelList";
import Label from "./Label";

export default {
  components: {
    AnimationFade,
    AnimationList,
    LabelModal,
    LayoutWithOrderList,
    PageHeader,
    EmptyLabelList,
    Label,
  },

  data() {
    return {
      fetchStatuses,
      showCreateLabelModal: false,
    };
  },
  computed: {
    ...mapGetters({
      ordersFetched: "orders/fetched",
      labelsFetched: "labels/fetched",
      getOrder: "orders/getOrder",
      getLabels: "labels/forOrder",
    }),

    order() {
      const id = parseInt(this.$route.params.id);
      return this.getOrder(id);
    },

    labels() {
      return this.getLabels(this.order.id);
    },

    fetched() {
      return this.ordersFetched && this.labelsFetched;
    },
  },

  methods: {
    ...mapActions({
      archiveOrderVuex: "orders/archive",
    }),

    async unarchiveOrder() {
      if (await confirmBox(this, { title: this.$t("unarchiveConfirmation") })) {
        notificationOnSubmit(this, {
          statusWatcher: "orders/submitStatus",
          onSuccessMessage: this.$t("notifications.unarchivedSuccess"),
          onErrorMessage: this.$t("notifications.unarchivedError"),
        });
        this.archiveOrderVuex({ order: this.order, archive: false });
      }
    },

    async archiveOrder() {
      if (await confirmBox(this, { title: this.$t("archiveConfirmation") })) {
        notificationOnSubmit(this, {
          statusWatcher: "orders/submitStatus",
          onSuccessMessage: this.$t("notifications.archivedSuccess"),
          onErrorMessage: this.$t("notifications.archivedError"),
        });
        this.archiveOrderVuex({ order: this.order, archive: true });
      }
    },
  },
};
</script>

<i18n src="./locale.json"></i18n>

<style lang="scss" scoped>
@import "../../App/css/variables";

.orderDetails {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 0 120px;
}

.top {
  display: flex;
  justify-content: space-between;
  padding: 80px 0;
  color: $primary;
}

.emptyLabelList {
  flex-grow: 1;
}

.actions {
  display: flex;
  align-items: stretch;

  .btn {
    margin-left: 10px;
  }
}

.manuallyEditedInfo {
  display: flex;
  align-items: center;
  margin: 0 -100px 20px;
  padding: 16px;
  border: 2px solid $lines;
  border-radius: 8px;
  color: $primary;
  font-size: 14px;

  &::before {
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border-radius: 100px;
    background-color: $lines;
    content: "!";
  }

  span {
    margin-left: 40px;

    b {
      color: $tertiary;
    }
  }
}
</style>
