<template>
  <div>
    <span class="title">{{ $t("title") }}</span>
    <div class="labelWrapper">
      <div class="header">
        <h5>{{ $t("subTitle") }}</h5>
        <div class="actions">
          <Button class="btn" type="noBorders" size="small" :disabled="labelSubmitting" @click="remove">
            {{ $t("buttons.remove") }}
          </Button>
          <Button
            class="btn"
            type="inverted"
            size="small"
            :title="!!label.downloadUrl ? $t('buttons.noEditAfterGenerationExplanation') : ''"
            :disabled="!!label.downloadUrl || labelSubmitting"
            @click="showModal = true"
          >
            {{ $t("buttons.edit") }}
          </Button>
          <GenerateLabelButton class="btn" size="small" :label="label" @editLabelClicked="showModal = true">
            {{ $t("buttons.print") }}
          </GenerateLabelButton>
        </div>
      </div>

      <div class="section underline">
        <div>
          <p class="fieldTitle">{{ $t("fields.person") }}</p>
          <p class="fieldValue">
            <template v-if="locale === 'en'">{{ order.buyerFirstName }} {{ order.buyerLastName }}</template>
            <template v-else>{{ order.buyerLastName }} {{ order.buyerFirstName }}</template>
          </p>
        </div>
        <div>
          <p class="fieldTitle">{{ $t("fields.orderNr") }}</p>
          <p class="fieldValue">{{ order.wixNumericId }}</p>
        </div>
        <div class="sameWidth">
          <p class="fieldTitle">{{ $t("fields.parcelInformation") }}</p>
          <p class="fieldValue">{{ label.numberOfParcels }}</p>
        </div>
      </div>

      <div class="section underline">
        <div>
          <p class="fieldTitle">{{ $t("fields.itemName") }}</p>
          <p class="fieldValue">{{ label.itemName }}</p>
        </div>
        <div class="sameWidth">
          <p class="fieldTitle">{{ $t("fields.deliveryDate") }}</p>
          <p class="fieldValue">{{ label.deliveryDate }}</p>
        </div>
      </div>

      <div class="section">
        <div>
          <p class="fieldTitle">{{ $t("fields.fromAddress") }}</p>
          <p class="fieldSubtitle underline">{{ $t("fields.storeInformation") }}</p>
          <p class="fieldValue">
            {{ shippingPreset.businessName }}<br />
            <br />
            <template v-if="shippingPreset.phoneNr">
              {{ $t("fields.phone", { value: shippingPreset.phoneNr }) }}
            </template>
            <br />
          </p>

          <p class="fieldSubtitle underline">{{ $t("fields.address") }}</p>
          <p class="fieldValue">
            <template v-if="locale === 'jp'">
              {{ $t("fields.zipcode", { value: shippingPreset.addressZipCode }) }}<br />
            </template>
            {{ shippingPreset.address }}
            <template v-if="locale !== 'jp'">
              <br />
              {{ $t("fields.zipcode", { value: shippingPreset.addressZipCode }) }}
            </template>
          </p>
        </div>
        <div class="sameWidth">
          <p class="fieldTitle">{{ $t("fields.toAddress") }}</p>
          <p class="fieldSubtitle underline">{{ $t("fields.recipientInformation") }}</p>
          <p class="fieldValue">
            <template v-if="locale === 'en'">{{ label.shippingFirstName }} {{ label.shippingLastName }}<br /></template>
            <template v-else>{{ label.shippingLastName }} {{ label.shippingFirstName }}<br /></template>
            {{ label.shippingAddressCompanyName }}<br />
            <template v-if="label.shippingPhoneNr">
              {{ $t("fields.phone", { value: label.shippingPhoneNr }) }}
            </template>
          </p>

          <p class="fieldSubtitle underline">{{ $t("fields.address") }}</p>
          <p class="fieldValue">
            <template v-if="locale === 'jp'">
              {{ $t("fields.zipcode", { value: label.shippingAddressZipCode }) }}<br />
            </template>
            {{ label.shippingAddressLine }}
            <template v-if="locale !== 'jp'">
              <br />
              {{ $t("fields.zipcode", { value: label.shippingAddressZipCode }) }}
            </template>
          </p>
        </div>
      </div>
      <div class="section filled bottomBox">
        <div>
          <p class="fieldTitle">{{ $t("fields.shippingCarrier") }}</p>
          <p class="fieldValue">{{ shippingCarrierName }}</p>
        </div>
        <div
          v-for="trackingNumber in label.trackingNumbers"
          :key="trackingNumber.trackingNumber"
          class="trackingNumberSection"
        >
          <div>
            <p class="fieldTitle">{{ $t("fields.trackingNumber") }}</p>
            <p class="fieldValue">{{ trackingNumber.trackingNumber }}</p>
          </div>

          <div class="sameWidth">
            <p class="fieldTitle">{{ $t("fields.shippingStatus") }}</p>
            <p class="fieldValue shippingStatus">
              <template
                v-if="
                  $t(`shippingStatuses.${latestShippingStatus(trackingNumber)}`) ===
                  $t(`shippingStatusMessages.${latestShippingStatusMessage(trackingNumber)}`)
                "
              >
                {{ $t(`shippingStatuses.${latestShippingStatus(trackingNumber)}`) }}
              </template>
              <template v-else>
                {{ $t(`shippingStatuses.${latestShippingStatus(trackingNumber)}`) }}:
                <i>{{ $t(`shippingStatusMessages.${latestShippingStatusMessage(trackingNumber)}`) }}</i>
              </template>
            </p>
          </div>

          <div
            :class="['trackingHistoryBtn', { opened: openTrackingHistory[trackingNumber.trackingNumber] }]"
            @click="
              openTrackingHistory[trackingNumber.trackingNumber] = !openTrackingHistory[trackingNumber.trackingNumber]
            "
          />

          <div v-if="openTrackingHistory[trackingNumber.trackingNumber]" class="deliveryHistory">
            <div class="headerRow">
              <p>{{ $t("fields.date") }}</p>
              <p class="sameWidth">{{ $t("fields.shippingStatus") }}</p>
            </div>

            <div v-for="shippingStatus in trackingNumber.shippingStatuses" :key="shippingStatus.id" class="row">
              <p>{{ shippingStatus.createdAt }}</p>
              <p class="sameWidth">
                <template
                  v-if="
                    $t(`shippingStatuses.${shippingStatus.status}`) ===
                    $t(`shippingStatusMessages.${shippingStatus.message}`)
                  "
                >
                  {{ $t(`shippingStatuses.${shippingStatus.status}`) }}
                </template>
                <template v-else>
                  {{ $t(`shippingStatuses.${shippingStatus.status}`) }}:
                  <i>{{ $t(`shippingStatusMessages.${shippingStatus.message}`) }}</i>
                </template>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AnimationFade>
      <LabelModal v-if="showModal" :order="order" :label="label" @close="showModal = false" />
    </AnimationFade>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import notificationOnSubmit from "../../../../lib/notification-on-submit";
import confirmBox from "../../../../lib/confirm-box";
import AnimationFade from "../../../animations/AnimationFade";
import Button from "../../../inputs/Button";
import LabelModal from "../../../modals/Label";
import GenerateLabelButton from "./GenerateLabelButton";

export default {
  components: {
    AnimationFade,
    Button,
    GenerateLabelButton,
    LabelModal,
  },

  props: {
    order: {
      type: Object,
      required: true,
    },
    label: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showModal: false,
      openTrackingHistory: {},
    };
  },

  computed: {
    ...mapGetters({
      shippingPresetById: "shippingPresets/byId",
      shippingCarrierNameById: "shippingCarriers/nameById",
      labelSubmitting: "labels/submitting",
      locale: "user/locale",
    }),

    shippingPreset() {
      return this.shippingPresetById(this.label.shippingPresetId) || {};
    },

    shippingCarrierName() {
      return this.shippingCarrierNameById(this.label.shippingCarrierId) || "-";
    },
  },

  methods: {
    ...mapActions({
      removeVuex: "labels/remove",
    }),

    async remove() {
      if (await confirmBox(this, { title: this.$t("removeLabelConfirmation") })) {
        notificationOnSubmit(this, {
          statusWatcher: "labels/submitStatus",
          onSuccessMessage: this.$t("notifications.removedSuccess"),
          onErrorMessage: this.$t("notifications.removedError"),
        });
        this.removeVuex(this.label);
      }
    },

    latestShippingStatus(trackingNumber) {
      return trackingNumber.shippingStatuses[0].status;
    },

    latestShippingStatusMessage(trackingNumber) {
      return trackingNumber.shippingStatuses[0].message;
    },
  },
};
</script>

<i18n src="./locale.json"></i18n>

<style lang="scss" scoped>
@import "../../../App/css/variables";

.title {
  color: $secondary;
  font-size: 14px;
}

.actions {
  display: flex;
  align-items: stretch;

  .btn {
    margin-left: 10px;
  }
}

.labelWrapper {
  margin: 15px 0;
  padding: 35px 40px 22px;
  border: 2px solid $lines;
  border-radius: 8px;
  background-color: $white;
}

.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 50px;
    border-bottom: 2px solid $tertiary;
    content: "";
  }

  h5 {
    color: $primary;
    font-size: 24px;
  }
}

.section {
  display: flex;
  justify-content: space-between;
  margin-top: 70px;

  &.filled {
    margin-right: -20px;
    margin-left: -20px;
    padding: 20px 20px 0;
    border-radius: 8px;
    background-color: $blue-1-aqua-haze;
  }

  div:first-child {
    margin-right: 20px;
  }
}

.fieldTitle {
  color: $primary;
  font-weight: bold;
  font-size: 12px;
}

.fieldSubtitle {
  margin-top: 30px;
  padding-bottom: 8px;
  color: $primary;
  font-size: 10px;
}

.fieldValue {
  max-width: 425px;
  margin: 8px 0 20px;
  color: $primary;
  font-size: 14px;
  line-height: 25px;

  &.invalid {
    color: $danger;
  }
}

.fieldValueSubtitle {
  margin: 0 0 20px;
  color: $primary;
  font-size: 11px;
}

.underline {
  border-bottom: 1px solid $lines;
}

.sameWidth {
  flex-shrink: 0;
  width: 50%;
  max-width: 350px;
}

.bottomBox {
  flex-direction: column;

  .trackingNumberSection {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .sameWidth {
    padding-right: 90px;
  }

  .trackingHistoryBtn {
    position: absolute;
    top: 10px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: transform 0.2s ease;

    &::after {
      display: block;
      width: 13px;
      height: 13px;
      margin-top: 10px;
      border-top: 2px solid $black;
      border-left: 2px solid $black;
      transform: rotate(45deg);
      content: "";
    }

    &.opened {
      transform: rotate(180deg);
    }
  }

  .shippingStatus {
    color: $purple;
  }

  .deliveryHistory {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: -17px 0 20px;
    padding-top: 15px;
    border-top: 1px dashed $lines;
    border-bottom: 2px solid $lines;

    .headerRow {
      display: flex;
      justify-content: space-between;
      margin: 0;
      padding-bottom: 15px;
      color: $secondary;
      font-size: 12px;
    }

    // only first row
    .headerRow + .row {
      color: $purple;
    }

    .row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid $lines;
      font-size: 14px;

      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
      }
    }
  }
}
</style>
