<template>
  <div class="filterSelect">
    <div class="input" @click="toggleDropdown">
      <span v-if="filter === orderSearchFilters.allActive">{{ $t("filters.allActive") }}</span>
      <span v-if="filter === orderSearchFilters.archived">{{ $t("filters.archived") }}</span>
      <span v-if="filter === orderSearchFilters.fulfilled">{{ $t("filters.fulfilled") }}</span>
      <span v-if="filter === orderSearchFilters.unfulfilled">{{ $t("filters.unfulfilled") }}</span>
    </div>

    <AnimationFade>
      <div v-if="opened" class="dropdownWrapper">
        <div class="overlay" @click="toggleDropdown" />
        <div class="dropdown">
          <h5>{{ $t("filterTitle") }}</h5>
          <ul class="list noBullets">
            <li
              :class="{ active: filter === orderSearchFilters.allActive }"
              @click="changeFilter(orderSearchFilters.allActive)"
            >
              {{ $t("filters.allActive") }}
            </li>
            <li
              :class="{ active: filter === orderSearchFilters.archived }"
              @click="changeFilter(orderSearchFilters.archived)"
            >
              {{ $t("filters.archived") }}
            </li>
            <li
              :class="{ active: filter === orderSearchFilters.fulfilled }"
              @click="changeFilter(orderSearchFilters.fulfilled)"
            >
              {{ $t("filters.fulfilled") }}
            </li>
            <li
              :class="{ active: filter === orderSearchFilters.unfulfilled }"
              @click="changeFilter(orderSearchFilters.unfulfilled)"
            >
              {{ $t("filters.unfulfilled") }}
            </li>
          </ul>
          <hr />
          <h5>{{ $t("sortingTitle") }}</h5>
          <ul class="list withBullets">
            <li
              :class="{ active: sorting === orderSearchSorting.newestToOldest }"
              @click="changeSorting(orderSearchSorting.newestToOldest)"
            >
              {{ $t("sorting.newestToOldest") }}
            </li>
            <li
              :class="{ active: sorting === orderSearchSorting.oldestToNewest }"
              @click="changeSorting(orderSearchSorting.oldestToNewest)"
            >
              {{ $t("sorting.oldestToNewest") }}
            </li>
            <li
              :class="{ active: sorting === orderSearchSorting.highestValueToLowest }"
              @click="changeSorting(orderSearchSorting.highestValueToLowest)"
            >
              {{ $t("sorting.highestValueToLowest") }}
            </li>
            <li
              :class="{ active: sorting === orderSearchSorting.lowestValueToHighest }"
              @click="changeSorting(orderSearchSorting.lowestValueToHighest)"
            >
              {{ $t("sorting.lowestValueToHighest") }}
            </li>
          </ul>
        </div>
      </div>
    </AnimationFade>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { orderSearchFilters, orderSearchSorting } from "../../../stores/consts";
import AnimationFade from "../../animations/AnimationFade";
export default {
  components: {
    AnimationFade,
  },

  data() {
    return {
      opened: false,
      orderSearchFilters,
      orderSearchSorting,
    };
  },
  computed: {
    ...mapGetters({
      filter: "orderSearch/filter",
      sorting: "orderSearch/sorting",
    }),
  },
  methods: {
    ...mapActions({
      changeFilter: "orderSearch/changeFilter",
      changeSorting: "orderSearch/changeSorting",
    }),
    toggleDropdown() {
      this.opened = !this.opened;
    },
  },
};
</script>

<i18n src="./locale.json"></i18n>

<style lang="scss" scoped>
@import "../../App/css/variables";

.filterSelect {
  position: relative;
}

.input {
  position: relative;
  display: flex;
  align-items: center;
  height: 46px;
  padding: 0 16px;
  border-bottom: 1px solid $lines;
  color: $white;
  font-size: 14px;
  cursor: pointer;
  user-select: none;

  &::after {
    position: absolute;
    right: 0;
    border: 3px solid $white;
    border-top: 3px solid transparent;
    border-left: 3px solid transparent;
    transform: rotate(45deg);
    content: "";
  }
}

.dropdownWrapper {
  position: relative;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
}

.dropdown {
  position: absolute;
  top: 5px;
  right: 0;
  left: 0;
  z-index: 15;
  padding: 0 15px 15px;
  border-radius: 12px;
  background: $white;
  box-shadow: 0 35px 28px rgb(15 11 41 / 15%);

  h5 {
    margin: 15px 0;
  }

  hr {
    height: 1px;
    margin-right: -15px;
    margin-left: -15px;
    border: none;
    background-color: $lines;
  }

  .list {
    margin-right: -15px;
    margin-left: -15px;
    padding: 0;
    list-style: none;

    li {
      position: relative;
      display: flex;
      align-items: center;
      height: 36px;
      padding: 0 15px;
      font-size: 14px;
      cursor: pointer;
    }

    &.noBullets {
      li {
        &.active,
        &:hover {
          background-color: $blue-1-aqua-haze;
        }
      }
    }

    &.withBullets {
      li {
        padding-left: 47px;

        &::before {
          position: absolute;
          left: 15px;
          box-sizing: border-box;
          width: 24px;
          height: 24px;
          border: 2px solid $lines;
          border-radius: 100px;
          content: "";
        }

        &.active {
          &::before {
            border-color: $inputs !important;
            background-color: $inputs !important;
          }
        }

        &:hover {
          &::before {
            border-color: $tertiary;
            background-color: $tertiary;
          }
        }
      }
    }
  }
}
</style>
