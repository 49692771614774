import { decamelizeKeys, camelizeKeys } from "humps";
import axiosOpenApiWrapper from "../../lib/axios-open-api-wrapper";

export default async (csrfToken, sessionToken, orderId, label) => {
  const { status, data } = await axiosOpenApiWrapper({
    method: "post",
    url: `/api/orders/${orderId}/labels/`,
    openApiUrl: "/api/orders/{orderId}/labels/",
    headers: {
      "X-CSRF-TOKEN": csrfToken,
      Authorization: `Bearer ${sessionToken}`,
    },
    data: decamelizeKeys({
      label,
    }),
    validateStatus: () => true,
  });

  const payload = camelizeKeys(data.payload);
  const error = `${status}` !== "200";
  const createdLabel = payload?.label;
  const order = payload?.order;
  return { error, label: createdLabel, order };
};
