<template>
  <LayoutWithSettingsMenu>
    <PageHeader :title="$t('header.title')" />
    <div class="box">
      <img :src="boxesSvgUrl" />
      <Button size="big" @click="$router.push({ path: '/settings/subscription/select-plan' })">
        {{ $t("buttons.selectPlan") }}
      </Button>
    </div>
    <InvoiceList />
  </LayoutWithSettingsMenu>
</template>

<script>
import LayoutWithSettingsMenu from "../../../layouts/WithSettingsMenu";
import PageHeader from "../../../PageHeader";
import Button from "../../../inputs/Button";
import boxesSvgUrl from "../../../../assets/images/boxes.svg";
import InvoiceList from "../InvoiceList";

export default {
  components: {
    LayoutWithSettingsMenu,
    PageHeader,
    Button,
    InvoiceList,
  },

  data() {
    return {
      boxesSvgUrl,
    };
  },
};
</script>

<i18n src="./locale.json"></i18n>

<style lang="scss" scoped>
@import "../../../App/css/variables";

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  border: 2px solid $lines;
  border-radius: 8px;
  background-color: $white;

  img {
    margin-bottom: 50px;
  }
}
</style>
