<template>
  <div class="ordersPanel">
    <InputText
      class="search"
      icon="magnifying-glass"
      noBorders
      changeOnKeyUp
      :placeholder="$t('searchPlaceholder')"
      :value="searchQuery"
      @change="throttledChangeSearchQuery"
    />
    <FilterSelect class="filterSelect" />

    <a class="refresh" :class="{ animated: refreshing }" :title="$t('refreshOrders')" @click="refreshOrders" />

    <div class="orders">
      <div v-if="fetched" class="scrollable">
        <EmptyOrderList v-if="orders.length === 0" />

        <AnimationList>
          <div
            v-for="order in orders"
            :key="order.id"
            :class="{ highlighted: order.id == $route.params.id }"
            class="order animationList-item"
            @click="$router.push({ path: `/orders/${order.id}` })"
          >
            <div>
              <h5 class="title">
                <template v-if="locale === 'en'">{{ order.buyerFirstName }} {{ order.buyerLastName }}</template>
                <template v-else>{{ order.buyerLastName }} {{ order.buyerFirstName }}</template>
              </h5>
              <small class="subtitle">{{ $t("orderTitle", { id: order.wixNumericId }) }}</small>
            </div>
            <div class="additionalInfo">
              <small>
                {{ $t(`paymentStatuses.${order.paymentStatus}`) }} -
                {{ $t(`fulfillmentStatuses.${order.fulfillmentStatus}`) }}
              </small>
              <small>{{ formatPrice(order) }}</small>
            </div>
          </div>
        </AnimationList>
      </div>
      <Loader v-else :delay="200" />
    </div>
  </div>
</template>

<script>
import throttle from "lodash.throttle";
import { mapActions, mapGetters } from "vuex";
import AnimationList from "../animations/AnimationList";
import InputText from "../inputs/InputText";
import Loader from "./Loader";
import EmptyOrderList from "./EmptyOrderList";
import FilterSelect from "./FilterSelect";
import notificationOnSubmit from "../../lib/notification-on-submit";

export default {
  components: {
    Loader,
    AnimationList,
    EmptyOrderList,
    InputText,
    FilterSelect,
  },

  computed: {
    ...mapGetters({
      fetched: "orders/fetched",
      orders: "orderSearch/result",
      searchQuery: "orderSearch/query",
      refreshing: "orders/refreshing",
      locale: "user/locale",
    }),
  },

  methods: {
    ...mapActions({
      changeSearchQuery: "orderSearch/changeQuery",
      refreshOrdersVuex: "orders/refresh",
    }),

    throttledChangeSearchQuery(query) {
      this.throttledFn = this.throttledFn || throttle(this.changeSearchQuery, 400);
      this.throttledFn(query);
    },

    formatPrice(order) {
      const value = parseFloat(order.totalPrice);
      return value.toLocaleString("en-US", {
        style: "currency",
        currency: order.currency,
      });
    },
    refreshOrders() {
      notificationOnSubmit(this, {
        statusWatcher: "orders/refreshStatus",
        onSuccessMessage: this.$t("notifications.refreshSuccess"),
        onErrorMessage: this.$t("notifications.refreshError"),
      });
      this.$router.push({ path: "/" });
      this.refreshOrdersVuex();
    },
  },
};
</script>

<i18n src="./locale.json"></i18n>

<style lang="scss" scoped>
@import "../App/css/variables";

.ordersPanel {
  display: flex;
  flex-direction: column;
  padding: 16px 16px 0;
  background-color: $primary;
}

.search {
  flex-shrink: 0;
}

.filterSelect {
  flex-shrink: 0;
  margin-top: 10px;
}

.refresh {
  display: flex;
  align-items: center;
  width: 30px;
  height: 30px;
  margin-top: 30px;
  background-image: url("../../assets/images/icons/refresh.svg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  cursor: pointer;
  filter: brightness(0) invert(1);

  /* stylelint-disable-next-line selector-class-pattern */
  &.router-link-active,
  &:hover {
    filter: invert(68%) sepia(21%) saturate(916%) hue-rotate(168deg) brightness(95%) contrast(96%);
  }
}

.orders {
  position: relative;
  flex-grow: 1;
  align-items: stretch;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: 30px;
    background: $primary;
    background: linear-gradient(180deg, $primary 0%, rgb(0 0 0 / 0%) 100%);
    content: "";
  }

  .scrollable {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin-right: -15px;
    padding: 15px 15px 15px 0;
    overflow: auto;
  }
}

.order {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 190px;
  margin-top: 15px;
  padding: 20px 25px;
  border-radius: 12px;
  background-color: $white;
  cursor: pointer;

  /* stylelint-disable-next-line selector-class-pattern */
  &.router-link-exact-active,
  &:hover,
  &.highlighted {
    background-color: $tertiary;

    * {
      color: $white !important;
    }
  }

  .title {
    max-width: 100%;
    overflow: hidden;
    font-size: 16px;
    text-overflow: ellipsis;
  }

  .subtitle {
    color: $tertiary;
  }

  .additionalInfo {
    display: flex;
    justify-content: space-between;
  }
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.animated {
  animation: rotate 1.5s linear infinite;
}
</style>
