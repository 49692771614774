import { render } from "./index.vue?vue&type=template&id=40e7c509&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"

import "./index.vue?vue&type=style&index=0&id=40e7c509&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-40e7c509"
/* custom blocks */
import block0 from "./locale.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Fapp%2Fapp_frontend%2Fcomponents%2FIncompleteSettingsInfoBar%2Findex.vue"
if (typeof block0 === 'function') block0(script)


export default script