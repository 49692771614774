{
  "jp": {
    "filters": {
      "allActive": "全アクティブラベル",
      "archived": "保存済みの注文",
      "fulfilled": "発送済みの注文",
      "unfulfilled": "未発送の注文"
    },
    "sorting": {
      "newestToOldest": "注文の新しい順",
      "oldestToNewest": "注文の古い順",
      "highestValueToLowest": "注文金額の高い順",
      "lowestValueToHighest": "注文金額の低い順"
    },
    "filterTitle": "条件検索:",
    "sortingTitle": "並べ替える:"
  },
  "en": {
    "filters": {
      "allActive": "Show All Active Labels",
      "archived": "Show Archived Only",
      "fulfilled": "Show Fulfilled Only",
      "unfulfilled": "Show Unfulfilled Only"
    },
    "sorting": {
      "newestToOldest": "Newest to Oldest",
      "oldestToNewest": "Oldest to Newest",
      "highestValueToLowest": "Highest Value to Lowest",
      "lowestValueToHighest": "Lowest Value to Highest"
    },
    "filterTitle": "FILTER BY TYPE:",
    "sortingTitle": "SORTING:"
  }
}
