<template>
  <div class="inputCheckbox" :class="{ checked: computedValue }" @click="customEmit(!computedValue)">
    <span class="label">{{ label }}</span>
    <div class="checkbox" :class="{ checked: computedValue }" />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Boolean,
      default: null,
    },
    label: {
      type: String,
      default: "",
    },
  },

  computed: {
    computedValue() {
      return this.modelValue === null ? this.value : this.modelValue;
    },
  },

  methods: {
    customEmit(value) {
      this.$emit("change", value);
      this.$emit("update:modelValue", value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../App/css/variables";

.inputCheckbox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 46px;
  padding: 0 16px;
  border: 2px solid $lines;
  border-radius: 8px;
  color: $primary;
  background-color: $white;
  cursor: pointer;
  user-select: none;

  &.checked {
    border-color: $inputs;
  }
}

.label {
  font-size: 12px;
}

.checkbox {
  position: relative;
  width: 20px;
  height: 20px;
  border: 2px solid $lines;
  border-radius: 100px;

  &.checked {
    border-color: $inputs !important;
    background-color: $inputs !important;

    &::before {
      position: absolute;
      top: 3px;
      left: 8px;
      width: 1.5px;
      height: 9px;
      background-color: #fff;
      transform: rotate(45deg);
      content: "";
    }

    &::after {
      position: absolute;
      top: 8px;
      left: 4px;
      width: 2px;
      height: 4px;
      background-color: #fff;
      transform: rotate(-45deg);
      content: "";
    }
  }
}
</style>
