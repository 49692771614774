import { fetchStatuses, submitStatuses } from "../consts";
import fetchShippingPresetsApi from "../api/fetchShippingPresets";
import createShippingPresetApi from "../api/createShippingPreset";
import updateShippingPresetApi from "../api/updateShippingPreset";
import setDefaultShippingPresetApi from "../api/setDefaultShippingPreset";
import removeShippingPresetApi from "../api/removeShippingPreset";

const state = {
  fetchStatus: fetchStatuses.idle,
  submitStatus: submitStatuses.idle,
  shippingPresets: [],
};

const getters = {
  fetched: ({ fetchStatus }) => fetchStatus === fetchStatuses.fetched,
  submitStatus: ({ submitStatus }) => submitStatus,
  all: ({ shippingPresets }) => shippingPresets.filter((shippingPreset) => !shippingPreset.deleted),
  allValid: ({ shippingPresets }) =>
    shippingPresets.filter((shippingPreset) => !shippingPreset.deleted && shippingPreset.validData),
  default: ({ shippingPresets }) => shippingPresets.find((shippingPreset) => shippingPreset.default === true),
  byId: ({ shippingPresets }) => (id) => shippingPresets.find((shippingPreset) => shippingPreset.id === id),
};

const actions = {
  async fetch({ commit, rootGetters }) {
    const sessionToken = rootGetters["session/sessionToken"];
    const csrfToken = rootGetters["config/csrfToken"];

    commit("SET_FETCH_STATUS", fetchStatuses.fetching);
    const { error, shippingPresets } = await fetchShippingPresetsApi(csrfToken, sessionToken);

    if (error) return commit("SET_FETCH_STATUS", fetchStatuses.errored);

    commit("SET_FETCH_STATUS", fetchStatuses.fetched);
    commit("SET_SHIPPING_PRESETS", shippingPresets);
  },

  async create({ commit, rootGetters }, shippingPreset) {
    const sessionToken = rootGetters["session/sessionToken"];
    const csrfToken = rootGetters["config/csrfToken"];

    commit("SET_SUBMIT_STATUS", submitStatuses.submitting);
    const { error, shippingPresets } = await createShippingPresetApi(csrfToken, sessionToken, shippingPreset);
    if (error) return commit("SET_SUBMIT_STATUS", submitStatuses.errored);

    commit("SET_SHIPPING_PRESETS", shippingPresets);
    commit("SET_SUBMIT_STATUS", submitStatuses.submitted);
    setTimeout(() => commit("SET_SUBMIT_STATUS", submitStatuses.idle), 0);
  },

  async update({ commit, rootGetters }, shippingPreset) {
    const sessionToken = rootGetters["session/sessionToken"];
    const csrfToken = rootGetters["config/csrfToken"];

    commit("SET_SUBMIT_STATUS", submitStatuses.submitting);
    const { error, shippingPresets } = await updateShippingPresetApi(
      csrfToken,
      sessionToken,
      shippingPreset.id,
      shippingPreset
    );
    if (error) return commit("SET_SUBMIT_STATUS", submitStatuses.errored);

    commit("SET_SHIPPING_PRESETS", shippingPresets);
    commit("SET_SUBMIT_STATUS", submitStatuses.submitted);
    setTimeout(() => commit("SET_SUBMIT_STATUS", submitStatuses.idle), 0);
  },

  async setDefault({ commit, rootGetters }, shippingPresetId) {
    const sessionToken = rootGetters["session/sessionToken"];
    const csrfToken = rootGetters["config/csrfToken"];

    commit("SET_SUBMIT_STATUS", submitStatuses.submitting);
    const { error, shippingPresets } = await setDefaultShippingPresetApi(csrfToken, sessionToken, shippingPresetId);
    if (error) return commit("SET_SUBMIT_STATUS", submitStatuses.errored);

    commit("SET_SHIPPING_PRESETS", shippingPresets);
    commit("SET_SUBMIT_STATUS", submitStatuses.submitted);
    setTimeout(() => commit("SET_SUBMIT_STATUS", submitStatuses.idle), 0);
  },

  async remove({ commit, rootGetters }, shippingPreset) {
    const sessionToken = rootGetters["session/sessionToken"];
    const csrfToken = rootGetters["config/csrfToken"];

    commit("SET_SUBMIT_STATUS", submitStatuses.submitting);
    const { error, shippingPresets } = await removeShippingPresetApi(csrfToken, sessionToken, shippingPreset.id);
    if (error) return commit("SET_SUBMIT_STATUS", submitStatuses.errored);

    commit("SET_SHIPPING_PRESETS", shippingPresets);
    commit("SET_SUBMIT_STATUS", submitStatuses.submitted);
    setTimeout(() => commit("SET_SUBMIT_STATUS", submitStatuses.idle), 0);
  },
};

const mutations = {
  SET_FETCH_STATUS(state, status) {
    state.fetchStatus = status;
  },
  SET_SUBMIT_STATUS(state, status) {
    state.submitStatus = status;
  },
  SET_SHIPPING_PRESETS(state, shippingPresets) {
    state.shippingPresets = shippingPresets;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
