import Cookies from "js-cookie";
import { submitStatuses } from "../consts";
import fetchUserApi from "../api/fetchUser";
import updateUserApi from "../api/updateUser";
import updateUserLocaleApi from "../api/updateUserLocale";

const state = {
  submitStatus: submitStatuses.idle,
  user: {
    fullName: "",
    email: "",
    locale: "",
  },
};

const getters = {
  profile: ({ user }) => user,
  locale: ({ user }) => user?.locale || Cookies.get("language") || "jp",
  submitStatus: ({ submitStatus }) => submitStatus,
};

const actions = {
  async fetch({ commit, rootGetters }) {
    const sessionToken = rootGetters["session/sessionToken"];
    const csrfToken = rootGetters["config/csrfToken"];

    const { user } = await fetchUserApi(csrfToken, sessionToken);
    commit("SET_USER", user);
  },

  async save({ commit, rootGetters }, user) {
    const sessionToken = rootGetters["session/sessionToken"];
    const csrfToken = rootGetters["config/csrfToken"];

    commit("SET_SUBMIT_STATUS", submitStatuses.submitting);
    const { error } = await updateUserApi(csrfToken, sessionToken, user);

    if (error) return commit("SET_SUBMIT_STATUS", submitStatuses.errored);

    commit("SET_USER", user);
    commit("SET_SUBMIT_STATUS", submitStatuses.submitted);
    setTimeout(() => commit("SET_SUBMIT_STATUS", submitStatuses.idle), 0);
  },

  async setLocale({ commit, rootGetters }, locale) {
    const isLoggedIn = rootGetters["session/isLoggedIn"];
    const sessionToken = rootGetters["session/sessionToken"];
    const csrfToken = rootGetters["config/csrfToken"];

    // intentionally set locale before the call to mitigate network lag
    commit("SET_LOCALE", locale);

    if (!isLoggedIn) return;

    commit("SET_SUBMIT_STATUS", submitStatuses.submitting);

    const { error } = await updateUserLocaleApi(csrfToken, sessionToken, locale);
    if (error) return commit("SET_SUBMIT_STATUS", submitStatuses.errored);

    commit("SET_SUBMIT_STATUS", submitStatuses.submitted);
    setTimeout(() => commit("SET_SUBMIT_STATUS", submitStatuses.idle), 0);
  },
};

const mutations = {
  SET_SUBMIT_STATUS(state, status) {
    state.submitStatus = status;
  },

  SET_USER(state, user) {
    state.user = user;
  },

  SET_LOCALE(state, locale) {
    Cookies.set("language", locale);

    state.user = {
      ...state.user,
      locale,
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
