{
  "jp": {
    "searchPlaceholder": "注文を検索",
    "orderTitle": "注文: {id}",
    "refreshOrders": "注文を更新",
    "notifications": {
      "refreshSuccess": "注文は正常に更新されました。",
      "refreshError": "注文の更新中にエラーが発生しました。"
    },
    "paymentStatuses": {
      "UNSPECIFIED_PAYMENT_STATUS": "不特定入金状況",
      "NOT_PAID": "入金未確認",
      "PAID": "入金確認",
      "PARTIALLY_REFUNDED": "一部返金",
      "FULLY_REFUNDED": "全額返金",
      "PENDING": "保留中"
    },
    "fulfillmentStatuses": {
      "NOT_FULFILLED": "未発送",
      "FULFILLED": "発送済み",
      "CANCELED": "キャンセル",
      "PARTIALLY_FULFILLED": "発送中"
    }
  },
  "en": {
    "searchPlaceholder": "Search for Order",
    "orderTitle": "ORDER: {id}",
    "refreshOrders": "Refresh Orders",
    "notifications": {
      "refreshSuccess": "Orders have been succesfully refreshed",
      "refreshError": "An error occurred while refreshing orders.."
    },
    "paymentStatuses": {
      "UNSPECIFIED_PAYMENT_STATUS": "unspecified payment status",
      "NOT_PAID": "not paid",
      "PAID": "paid",
      "PARTIALLY_REFUNDED": "partially refunded",
      "FULLY_REFUNDED": "fully refunded",
      "PENDING": "pending"
    },
    "fulfillmentStatuses": {
      "NOT_FULFILLED": "not fulfilled",
      "FULFILLED": "fulfilled",
      "CANCELED": "canceled",
      "PARTIALLY_FULFILLED": "partially fulfilled"
    }
  }
}
