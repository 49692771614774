<template>
  <transition name="fade">
    <slot></slot>
  </transition>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
/* stylelint-disable selector-class-pattern */
@import "../../App/css/variables";

.fade-enter-active,
.fade-leave-active {
  position: relative;
  z-index: 100;
  transition: opacity 0.2s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
