import { deepClone } from "../../lib/utils";
import { fetchStatuses, submitStatuses } from "../consts";
import fetchOrdersApi from "../api/fetchOrders";
import refreshOrdersApi from "../api/refreshOrders";
import archiveOrderApi from "../api/archiveOrder";

const state = {
  fetchStatus: fetchStatuses.idle,
  submitStatus: submitStatuses.idle,
  refreshStatus: submitStatuses.idle,
  orders: [],
};

const getters = {
  fetched: ({ fetchStatus }) => fetchStatus === fetchStatuses.fetched,
  submitStatus: ({ submitStatus }) => submitStatus,
  refreshStatus: ({ refreshStatus }) => refreshStatus,
  refreshing: ({ refreshStatus }) => refreshStatus === submitStatuses.submitting,
  all: ({ orders }) => orders,
  getOrder: ({ orders }) => (id) => orders.find((order) => order.id === id),
};

const actions = {
  async fetch({ commit, rootGetters }) {
    const sessionToken = rootGetters["session/sessionToken"];
    const csrfToken = rootGetters["config/csrfToken"];

    commit("SET_FETCH_STATUS", fetchStatuses.fetching);
    const { error, orders } = await fetchOrdersApi(csrfToken, sessionToken);

    if (error) return commit("SET_FETCH_STATUS", fetchStatuses.errored);

    commit("SET_FETCH_STATUS", fetchStatuses.fetched);
    commit("SET_ORDERS", orders);
  },

  async refresh({ commit, dispatch, rootGetters }) {
    const sessionToken = rootGetters["session/sessionToken"];
    const csrfToken = rootGetters["config/csrfToken"];

    commit("SET_REFRESH_STATUS", submitStatuses.submitting);
    const { error } = await refreshOrdersApi(csrfToken, sessionToken);

    if (error) return commit("SET_REFRESH_STATUS", submitStatuses.errored);

    commit("SET_REFRESH_STATUS", submitStatuses.submitted);
    setTimeout(() => commit("SET_REFRESH_STATUS", submitStatuses.idle), 0);
    dispatch("fetch");
    dispatch("labels/fetch", null, { root: true });
  },

  async archive({ commit, rootGetters }, { order, archive }) {
    const sessionToken = rootGetters["session/sessionToken"];
    const csrfToken = rootGetters["config/csrfToken"];

    commit("SET_SUBMIT_STATUS", submitStatuses.submitting);
    const { error, order: updatedOrder } = await archiveOrderApi(csrfToken, sessionToken, order.id, archive);

    if (error) return commit("SET_SUBMIT_STATUS", submitStatuses.errored);
    commit("SET_ORDER", updatedOrder);
    commit("SET_SUBMIT_STATUS", submitStatuses.submitted);
    setTimeout(() => commit("SET_SUBMIT_STATUS", submitStatuses.idle), 0);
  },

  update({ commit }, order) {
    commit("SET_ORDER", order);
  },
};

const mutations = {
  SET_FETCH_STATUS(state, status) {
    state.fetchStatus = status;
  },

  SET_SUBMIT_STATUS(state, status) {
    state.submitStatus = status;
  },

  SET_REFRESH_STATUS(state, status) {
    state.refreshStatus = status;
  },

  SET_ORDERS(state, orders) {
    state.orders = orders;
  },

  SET_ORDER(state, order) {
    const orders = deepClone(state.orders);
    orders[orders.findIndex(({ id }) => id == order.id)] = order;

    state.orders = orders;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
