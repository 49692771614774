{
  "jp": {
    "selectLanguage": "言語",
    "jp": "日本語",
    "en": "English"
  },
  "en": {
    "selectLanguage": "Select Language",
    "jp": "日本語",
    "en": "English"
  }
}
