<template>
  <div class="shippingPreset">
    <p v-if="!shippingPreset.validData" class="invalidShippingPresetTitle">
      {{ $t("notifications.invalidFields") }}
    </p>
    <div class="info">
      <div class="column">
        <p class="fieldTitle">{{ $t("fields.name") }}</p>
        <p class="fieldValue">
          <i>{{ shippingPreset.name }}</i>
        </p>
      </div>
      <div class="column">
        <p class="fieldTitle">{{ $t("fields.businessName") }}</p>
        <p class="fieldValue">{{ shippingPreset.businessName }}</p>
      </div>
      <div class="column wider">
        <p class="fieldTitle">{{ $t("fields.address") }}</p>
        <p class="fieldValue">{{ shippingPreset.address }}<br />{{ shippingPreset.addressZipCode }}</p>
      </div>
      <div class="column">
        <p class="fieldTitle">{{ $t("fields.phone") }}</p>
        <p class="fieldValue">{{ shippingPreset.phoneNr || "-" }}</p>
      </div>
    </div>
    <div class="footer">
      <span>{{ $t("info") }}</span>
      <div class="actions">
        <InputCheckbox
          type="compact"
          :disabled="!shippingPreset.validData"
          :label="$t('fields.defaultLabel')"
          :value="shippingPreset.default"
          @change="setDefault"
        />
        <Button type="noBorders" :disabled="shippingPresets.length === 1" @click="remove">
          {{ $t("buttons.remove") }}
        </Button>
        <Button type="noBorders" @click="showModal = true">{{ $t("buttons.edit") }}</Button>
      </div>
    </div>
    <AnimationFade>
      <ShippingPresetModal v-if="showModal" :shippingPreset="shippingPreset" @close="showModal = false" />
    </AnimationFade>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import notificationOnSubmit from "../../../../lib/notification-on-submit";
import confirmBox from "../../../../lib/confirm-box";
import Button from "../../../inputs/Button";
import InputCheckbox from "../../../inputs/InputCheckbox";
import AnimationFade from "../../../animations/AnimationFade";
import ShippingPresetModal from "../../../modals/ShippingPreset";

export default {
  components: {
    ShippingPresetModal,
    AnimationFade,
    Button,
    InputCheckbox,
  },

  props: {
    shippingPreset: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showModal: false,
    };
  },

  computed: {
    ...mapGetters({
      shippingPresets: "shippingPresets/allValid",
    }),
  },

  methods: {
    ...mapActions({
      setDefaultVuex: "shippingPresets/setDefault",
      removeVuex: "shippingPresets/remove",
    }),

    async setDefault() {
      if (this.shippingPreset.default) return;
      if (!this.shippingPreset.validData) return;
      if (!(await confirmBox(this, { title: this.$t("setDefaultConfirmation") }))) return;

      notificationOnSubmit(this, {
        statusWatcher: "shippingPresets/submitStatus",
        onSuccessMessage: this.$t("notifications.setDefaultSuccess"),
        onErrorMessage: this.$t("notifications.setDefaultError"),
      });
      this.setDefaultVuex(this.shippingPreset.id);
    },

    async remove() {
      if (!(await confirmBox(this, { title: this.$t("removeConfirmation") }))) return;

      notificationOnSubmit(this, {
        statusWatcher: "shippingPresets/submitStatus",
        onSuccessMessage: this.$t("notifications.removedSuccess"),
        onErrorMessage: this.$t("notifications.removedError"),
      });
      this.removeVuex(this.shippingPreset);
    },
  },
};
</script>

<i18n src="./locale.json"></i18n>

<style lang="scss" scoped>
@import "../../../App/css/variables";

.shippingPreset {
  margin-bottom: 15px;
  padding: 20px;
  border: 2px solid $lines;
  border-radius: 8px;
  background-color: $white;
}

.info {
  display: flex;
}

.column {
  width: 110px;
  margin: 0 20px;

  &.wider {
    width: 215px;
  }
}

.invalidShippingPresetTitle {
  margin: 0 0 20px 20px;
  color: $warning;
  font-weight: bold;
  font-size: 12px;
}

.fieldTitle {
  color: $primary;
  font-weight: bold;
  font-size: 12px;
}

.fieldValue {
  max-width: 425px;
  margin: 8px 0 0;
  color: $primary;
  font-size: 14px;
  line-height: 25px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px -20px 0;
  padding: 20px 20px 0;
  border-top: 2px solid $lines;
  color: $secondary;
  font-size: 12px;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
