<template>
  <div class="stepsPanel">
    <a href="#step1">
      <div class="step">
        <h4>{{ $t("step1.title") }}</h4>
        <small>{{ $t("step1.description") }}</small>
      </div>
    </a>
    <a href="#step2">
      <div class="step">
        <h4>{{ $t("step2.title") }}</h4>
        <small>{{ $t("step2.description") }}</small>
      </div>
    </a>
    <a href="#step3">
      <div class="step">
        <h4>{{ $t("step3.title") }}</h4>
        <small>{{ $t("step3.description") }}</small>
      </div>
    </a>
    <a href="#step4">
      <div class="step">
        <h4>{{ $t("step4.title") }}</h4>
        <small>{{ $t("step4.description") }}</small>
      </div>
    </a>
  </div>
</template>

<script></script>

<i18n src="./locale.json"></i18n>

<style lang="scss" scoped>
@import "../App/css/variables";

a {
  color: black;
  text-decoration: none;
}

.stepsPanel {
  display: flex;
  flex-direction: column;
  padding: 48px 24px 24px;
  border-right: 1px solid $lines;
}

.step {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 100px;
  margin-top: 15px;
  padding: 20px 25px;
  border: 1px solid $lines;
  border-radius: 12px;
  background-color: white;
}

.activeStep {
  color: white;
  background: $primary;
}
</style>
