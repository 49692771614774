<template>
  <div class="ordersPanel">
    <h2 class="title">{{ $t("settings") }}</h2>
    <div class="menu">
      <router-link to="/settings/profile" class="menuItem" :title="$t('profile')">
        {{ $t("profile") }}
      </router-link>
      <router-link to="/settings/subscription" class="menuItem" :title="$t('subscription')">
        {{ $t("subscription") }}
        <span v-if="showSubscriptionWarning" :title="$t('selectSubscriptionWarning')">&nbsp;⚠️</span>
      </router-link>
      <router-link to="/settings/shipping-presets" class="menuItem" :title="$t('shippingPresets')">
        {{ $t("shippingPresets") }}
        <span v-if="showShippingPresetsWarning" :title="$t('noShippingPresetsWarning')">&nbsp;⚠️</span>
      </router-link>
      <router-link to="/settings/shipping-carriers" class="menuItem" :title="$t('shippingCarriers')">
        {{ $t("shippingCarriers") }}
        <span v-if="showShippingCarriersWarning" :title="$t('noShippingCarriersWarning')">&nbsp;⚠️</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      subscription: "subscriptions/active",
      jppostCarrier: "shippingCarriers/jppost",
      shippingPresets: "shippingPresets/allValid",
    }),

    showSubscriptionWarning() {
      return !this.subscription;
    },

    showShippingCarriersWarning() {
      return !this.jppostCarrier?.active;
    },

    showShippingPresetsWarning() {
      return this.shippingPresets.every((preset) => !preset.validData);
    },
  },
};
</script>

<i18n src="./locale.json"></i18n>

<style lang="scss" scoped>
@import "../App/css/variables";

.ordersPanel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 85px 32px 0 65px;
  border-right: 1px solid $lines;
  color: $secondary;
  background-color: $blue-1-aqua-haze;

  &::after {
    height: 165px;
    content: "";
  }
}

.title {
  font-size: 32px;
}

.menuItem {
  display: flex;
  align-items: center;
  height: 60px;
  margin-left: -32px;
  padding: 32px;
  border-top: 1px solid $lines;
  color: $secondary;
  font-size: 16px;
  text-decoration: none;

  &:first-child {
    border-color: transparent;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &.router-link-active,
  &:hover {
    color: $inputs;
  }
}
</style>
