import { fetchStatuses, submitStatuses } from "../consts";
import fetchShippingCarriersApi from "../api/fetchShippingCarriers";
import saveShippingCarrierApi from "../api/saveShippingCarrier";

const state = {
  fetchStatus: fetchStatuses.idle,
  submitStatus: submitStatuses.idle,
  shippingCarriers: {},
};

const getters = {
  submitStatus: ({ submitStatus }) => submitStatus,
  fetched: ({ fetchStatus }) => fetchStatus === fetchStatuses.fetched,
  jppost: ({ shippingCarriers }) => shippingCarriers["jppost"],
  nameById: ({ shippingCarriers }) => (id) => {
    if (shippingCarriers["jppost"]?.id === id) return "JAPAN POST";
    return null;
  },
};

const actions = {
  async fetch({ commit, rootGetters }) {
    const sessionToken = rootGetters["session/sessionToken"];
    const csrfToken = rootGetters["config/csrfToken"];

    commit("SET_FETCH_STATUS", fetchStatuses.fetching);
    const { error, shippingCarriers } = await fetchShippingCarriersApi(csrfToken, sessionToken);

    if (error) return commit("SET_FETCH_STATUS", fetchStatuses.errored);

    commit("SET_FETCH_STATUS", fetchStatuses.fetched);
    commit("SET_SHIPPING_CARRIERS", shippingCarriers);
  },

  async save({ commit, rootGetters, dispatch }, { type, shippingCarrier }) {
    const sessionToken = rootGetters["session/sessionToken"];
    const csrfToken = rootGetters["config/csrfToken"];

    commit("SET_SUBMIT_STATUS", submitStatuses.submitting);
    const { error, shippingCarriers } = await saveShippingCarrierApi(csrfToken, sessionToken, type, shippingCarrier);

    if (error) return commit("SET_SUBMIT_STATUS", submitStatuses.errored);

    commit("SET_SUBMIT_STATUS", submitStatuses.submitted);
    commit("SET_SHIPPING_CARRIERS", shippingCarriers);
    setTimeout(() => commit("SET_SUBMIT_STATUS", submitStatuses.idle), 0);

    dispatch("orders/fetch", null, { root: true });
  },
};

const mutations = {
  SET_FETCH_STATUS(state, status) {
    state.fetchStatus = status;
  },
  SET_SUBMIT_STATUS(state, status) {
    state.submitStatus = status;
  },
  SET_SHIPPING_CARRIERS(state, shippingCarriers) {
    state.shippingCarriers = shippingCarriers;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
