{
  "jp": {
    "fields": {
      "name": "出荷元名称",
      "businessName": "会社名・店舗名",
      "address": "住所",
      "phone": "電話番号",
      "defaultLabel": "既定の出荷元にする"
    },
    "buttons": {
      "edit": "変更",
      "remove": "削除"
    },
    "notifications": {
      "setDefaultSuccess": "既定の出荷元が更新されました。",
      "setDefaultError": "エラーが発生しました。",
      "removedSuccess": "出荷元情報が削除されました。",
      "removedError": "出荷元の削除中にエラーが発生しました。",
      "invalidFields": "出荷元情報に何らかの問題があります。「変更」ボタンをクリックして内容を確認してください。"
    },
    "info": "ユーザー情報更新",
    "setDefaultConfirmation": "こちらを既定の出荷元にしますか？",
    "removeConfirmation": "この出荷元情報を削除しますか？"
  },
  "en": {
    "fields": {
      "name": "NAME",
      "businessName": "BUSINESS NAME",
      "address": "ADDRESS",
      "phone": "PHONE NUMBER",
      "defaultLabel": "SET AS DEFAULT PRESET"
    },
    "buttons": {
      "edit": "EDIT",
      "remove": "REMOVE"
    },
    "notifications": {
      "setDefaultSuccess": "The preset has been successfully set as a new default!",
      "setDefaultError": "An error occurred while setting new default preset..",
      "removedSuccess": "The preset has been successfully removed!",
      "removedError": "An error occurred while removing a preset..",
      "invalidFields": "Some fields of this preset are still invalid, you can edit them by clicking the edit button."
    },
    "info": "Modify Your Personal Information",
    "setDefaultConfirmation": "Do you want to set this preset as default?",
    "removeConfirmation": "Do you want to remove this preset?"
  }
}
