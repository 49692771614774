import { camelizeKeys } from "humps";
import axiosOpenApiWrapper from "../../lib/axios-open-api-wrapper";

export default async (csrfToken, sessionToken, shippingPresetId) => {
  const { status, data } = await axiosOpenApiWrapper({
    method: "delete",
    url: `/api/shipping_presets/${shippingPresetId}/`,
    openApiUrl: "/api/shipping_presets/{shippingPresetId}/",
    headers: {
      "X-CSRF-TOKEN": csrfToken,
      Authorization: `Bearer ${sessionToken}`,
    },
    validateStatus: () => true,
  });

  const payload = camelizeKeys(data.payload);
  const error = `${status}` !== "200";
  const shippingPresets = payload?.shippingPresets;
  return { error, shippingPresets };
};
