<template>
  <div class="pageHeader">
    <h2>{{ title }}</h2>
    <div class="actions">
      <Button
        v-if="!!additionalBtnTitle"
        class="btn"
        type="noBorders"
        size="small"
        @click="$emit('additionalBtnClick')"
      >
        {{ additionalBtnTitle }}
      </Button>
      <Button v-if="btnTitle" class="btn" size="big" @click="$emit('btnClick')">{{ btnTitle }}</Button>
    </div>
    <slot name="rightSection"></slot>
  </div>
</template>

<script>
import Button from "../inputs/Button";

export default {
  components: {
    Button,
  },
  props: {
    title: {
      type: String,
      default: "",
    },

    additionalBtnTitle: {
      type: String,
      default: "",
    },

    btnTitle: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../App/css/variables";

.pageHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 80px 0;
  color: $primary;
}

.actions {
  display: flex;
  align-items: stretch;

  .btn {
    margin-left: 10px;
  }
}
</style>
