<template>
  <Notifications />
  <div class="withSettingsMenu">
    <IncompleteSettingsInfoBar noLink />
    <div class="main">
      <SidePanel class="sidePanel" />
      <SettingsMenu class="settingsMenu" />
      <div class="layoutContentWrapper">
        <div class="layoutContent">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SidePanel from "../../SidePanel";
import SettingsMenu from "../../SettingsMenu";
import Notifications from "../../Notifications";
import IncompleteSettingsInfoBar from "../../IncompleteSettingsInfoBar";

export default {
  components: {
    SidePanel,
    SettingsMenu,
    Notifications,
    IncompleteSettingsInfoBar,
  },
};
</script>

<style lang="scss" scoped>
@import "../../App/css/variables";

.withSettingsMenu {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.main {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

.sidePanel {
  flex: 80px 0 0;
}

.settingsMenu {
  flex: 308px 0 0;
}

.layoutContentWrapper {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  height: 100%;
  overflow: auto;
  background: $white url("../../../assets/images/city-footer.svg") no-repeat 0 100%;
  background-size: contain;
}

.layoutContent {
  min-width: 750px;
  max-width: 940px;

  &::after {
    display: block;
    height: 100px;
    content: "";
  }
}
</style>
