<template>
  <div class="generateLabelButton">
    <Button class="btn" v-bind="$props" :disabled="disabled" @click="clicked">
      {{ text }}
      <div v-if="disabled" class="infoBoxWrapper">
        <div class="infoBox">
          <p>{{ $t("errorBox.title") }}</p>
          <ul>
            <li v-if="invalidLabelData">
              {{ $t("errorBox.invalidLabelData") }} &nbsp;
              <span class="link" @click="$emit('editLabelClicked')">{{ $t("errorBox.editLabel") }}</span>
            </li>
            <li v-if="noSubscription">
              {{ $t("errorBox.noSubscription") }} &nbsp;
              <router-link class="link" to="/settings/subscription">{{ $t("errorBox.subscriptions") }}</router-link>
            </li>
            <li v-if="emptyBalance">
              {{ $t("errorBox.emptyBalance") }} &nbsp;
              <router-link class="link" to="/settings/subscription">{{ $t("errorBox.subscriptions") }}</router-link>
            </li>
          </ul>
        </div>
      </div>
    </Button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { submitStatuses } from "../../../../../stores/consts";
import notificationOnSubmit from "../../../../../lib/notification-on-submit";
import confirmBox from "../../../../../lib/confirm-box";
import Button from "../../../../inputs/Button";

export default {
  components: {
    Button,
  },
  props: {
    label: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      submitStatus: "labels/submitStatus",
      subscription: "subscriptions/active",
    }),

    invalidLabelData() {
      return !this.label.validData;
    },

    noSubscription() {
      return !this.subscription;
    },

    emptyBalance() {
      return this.subscription?.labelBalance === 0;
    },

    disabled() {
      if (this.readyToDownload) return false;

      return this.invalidLabelData || this.noSubscription || this.emptyBalance;
    },

    readyToDownload() {
      return !!this.label.downloadUrl;
    },

    text() {
      if (this.submitStatus === submitStatuses.submitting) return this.$t("buttons.submitting");

      return this.readyToDownload ? this.$t("buttons.downloadUrl") : this.$t("buttons.generateLabel");
    },
  },
  methods: {
    ...mapActions({
      generatePostLabelVuex: "labels/generatePostLabel",
    }),

    clicked() {
      if (this.submitStatus === submitStatuses.submitting) return;
      if (this.submitStatus === submitStatuses.errored) return this.generatePostLabel();

      return this.label.downloadUrl ? window.open(this.label.downloadUrl) : this.generatePostLabel();
    },

    async generatePostLabel() {
      if (!(await confirmBox(this, { title: this.$t("generateLabelConfirm") }))) return;

      notificationOnSubmit(this, {
        statusWatcher: "labels/submitStatus",
        onSuccessMessage: this.$t("notifications.success"),
        onErrorMessage: this.$t("notifications.error"),
        duration: 6000,
      });
      this.generatePostLabelVuex(this.label);
    },
  },
};
</script>

<i18n src="./locale.json"></i18n>

<style lang="scss" scoped>
@import "../../../../App/css/variables";

.generateLabelButton {
  position: relative;
}

.btn {
  display: flex;
}

.btn:hover .infoBoxWrapper {
  display: block;
}

.infoBoxWrapper {
  position: absolute;
  right: -30px;
  bottom: -20px;
  display: none;
  transform: translateY(100%);
  cursor: initial;
}

.infoBox {
  position: relative;
  width: 600px;
  padding: 16px 16px 16px 50px;
  border: 2px solid $blue-4-curious-blue;
  border-radius: 6px;
  color: $secondary;
  font-size: 14px;
  text-align: left;
  background: $blue-8-zumthor url("../../../../../assets/images/info.svg") no-repeat 16px 16px;

  &::before {
    position: absolute;
    top: -11px;
    right: 80px;
    width: 17px;
    height: 17px;
    border-top: 2px solid $blue-4-curious-blue;
    border-left: 2px solid $blue-4-curious-blue;
    border-top-left-radius: 6px;
    background: $blue-8-zumthor;
    transform: rotate(45deg);
    content: "";
  }

  &::after {
    position: absolute;
    top: -30px;
    right: 0;
    left: 0;
    height: 30px;
    content: "";
  }

  .link {
    color: $inputs;
    text-decoration: underline;
    cursor: pointer;
  }

  ul {
    margin-top: 15px;
    list-style: none;

    li {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 5px;

      &::before {
        display: block;
        width: 6px;
        height: 6px;
        margin-right: 10px;
        border-radius: 100px;
        background-color: $secondary;
        content: "";
      }
    }
  }
}
</style>
