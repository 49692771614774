import { fetchStatuses, submitStatuses } from "../consts";
import fetchSubscriptionApi from "../api/fetchSubscription";
import updateSubscriptionApi from "../api/updateSubscription";

const unlimitedBalanceIndicator = -1;

const state = {
  subscription: null,
  checkoutUrl: "",
  fetchStatus: fetchStatuses.idle,
  submitStatus: submitStatuses.idle,
};

const getters = {
  submitStatus: ({ submitStatus }) => submitStatus,
  checkoutUrl: ({ checkoutUrl }) => checkoutUrl,
  active: ({ subscription }) => subscription,
  unlimited: ({ subscription }) => subscription?.labelBalance === unlimitedBalanceIndicator,
  fetched: ({ fetchStatus }) => fetchStatus === fetchStatuses.fetched,
};

const actions = {
  async fetch({ commit, rootGetters }) {
    const sessionToken = rootGetters["session/sessionToken"];
    const csrfToken = rootGetters["config/csrfToken"];

    commit("SET_FETCH_STATUS", fetchStatuses.fetching);
    const { error, subscription } = await fetchSubscriptionApi(csrfToken, sessionToken);

    if (error) return commit("SET_FETCH_STATUS", fetchStatuses.errored);

    commit("SET_SUBSCRIPTION", subscription);
    commit("SET_FETCH_STATUS", fetchStatuses.fetched);
  },

  async update({ commit, rootGetters }, { planName, billingCycle }) {
    const sessionToken = rootGetters["session/sessionToken"];
    const csrfToken = rootGetters["config/csrfToken"];
    const successUrl = `${window.location.origin}/settings/subscription/succesfully-purchased`;

    commit("SET_SUBMIT_STATUS", submitStatuses.submitting);
    const { error, checkoutUrl } = await updateSubscriptionApi(
      csrfToken,
      sessionToken,
      planName,
      billingCycle,
      successUrl
    );
    if (error) return commit("SET_SUBMIT_STATUS", submitStatuses.errored);

    commit("SET_CHECKOUT_URL", checkoutUrl);
    commit("SET_SUBMIT_STATUS", submitStatuses.submitted);
    setTimeout(() => commit("SET_SUBMIT_STATUS", submitStatuses.idle), 0);
  },
};

const mutations = {
  SET_FETCH_STATUS(state, fetchStatus) {
    state.fetchStatus = fetchStatus;
  },
  SET_SUBMIT_STATUS(state, status) {
    state.submitStatus = status;
  },
  SET_CHECKOUT_URL(state, checkoutUrl) {
    state.checkoutUrl = checkoutUrl;
  },
  SET_SUBSCRIPTION(state, subscription) {
    state.subscription = subscription;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
