import axiosOpenApiWrapper from "../../lib/axios-open-api-wrapper";

export default async (csrfToken, sessionToken) => {
  const { status } = await axiosOpenApiWrapper({
    method: "post",
    url: "/api/orders/refresh_orders",
    headers: {
      "X-CSRF-TOKEN": csrfToken,
      Authorization: `Bearer ${sessionToken}`,
    },
    validateStatus: () => true,
  });

  const error = `${status}` !== "200";
  return { error };
};
