{
  "jp": {
    "title": "ご利用プランを停止しますか？",
    "subtitle": "送り状を作成できなくなります。",
    "buttons": {
      "cancelSubscription": "プランの利用を停止<br />(Wixダッシュボードにリダイレクトする)",
      "back": "戻る"
    },
    "notifications": {
      "redirectingToWix": "WIXの契約ページへのリダイレクト"
    }
  },
  "en": {
    "title": "Are you sure you want to end your Unipostal subscription?",
    "subtitle": "You won’t be able to print any labels.",
    "buttons": {
      "cancelSubscription": "Cancel Subscription<br />(redirect to Wix dashboard)",
      "back": "No, take me back."
    },
    "notifications": {
      "redirectingToWix": "Redirecting to WIX subscription page"
    }
  }
}
