<template>
  <LayoutWithOrderList>
    <div class="welcome">
      <strong class="logo" />
      <div class="content">
        <img :src="welcomeScreenSvgUrl" />
        <h1>{{ $t("title") }}</h1>
      </div>
    </div>
  </LayoutWithOrderList>
</template>

<script>
import welcomeScreenSvgUrl from "../../../assets/images/welcome-screen.svg";
import LayoutWithOrderList from "../../layouts/WithOrderList";

export default {
  components: {
    LayoutWithOrderList,
  },

  data() {
    return {
      welcomeScreenSvgUrl,
    };
  },
};
</script>

<i18n src="./locale.json"></i18n>

<style lang="scss" scoped>
@import "../../App/css/variables";

.welcome {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.logo {
  position: absolute;
  top: 30px;
  left: 50%;
  width: 175px;
  height: 37px;
  background: $white url("../../../assets/images/logo-text.svg") no-repeat 0 0;
  background-size: contain;
  transform: translateX(-50%);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $primary;
  text-align: center;

  h1 {
    margin-top: 30px;
    font-weight: normal;
    font-size: 30px;
  }
}
</style>
