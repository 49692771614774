{
  "jp": {
    "header": {
      "title": "日本郵便の認証コード取得方法"
    },
    "step1": {
      "title": "１.",
      "description": "日本郵便 ゆうパック・ゆうパケットの送り状作成をご利用のお客様は、<a href='https://ang.jpi.post.japanpost.jp/contents/I10P51Action.do?siteid=DEGICA'>こちら</a>より「ゆうパック送り状作成システム」の申込みをお願い致します。",
      "furtherDescriptionLine1": "<b>事前にユーザー様が日本郵便より「料金後納」承認を受けている必要があります。</b>",
      "furtherDescriptionLine1": "「料金後納」承認後、日本郵便より後納顧客コード（31桁）が発行されます。",
      "furtherDescriptionLine3": "「ゆうパック送り状作成システム」の各項目をご入力の上、認証コードのリクエスト送信をお願いいたします。（リクエスト送信の際、32桁の顧客コードを必ず入力ください。）"
    },
    "step2": {
      "title": "２.",
      "description": "リクエスト送信後、日本郵便よりリクエスト受付完了のメールが自動送信されます。",
      "furtherDescriptionLine1": "メールタイトル：「Unipostal」ゆうパック送り状作成サービスのお申し込みを受け付けました。"
    },
    "step3": {
      "title": "３.",
      "description": "日本郵便による審査終了後、審査結果がメールで通知されます（所要日数：5～10営業日）。",
      "furtherDescriptionLine1": "メールタイトル：「Unipostal」ゆうパック送り状作成サービスご利用承認通知",
      "furtherDescriptionLine2": "<b>【重要】</b> 上記メールをお受け取りになりましたら、メールに「認証コード」が記載されている事をご確認下さい。また、このメールを削除したりゴミ箱に入れることなく、必ず保存するようにしてください。"
    },
    "step4": {
      "title": "４.",
      "description": "Unipostalのダッシュボードのメニュー「設定」から「配送会社」をクリックして下さい。",
      "furtherDescriptionLine1": "「認証コード」欄に、上記3. メールに記載されている認証コードをコピー＆ペーストしてください。"
    }
  },
  "en": {
    "header": {
      "title": "Japan Post Onboarding Guide"
    },
    "step1": {
      "title": "Step 1",
      "description": "If you are using JapanPost's \"ゆうパック・ゆうパケット\" label creation system, please apply for the \"ゆうパック送り状作成システム\" system from <a href='https://ang.jpi.post.japanpost.jp/contents/I10P51Action.do?siteid=DEGICA'>here</a>.",
      "furtherDescriptionLine1": "Merchants are requested to register directly to the \"ゆうパック送り状作成システム\" system from <a href='https://ang.jpi.post.japanpost.jp/contents/I10P51Action.do?siteid=DEGICA'>the link above</a>.",
      "furtherDescriptionLine2": "<b>The Merchant must have completed the \"Postage Payment Agreement (料金後納契約)\" with JapanPost in advance</b>. After the completion of the Postage Payment Agreement (料金後納契約), JapanPost will issue a Postage Customer Code (後納顧客コード) (32-digit code) to the merchant. This 32-digit code is required when filling out the above \"ゆうパック送り状作成システム\" application.",
      "furtherDescriptionLine3": "Please fill in each item of the \"ゆうパック送り状作成システム\" application form and complete the request for a Consignor ID (認証コード) to be used with Unipostal. <br />(Be sure to enter the 32-digit Postage Customer Code when sending the request)"
    },
    "step2": {
      "title": "Step 2",
      "description": "After the Merchant sends a request for a Consignor ID (認証コード) through the \"ゆうパック送り状作成システム\" application system, an automated email will be sent from JapanPost to the Merchant to confirm that the request has been received.",
      "furtherDescriptionLine1": "Email subject: 「Unipostal」ゆうパック送り状作成サービスのお申し込みを受け付けました。"
    },
    "step3": {
      "title": "Step 3",
      "description": "After the contract details review has been completed by JapanPost, you will be notified of the review result by email within 5 to 10 business days.",
      "furtherDescriptionLine1": "Email subject: 「Unipostal」ゆうパック送り状作成サービスご利用承認通知",
      "furtherDescriptionLine2": "<b>Important:</b> When you receive the above email, please confirm that the \"Consignor ID\" (認証コード) is included in the email. Be sure to save this email and do not delete it or move it to trash."
    },
    "step4": {
      "title": "Step 4",
      "description": "Click on \"Shipping Carriers\" from the \"Settings\" menu on the Unipostal dashboard.",
      "furtherDescriptionLine1": "Please copy and paste the “Consignor ID” (認証コード) that was sent to you (in the notification of review result email from Step 3) into the \"Consignor ID\" field of the Shipping Carrier page."
    }
  }
}
