import axios from "axios";
import { Validator } from "jsonschema";
import openApiSchema from "../../app_api_schema/openapi.yml";

const schemaValidator = new Validator();

export default async (axiosProps) => {
  validateRequest(axiosProps);
  const response = await axios(axiosProps);
  validateResponse(axiosProps, response);
  return response;
};

const validateRequest = ({ method, openApiUrl = null, url, data }) => {
  const schemaUrl = openApiUrl || url;
  const schema =
    openApiSchema.paths?.[schemaUrl]?.[method]?.["requestBody"]?.["content"]?.["application/json"]?.["schema"];

  if (!schema) throw new Error(`invalid request: ${method} ${url}, errors: "Schema not found"`);

  const result = schemaValidator.validate(data, schema);
  if (result.errors.length > 0) throw new Error(`invalid request: ${method} ${url}, errors: "${result.errors}"`);
};

const validateResponse = ({ method, openApiUrl = null, url }, response) => {
  if (response.status === 500) throw new Error(`invalid response (500): ${method} ${url}"`);

  const schemaUrl = openApiUrl || url;
  const responseStatus = response.status;
  const data = response.data;

  const schema =
    openApiSchema.paths?.[schemaUrl]?.[method]?.["responses"]?.[responseStatus]?.["content"]?.["application/json"]?.[
      "schema"
    ];

  if (!schema) throw new Error(`invalid response: ${method} ${responseStatus} ${url}, errors: "Schema not found"`);

  const result = schemaValidator.validate(data, schema);
  if (result.errors.length > 0)
    throw new Error(`invalid response: ${method} ${responseStatus} ${url}, errors: "${result.errors}"`);
};
