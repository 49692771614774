<template>
  <LayoutWithSettingsMenu>
    <PageHeader :title="$t('header.title')" />
    <div class="box">
      <img :src="boxesSvgUrl" />
      <p>
        <strong>{{ $t("title") }}</strong>
        <br />
        <br />
        {{ $t("desc") }}
      </p>
    </div>
  </LayoutWithSettingsMenu>
</template>

<script>
import LayoutWithSettingsMenu from "../../layouts/WithSettingsMenu";
import PageHeader from "../../PageHeader";
import boxesSvgUrl from "../../../assets/images/boxes-with-ok.svg";

export default {
  components: {
    LayoutWithSettingsMenu,
    PageHeader,
  },

  data() {
    return {
      boxesSvgUrl,
    };
  },
};
</script>

<i18n src="./locale.json"></i18n>

<style lang="scss" scoped>
@import "../../App/css/variables";

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  border: 2px solid $lines;
  border-radius: 8px;
  background-color: $white;

  img {
    margin-bottom: 50px;
  }
}
</style>
