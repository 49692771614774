{
  "jp": {
    "header": {
      "title": "ユーザー情報",
      "personalInformation": "ユーザー情報"
    },
    "fields": {
      "fullNameLabel": "氏名*",
      "fullNamePlaceholder": "氏名を入力してください",
      "emailLabel": "メールアドレス*",
      "emailPlaceholder": "メールアドレスを入力してください"
    },
    "notifications": {
      "success": "ユーザー情報が更新されました。",
      "error": "エラーが発生しました。"
    },
    "buttons": {
      "errored": "エラー",
      "saving": "保存中",
      "save": "保存"
    }
  },
  "en": {
    "header": {
      "title": "Personal Information",
      "personalInformation": "Personal Information"
    },
    "fields": {
      "fullNameLabel": "Full Name*",
      "fullNamePlaceholder": "Provide your full name",
      "emailLabel": "Email*",
      "emailPlaceholder": "Provide your email"
    },
    "notifications": {
      "success": "Profile has been successfully updated!",
      "error": "An error occurred while updating profile.."
    },
    "buttons": {
      "errored": "Errored",
      "saving": "Saving...",
      "save": "Save"
    }
  }
}
