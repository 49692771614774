<template>
  <LayoutPlain>
    <div class="signin">
      <strong class="logo" />
      <div class="content">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <h1 v-html="$t('header')" />
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-html="$t('info')" />

        <Button :disabled="disabled" size="big" @click="loginThroughWix">
          {{ btnText }}
        </Button>
        <div class="languageSelectWrapper">
          <LanguageCyclicalSelect />
        </div>
      </div>
    </div>
  </LayoutPlain>
</template>

<script>
import { mapGetters } from "vuex";
import { loginStatuses } from "../../../stores/consts";
import Button from "../../inputs/Button";
import LanguageCyclicalSelect from "../../inputs/LanguageCyclicalSelect";
import LayoutPlain from "../../layouts/Plain";

export default {
  components: {
    LayoutPlain,
    Button,
    LanguageCyclicalSelect,
  },
  data() {
    return {
      loginStatuses,
      btnClicked: false,
    };
  },

  computed: {
    ...mapGetters({
      loginStatus: "session/loginStatus",
    }),

    disabled() {
      return this.loginStatus !== loginStatuses.notLoggedIn || this.btnClicked;
    },

    btnText() {
      if (this.loginStatus === loginStatuses.loggingIn) return this.$t("buttons.fetching");
      if (this.btnClicked) return this.$t("buttons.redirecting");
      return this.$t("buttons.login");
    },
  },

  methods: {
    loginThroughWix() {
      this.btnClicked = true;
      window.location.href = "/wix/redirect_to_wix_app_install_page";
    },
  },
};
</script>

<i18n src="./locale.json"></i18n>

<style lang="scss" scoped>
@import "../../App/css/variables";

.signin {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: $white url("../../../assets/images/city-footer.svg") no-repeat 0 100%;
  background-size: contain;
}

.logo {
  position: absolute;
  top: 30px;
  left: 50%;
  width: 175px;
  height: 37px;
  background: $white url("../../../assets/images/logo-text.svg") no-repeat 0 0;
  background-size: contain;
  transform: translateX(-50%);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $primary;
  text-align: center;

  h1 {
    font-weight: normal;
    font-size: 56px;
  }

  p {
    margin: 50px 0 60px;
    font-size: 18px;
  }

  small {
    font-size: 0.8rem;
  }
}

.languageSelectWrapper {
  position: relative;
  margin-top: 60px;
  padding-top: 50px;

  &::before {
    position: absolute;
    top: 0;
    left: 50%;
    width: 36px;
    border-top: 2px solid $lines;
    transform: translateX(-50%);
    content: "";
  }
}
</style>
